import React, { useMemo } from "react";
import { useTheme } from "styled-components";
import { renderToString } from "react-dom/server";
import { MarkerProps } from "@react-google-maps/api";

import { Theme } from "styles/defaultTheme";
import MapMarker from "components/molecules/Images/MapMarker";

const useGoogleMarker = (): MarkerProps["icon"] => {
  const theme = useTheme() as Theme;

  const customMarker = useMemo(() => {
    // https://stackoverflow.com/questions/24413766/how-to-use-svg-markers-in-google-maps-api-v3/24426400
    const leftColor = theme ? theme.palette.primary.main : "#8c181b";
    const rightColor = theme ? theme.palette.primary.dark : "#770a12";
    const svg = renderToString(
      <MapMarker rightColor={rightColor} leftColor={leftColor} />,
    );
    return {
      url: `data:image/svg+xml,${encodeURIComponent(svg)}`,
    };
  }, [theme]);

  return customMarker;
};

export default useGoogleMarker;
