import React, { useMemo, useState, useCallback, CSSProperties } from "react";
import {
  GoogleMap,
  MarkerClusterer,
  Marker,
  OverlayView,
} from "@react-google-maps/api";

import IEventCard from "api/interfaces/IEventCard";
import RIGA_GEO from "constants/locations";
import { MAP_CLUSTER } from "constants/images";
import useGoogleMarker from "hooks/useGoogleMarker";

import MapCard from "../Cards/MapCard";

interface ClusterMapProps {
  translatedTitles: string[];
  events: IEventCard[];
  initialCenter?: {
    lat: number;
    lng: number;
  };
  initialZoom?: number;
}
const mapStyles: CSSProperties = {
  flex: 1,
  height: "100%",
  width: "100vw",
  position: "relative",
  margin: "0",
};
const clusterStyles = [
  {
    url: MAP_CLUSTER,
    width: 45,
    height: 45,
    textColor: "#fff",
  },
];
const clusterOpts = { maxZoom: 8 };
const getPixelPositionOffset = () => ({ x: -100, y: 0 });
export const loadMarkers = (events: IEventCard[]): IEventCard[] => {
  if (events === undefined) return [];

  const markers = events.filter(
    (event: IEventCard) => event.latitude && event.longitude,
  );
  const duplicates: IEventCard[] = [];
  const uniqueMarkers = markers.reduce(
    (unique: IEventCard[], item: IEventCard) => {
      // if unique has same location add to duplicates
      const dup = unique.find(
        (el: IEventCard) =>
          el.latitude === item.latitude && el.longitude === item.longitude,
      );
      if (!dup) {
        return [...unique, item];
      }
      duplicates.push(item);

      return unique;
    },
    [],
  );
  if (duplicates.length !== 0) {
    const a = 360.0 / duplicates.length;
    duplicates.map((dupe: IEventCard) => {
      let latitude = dupe.latitude || 0;
      let longitude = dupe.longitude || 0;
      latitude +=
        -0.0006 * Math.cos(((a * duplicates.indexOf(dupe)) / 180) * Math.PI);
      longitude +=
        -0.0006 * Math.sin(((a * duplicates.indexOf(dupe)) / 180) * Math.PI);

      return { ...dupe, latitude, longitude };
    });
    return [...uniqueMarkers, ...duplicates];
  }
  return uniqueMarkers;
};
const ClusterMap: React.FC<ClusterMapProps> = ({
  events: markers,
  initialCenter,
  initialZoom,
  translatedTitles,
}) => {
  const [mapCenter, setMapCenter] = useState(initialCenter || RIGA_GEO);
  const [selectedMarker, setSelectedMarker] = useState<IEventCard>();

  const deselectMarker = useCallback(() => {
    setSelectedMarker(undefined);
  }, []);

  const handleMarkerClick = useCallback((event: IEventCard) => {
    setSelectedMarker(event);
    const { latitude, longitude } = event;

    if (latitude && longitude) {
      setMapCenter({
        lat: latitude,
        lng: longitude,
      });
    }
  }, []);

  const clusterMarkers = useMemo(() => {
    return loadMarkers(markers);
  }, [markers]);

  const customMarker = useGoogleMarker();

  const selectIndex = markers.findIndex(
    (marker) => marker.slug === selectedMarker?.slug,
  );

  return (
    <GoogleMap
      mapContainerStyle={mapStyles}
      center={mapCenter}
      zoom={initialZoom ? Number(initialZoom) : 4}
      onClick={deselectMarker}
    >
      <MarkerClusterer
        enableRetinaIcons
        averageCenter
        styles={clusterStyles}
        options={clusterOpts}
      >
        {(clusterer) =>
          clusterMarkers.map((marker: IEventCard) => {
            const { latitude, longitude, slug } = marker;
            return (
              <Marker
                key={slug}
                position={{
                  lat: latitude || RIGA_GEO.lat,
                  lng: longitude || RIGA_GEO.lng,
                }}
                onClick={() => handleMarkerClick(marker)}
                icon={customMarker}
                clusterer={clusterer}
              />
            );
          })
        }
      </MarkerClusterer>
      {selectedMarker !== undefined && selectIndex > -1 && (
        <OverlayView
          position={mapCenter}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <MapCard
            data={selectedMarker}
            titleTranslated={translatedTitles[selectIndex]}
          />
        </OverlayView>
      )}
    </GoogleMap>
  );
};

export default ClusterMap;
