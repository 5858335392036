import { LOCALE, LOCALE_OPTS } from "locales/locale";
import { DbFieldMaker, SimpleFieldMaker } from "helpers/formHelpers";
import { IFrameOptions } from "interfaces/fields";
import { FIELD } from "interfaces/forms";

export enum WIZARD {
  TYPE = "type",
  FORM_ENABLED = "formEnabled",
  FORM_TOKEN = "formToken",
  START_TIME = "startTime",
  END_TIME = "endTime",
  ORGANISERS = "organisers",
  PLACE = "placeName",
  TAGS = "tags",
  CATEGORY = "category",
  CITY = "city",
  HAS_FILTER_BY_NAME = "hasFilterByName",
  HAS_FILTER_BY_DATE = "hasFilterByDateTime",
  HAS_FILTER_BY_CITY = "hasFilterByCity",
  HAS_FILTER_BY_TAGS = "hasFilterByAllTags",
  HIGHLIGHT_ONLINE = "highlightOnline",
  HIGHLIGHTED_TAGS = "highlightedTags",
  PRESET_START_DATE = "presetFilterDate",
  PRESET_END_DATE = "presetFilterDateFin",
  PRESET_CITY = "presetFilterCity",
  PRESET_TAGS = "presetFilterTags",
  HAS_MAP = "hasMap",
  MAP_IS_PRIMARY = "mapIsPrimary",
  MAP_CENTER_POINT = "mapCenterPoint",
  MAP_ZOOM = "mapZoom",
  TITLE = "title",
  LANGUAGE = "language",
  LANGUAGE_CHOICE = "languageChoice",
  EVENT_COUNT = "eventCountInPage",
  OPEN_EVENT_IN_IFRAME = "openEventInIframe",
  LOGO = "logoUrl",
  PRIMARY_COLOR = "primaryColor",
  HEADER_COLOR = "headerColor",
  SECONDARY_COLOR = "secondaryColor",
  WHITE_LOGO = "whiteLogo",
  HEADER_HEIGHT = "headerHeight",
  WIDTH = "iframeWidth",
  HEIGHT = "iframeHeight",
}

export const Step1Fields = [
  SimpleFieldMaker(FIELD.SELECT, "Type", {
    id: WIZARD.TYPE,
    disabled: false,
    options: IFrameOptions,
  }),
  SimpleFieldMaker(FIELD.CHECKBOX, "Public Form enabled", {
    id: WIZARD.FORM_ENABLED,
  }),
  SimpleFieldMaker(FIELD.INPUT, "Form Graphql Token", {
    id: WIZARD.FORM_TOKEN,
    required: false,
    placeholder: "-insert token here-",
  }),
];

export const Step2BaseFields = [
  DbFieldMaker(FIELD.DATES, "Start Time", WIZARD.START_TIME),
  DbFieldMaker(FIELD.DATES, "End Time", WIZARD.END_TIME),
];

export const Step2aFields = [
  DbFieldMaker(FIELD.MULTISELECT, "Organization", WIZARD.ORGANISERS, []),
  DbFieldMaker(FIELD.MULTISELECT, "Place", WIZARD.PLACE, []),
];

export const Step2bFields = [
  DbFieldMaker(FIELD.MULTISELECT, "Tags", WIZARD.TAGS, []),
  DbFieldMaker(FIELD.MULTISELECT, "Category", WIZARD.CATEGORY, []),
  DbFieldMaker(FIELD.MULTISELECT, "City", WIZARD.CITY, []),
];

export const Step3Fields = [
  SimpleFieldMaker(FIELD.CHECKBOX, "Meklētājs pēc nosaukuma", {
    id: WIZARD.HAS_FILTER_BY_NAME,
  }),
  SimpleFieldMaker(FIELD.CHECKBOX, "Laiks", {
    value: true,
    id: WIZARD.HAS_FILTER_BY_DATE,
  }),
  SimpleFieldMaker(FIELD.CHECKBOX, "Vieta", {
    value: true,
    id: WIZARD.HAS_FILTER_BY_CITY,
  }),
  SimpleFieldMaker(FIELD.CHECKBOX, "Visi atslēgvārdi", {
    id: WIZARD.HAS_FILTER_BY_TAGS,
  }),
  SimpleFieldMaker(FIELD.CHECKBOX, "Izcelt Tiešsaistes vietu", {
    id: WIZARD.HIGHLIGHT_ONLINE,
  }),
  SimpleFieldMaker(FIELD.MULTISELECT, "Izceltie atslēgvārdi", {
    disabled: false,
    options: [],
    id: WIZARD.HIGHLIGHTED_TAGS,
  }),
  SimpleFieldMaker(FIELD.DATES, "Sākuma Laiks pēc noklusējuma", {
    id: WIZARD.PRESET_START_DATE,
    value: [],
  }),
  SimpleFieldMaker(FIELD.DATES, "Beigu Laiks pēc noklusējuma", {
    id: WIZARD.PRESET_END_DATE,
    value: [],
  }),
  SimpleFieldMaker(FIELD.MULTISELECT, "Pilsēta pēc noklusējuma", {
    disabled: false,
    options: [],
    id: WIZARD.PRESET_CITY,
  }),
  SimpleFieldMaker(FIELD.MULTISELECT, "Atslēgvārdi pēc noklusējuma", {
    disabled: false,
    options: [],
    id: WIZARD.PRESET_TAGS,
  }),
];

export const Step4Fields = [
  SimpleFieldMaker(FIELD.CHECKBOX, "Atļaut kartes skatu", {
    value: true,
    id: WIZARD.HAS_MAP,
  }),
  SimpleFieldMaker(FIELD.CHECKBOX, "Karte kā primārais skats", {
    id: WIZARD.MAP_IS_PRIMARY,
  }),
  SimpleFieldMaker(FIELD.LOCATION, "Kartes Centra Punkts", {
    id: WIZARD.MAP_CENTER_POINT,
  }),
  SimpleFieldMaker(FIELD.INTEGER, "Sākuma zooms", {
    value: 16,
    id: WIZARD.MAP_ZOOM,
  }),
];

export const Step5Fields = [
  SimpleFieldMaker(FIELD.INPUT, "Nosaukums", {
    value: "IFrame",
    id: WIZARD.TITLE,
  }),
  SimpleFieldMaker(FIELD.SELECT, "Valoda", {
    value: LOCALE.LATVIAN,
    options: LOCALE_OPTS,
    id: WIZARD.LANGUAGE,
  }),
  SimpleFieldMaker(FIELD.MULTISELECT, "Valodu izvēle", {
    value: [],
    options: LOCALE_OPTS,
    id: WIZARD.LANGUAGE_CHOICE,
  }),
  SimpleFieldMaker(FIELD.INTEGER, "Notikumu skaits vienā lapā", {
    value: 12,
    id: WIZARD.EVENT_COUNT,
  }),
  SimpleFieldMaker(FIELD.CHECKBOX, "Atvērt notikumu Ifreimā", {
    id: WIZARD.OPEN_EVENT_IN_IFRAME,
  }),
  SimpleFieldMaker(FIELD.INPUT, "Saite uz kompānijas logo", {
    id: WIZARD.LOGO,
  }),
  SimpleFieldMaker(FIELD.COLOR, "Primārā krāsa", {
    value: "#fccb00",
    id: WIZARD.PRIMARY_COLOR,
  }),
  SimpleFieldMaker(FIELD.COLOR, "Sekundārā krāsa", {
    value: "#ffffff",
    id: WIZARD.SECONDARY_COLOR,
  }),
  SimpleFieldMaker(FIELD.COLOR, "Rīkjoslas krāsa", {
    value: "rgb(167, 167, 167)",
    id: WIZARD.HEADER_COLOR,
  }),
  SimpleFieldMaker(FIELD.INTEGER, "Headera platums (px)", {
    value: 25,
    id: WIZARD.HEADER_HEIGHT,
  }),
  SimpleFieldMaker(FIELD.INPUT, "Platums", {
    value: "100%",
    id: WIZARD.WIDTH,
  }),
  SimpleFieldMaker(FIELD.INPUT, "Augstums", {
    value: "600px",
    id: WIZARD.HEIGHT,
  }),
];
