import { Dropdown } from "semantic-ui-react";

import FilterCheckbox from "components/atoms/Checkbox/FilterCheckbox";
import styled from "styles/styled";

export const StyledCheckboxGrid = styled.div`
  &&& {
    display: grid;
    grid-template-columns: 50% 50%;
    box-shadow: none !important;
    border: none;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    max-height: 15.42857143rem;
    position: static;
    overflow-y: auto;
    background: white;
    top: 100%;
    white-space: nowrap;
    width: inherit;
  }
`;

export const StyledTagsDivider = styled(Dropdown.Divider)({
  "&&&": { marginLeft: "40px", marginRight: "40px", marginTop: "20px" },
});

export const StyledCancelTagsButton = styled(FilterCheckbox)(({ theme }) => ({
  marginLeft: "auto",
  marginRight: "auto",
  width: "fit-content",
  color: theme.palette.primary.main,
}));

export const StyledTagsFrame = styled.div({
  "& > *": {
    marginBottom: "10px!important",
    "&:last-child": {
      marginBottom: "0",
    },
  },
});
