import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import { TUser } from "../context/GlobalContext";
import { IndexSignature } from "../interfaces/index";
import { parseJwt } from "../helpers/userHelpers";

const UserPoolData = {
  UserPoolId: process.env.REACT_APP_AWS_USER_POOL || "",
  ClientId: process.env.REACT_APP_AWS_CLIENT_ID || "",
  storage: window.sessionStorage,
};

export const UserPool = new CognitoUserPool(UserPoolData);

export interface ICongnitoAttribute {
  name: string;
  value: string;
}

export interface IFormAttribute extends ICongnitoAttribute {
  label: string;
  required?: boolean;
}

export function SignOutUser(user: TUser | null) {
  if (user) {
    const { username } = user;
    const cognitoUser = new CognitoUser({
      Username: username as string,
      Pool: UserPool,
    });
    cognitoUser.signOut();
  }
}
export const GetUserJWTtoken = async (): Promise<string> => {
  const cognitoUser = UserPool.getCurrentUser();
  let userToken = "";
  // eslint-disable-next-line no-unused-expressions
  cognitoUser?.getSession((err: any, sess: CognitoUserSession) => {
    const idToken = sess.getIdToken().getJwtToken();
    userToken = idToken;
  });
  return userToken;
};

export async function GetUserFromSession() {
  return new Promise((resolve, reject) => {
    const cognitoUser = UserPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession(async (err: Error, session: any) => {
        if (err) {
          reject(err);
        }
        if (session && session.isValid()) {
          const userToken = session.getAccessToken().getJwtToken();
          const tempUsr = parseJwt(userToken);
          cognitoUser.getUserAttributes(async (error, attributes) => {
            if (error) {
              reject(error);
            }
            if (attributes) {
              const userNameAtt = new CognitoUserAttribute({
                Name: "username",
                Value: tempUsr.username,
              });

              resolve([...attributes, userNameAtt]);
            }
          });
        }
      });
    } else {
      resolve("no user");
    }
  });
}

export function CoginitoAttributeMaker(state: IndexSignature) {
  const Attributes: CognitoUserAttribute[] = [];
  Object.keys(state).forEach((key: string) => {
    if (key !== "password") {
      Attributes.push(
        new CognitoUserAttribute({
          Name: key,
          Value: state[key],
        }),
      );
    }
  });

  return Attributes;
}

// TODO: Investigate how to remove casting return
// this due to promise overhead, and rejection handling it
// requires to extended promise call
// read here https://github.com/Microsoft/TypeScript/issues/30712
export async function SingInUser(
  username: string,
  password: string,
): Promise<CognitoUserAttribute[]> {
  return new Promise((resolve, reject) => {
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });
    cognitoUser.authenticateUser(authDetails, {
      async onSuccess(result: CognitoUserSession) {
        const userToken = result.getAccessToken().getJwtToken();
        const tempUsr = parseJwt(userToken);
        sessionStorage.setItem("token", userToken);

        cognitoUser.getUserAttributes(async (err, attributes) => {
          if (err) {
            reject(err);
          }
          if (attributes) {
            const userNameAtt = new CognitoUserAttribute({
              Name: "username",
              Value: tempUsr.username,
            });

            resolve([...attributes, userNameAtt]);
          }
        });
      },
      newPasswordRequired(userAttributes, requiredAttributes) {
        // the api doesn't accept this field back
        delete userAttributes.email_verified;
        delete userAttributes.phone_number_verified;

        cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
          onSuccess(res: any) {
            resolve(res);
          },
          onFailure(err) {
            reject(err);
          },
        });
      },
      onFailure(error) {
        reject(error);
      },
    });
  });
}
