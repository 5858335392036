export const isFile = (x: any): x is File =>
  (x as File)?.name !== undefined && (x as File)?.type !== undefined;

export const handleS3Api = async (
  url: string,
  settings?: Record<string, any>,
  skipResponseStream = false,
): Promise<any> => {
  try {
    const response = await fetch(url, settings);
    if (response.status !== 200)
      throw new Error(
        `Fetch did not return OK status : ${JSON.stringify(response)}`,
      );
    if (skipResponseStream) return response;
    const data = await response.json();
    if (data?.errorType || data?.errorMessage) {
      throw new Error(
        `Unexpected error within lambda. Reason: ${data.errorMessage}`,
      );
    }
    return data;
  } catch (err: any) {
    console.error("handleApi failed", err);
    throw new Error(err);
  }
};

export async function uploadImageFromFile(
  file: File,
  fileName?: string,
): Promise<string> {
  const newFile = file;
  const filename = fileName || encodeURI(file.name);
  const filetype = encodeURI(file.type);

  const response = await handleS3Api(
    `${process.env.REACT_APP_IFRAME_KURPES_BACKEND_URL}/image?filename=${filename}&filetype=${filetype}`,
    {
      method: "GET",
    },
  );
  await handleS3Api(
    `${response.presigned}`,
    {
      method: "PUT",
      body: newFile,
    },
    true,
  );

  return response.presigned?.split("?")[0];
}
