import {
  GET_TAGS_QUERY,
  GET_CATEGORIES_QUERY,
  GET_ORGS_QUERY,
} from "../graphql/queries";
import { graphqlFetch } from "../api";

// get form option Values
export const GetOrganizationOpts = async (
  ids: string[],
): Promise<Record<string, string>[]> => {
  const response: Promise<any> = graphqlFetch(GET_ORGS_QUERY(ids));
  type OrgType = {
    organisers: {
      id: string;
      name: string;
    }[];
  };
  const { data }: { data: OrgType } = await response;
  const { organisers } = data;
  return organisers.map((org) => ({
    key: org.id,
    value: org.id,
    text: org.name,
  }));
};

export const GetCategoryOpts = async (): Promise<Record<string, string>[]> => {
  const response: Promise<any> = graphqlFetch(GET_CATEGORIES_QUERY);
  const {
    data,
  }: {
    data: { categories: { id: string; title: string }[] };
  } = await response;
  const { categories } = data;
  return categories.map((cat) => ({
    key: cat.id,
    value: cat.id,
    text: cat.title,
  }));
};

export const GetTagOpts = async (): Promise<Record<string, string>[]> => {
  const response: Promise<any> = graphqlFetch(GET_TAGS_QUERY);
  const {
    data,
  }: {
    data: { tags: { id: string; tag: string }[] };
  } = await response;
  const { tags } = data;
  return tags.map((tag) => ({
    key: tag.id,
    value: tag.id,
    text: tag.tag,
  }));
};
