import React from "react";
import { Card, Placeholder } from "semantic-ui-react";
import styled from "styled-components";

export interface MainCardLoaderProps {}

const StyledPlaceholderImage = styled(Placeholder.Image)({
  "&&&": {
    height: "0",
    paddingBottom: "56.25%",
  },
});
const StyledPlaceholderCard = styled(Card)({
  "&&&": {
    border: "none",
    boxShadow: "none",
    width: "100%",
  },
});

const MainCardLoader: React.FC<MainCardLoaderProps> = () => {
  return (
    <StyledPlaceholderCard>
      <Placeholder fluid>
        <StyledPlaceholderImage />
      </Placeholder>
      <Card.Content>
        <Placeholder fluid>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Card.Content>
    </StyledPlaceholderCard>
  );
};

export default MainCardLoader;
