import React, { memo, useState, ChangeEvent, FocusEvent } from "react";
import { ColorResult, GithubPicker } from "react-color";
import { FormField } from "semantic-ui-react";

import FormSegment from "components/templates/Wrappers/FormSegment";
import styled from "styles/styled";
import { IBaseDynamicFormFieldProps } from "interfaces/forms";

export type FormColorPickerProps = IBaseDynamicFormFieldProps<string>;

const StyledCover = styled.div({
  position: "fixed",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
});

const StyledPopover = styled.div({
  position: "absolute",
  zIndex: 2,
});

const StyledDiv = styled.div({
  display: "grid",
  gridTemplateColumns: "4em 1fr",
});

const FormColorPicker: React.FC<FormColorPickerProps> = ({
  onChange,
  onBlur,
  id,
  label,
  required,
  value,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const onColorChange = (color: ColorResult) => {
    if (onChange) {
      onChange(color.hex, id);
    }
  };
  return (
    <FormField required={required}>
      {label && <label htmlFor={id}>{label}</label>}
      <StyledDiv>
        <FormSegment
          style={{
            background: value || "#FFFFFF",
            height: "100%",
          }}
        />
        <input
          {...props}
          id={id}
          required={required}
          value={value}
          onClick={() => setOpen(!open)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
              onChange(event.target.value, id);
            }
          }}
          onBlur={(event: FocusEvent<HTMLInputElement>) => {
            if (onBlur) {
              onBlur(event, id);
            }
          }}
        />
      </StyledDiv>
      {open && (
        <StyledPopover>
          <StyledCover onClick={() => setOpen(false)} />
          <GithubPicker color={value} onChangeComplete={onColorChange} />
        </StyledPopover>
      )}
    </FormField>
  );
};

export default memo(FormColorPicker);
