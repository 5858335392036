/* eslint-disable no-underscore-dangle */
import React, { memo } from "react";
import { FormField } from "semantic-ui-react";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { BaseOptions } from "flatpickr/dist/types/options";

import { IBaseDynamicFormFieldProps } from "interfaces/forms";

export interface FormDateTimeInputProps
  extends IBaseDynamicFormFieldProps<Date[]> {
  range?: boolean;
}

const L_TIME_MOMENT = moment.localeData().longDateFormat("lt");
const L_DATE_MOMENT = moment.localeData().longDateFormat("l");
const htmlDateTimeFormat = `${L_DATE_MOMENT} ${L_TIME_MOMENT}`;

const FormDateTimeInput: React.FC<FormDateTimeInputProps> = ({
  id,
  label,
  required,
  onChange,
  range,
  ...props
}) => {
  // Flatpickr does not accept children
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, ...restProps } = props;

  const options: Partial<BaseOptions> = {
    onChange: (val) => onChange?.(val, id),
    allowInput: true,
    dateFormat: htmlDateTimeFormat,
    enableTime: true,
    time_24hr: !L_TIME_MOMENT.toLowerCase().includes("a"),
    // this teaches flatpickr moment formatting tokens
    parseDate: (dateStr, format) => {
      return moment(dateStr, format, true).toDate();
    },
    formatDate: (date, format) => {
      return moment(date).format(format);
    },
    onClose(dates, currDateStr, picker) {
      picker.setDate(picker._input.value || "", true);
    },
  };

  if (range) options.mode = "range";

  return (
    <FormField required={required}>
      {label && <label htmlFor={id}>{label}</label>}
      <Flatpickr
        {...restProps}
        options={options}
        id={id}
        required={required}
        onChange={(val) => onChange?.(val, id)}
        {...{ autoComplete: "off" }}
      />
    </FormField>
  );
};

export default memo(FormDateTimeInput);
