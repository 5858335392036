/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */

import { useEffect, useCallback, SyntheticEvent, MouseEvent } from "react";
import { useImmerReducer } from "use-immer";

interface IForm<T> {
  error: string;
  fieldErrors: Record<keyof T | string, any>;
  isLoading: boolean;
  isSubmitted: boolean;
  isDirty: boolean;
  values: T;
}
export interface IFormError {
  key: string;
  path: string;
  unknown?: string;
  min?: number;
  max?: number;
  length?: number;
  regex?: string;
}

type TAction<T> =
  | { type: "submit" | "success" | "error" }
  | { type: "fieldError"; payload: string; fieldName: keyof T }
  | { type: "field"; payload: unknown; fieldName: keyof T }
  | { type: "reset"; payload: T };

function textSectionReducer<T>(draft: IForm<T>, action: TAction<T>) {
  switch (action.type) {
    case "reset": {
      draft.values = action.payload;
      draft.isDirty = false;
      return;
    }
    case "field": {
      (draft.values as any)[action.fieldName] = action.payload;
      draft.isDirty = true;
      if (draft.fieldErrors[action.fieldName]) {
        delete draft.fieldErrors[action.fieldName];
      }
      return;
    }
    case "fieldError": {
      draft.fieldErrors[action.fieldName] = action.payload;
      return;
    }
    case "submit": {
      draft.error = "";
      draft.isLoading = true;
      return;
    }
    case "success": {
      draft.isSubmitted = true;
      draft.isLoading = false;
      draft.isDirty = false;
      return;
    }
    case "error": {
      draft.error = "Form submission failed!!";
      draft.isLoading = false;
      return;
    }
    default: {
      draft.error = "Unrecognised action type used!!";
      draft.isLoading = false;
    }
  }
}

const useForm = <T>(initialData: T, submitFormQuery: (form: T) => void) => {
  const [formState, dispatchForm] = useImmerReducer<IForm<T>, TAction<T>>(
    textSectionReducer as any, // TODO fix type. It should be correct though ...
    {
      error: "",
      isLoading: false,
      isSubmitted: false,
      fieldErrors: {} as any,
      values: initialData,
      isDirty: false,
    },
  );
  const { values, isLoading, isSubmitted, error, fieldErrors, isDirty } =
    formState;

  const resetForm = useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      if (event) event.preventDefault();
      dispatchForm({
        type: "reset",
        payload: initialData,
      });
    },
    [initialData, dispatchForm],
  );

  const changeFormValue = useCallback(
    (data: unknown, id: string) => {
      // TODO guard against nonexisting ids!
      if (!id) return;
      dispatchForm({
        type: "field",
        fieldName: id as keyof T,
        payload: data,
      });
    },
    [dispatchForm],
  );

  const validateFormValue = useCallback(
    (e: any, id: string) => {
      if (!e.target.required) return;

      if (!e.target.value) {
        dispatchForm({
          type: "fieldError",
          fieldName: id as keyof T,
          payload: "Cannot be empty",
        });
      }
    },
    [dispatchForm],
  );

  const submitForm = async (event: SyntheticEvent) => {
    event.preventDefault();
    dispatchForm({ type: "submit" });
    try {
      await submitFormQuery(values);
      dispatchForm({ type: "success" });
    } catch (err) {
      dispatchForm({ type: "error" });
    }
  };

  useEffect(() => {
    resetForm();
  }, [resetForm, initialData]);

  return {
    values,
    isLoading,
    isSubmitted,
    error,
    fieldErrors,
    submitForm,
    changeFormValue,
    validateFormValue,
    resetForm,
    isDirty,
  };
};

export default useForm;
