import React, { CSSProperties } from "react";
import { ButtonProps } from "semantic-ui-react";

import {
  BaseButton,
  PrimaryButton,
  FilterButton,
  TextButton,
  SecondaryButton,
} from "./SimpleButton.styles";

export enum BUTTON {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DEFAULT = "default",
  FILTER = "filter",
  TEXT = "text",
}

export interface SimpleButtonProps {
  id?: string;
  variant?: BUTTON;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  animated?: boolean;
  disabled?: boolean;
  icon?: ButtonProps["icon"];
  fluid?: ButtonProps["fluid"];
  loading?: boolean;
  href?: string;
  rel?: string;
  target?: string;
  floated?: "right" | "left";
  type?: "button" | "reset" | "submit";
  style?: CSSProperties;
  size?: "small" | "medium";
}

const SimpleButton: React.FC<SimpleButtonProps> = ({
  id,
  children,
  variant,
  onClick,
  animated,
  disabled,
  icon,
  fluid,
  loading,
  href,
  rel,
  target,
  floated,
  type,
  style,
  size,
}) => {
  let CurrentButton = BaseButton;
  if (variant === BUTTON.PRIMARY) {
    CurrentButton = PrimaryButton;
  } else if (variant === BUTTON.SECONDARY) {
    CurrentButton = SecondaryButton;
  } else if (variant === BUTTON.FILTER) {
    CurrentButton = FilterButton;
  } else if (variant === BUTTON.TEXT) {
    CurrentButton = TextButton;
  }
  return (
    <CurrentButton
      id={id}
      onClick={onClick}
      type={type || "button"}
      animated={animated ? "vertical" : false}
      disabled={disabled}
      icon={icon}
      fluid={fluid}
      loading={loading}
      rel={rel}
      href={href}
      target={target}
      floated={floated}
      style={style}
      size={size}
    >
      {children}
    </CurrentButton>
  );
};
export default SimpleButton;
