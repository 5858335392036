import { useContext } from "react";

import { FilterContext, IFilterContext } from "context/FilterContext";

const useFilterContext = (): IFilterContext => {
  const context = useContext(FilterContext);
  if (context === null) {
    throw new Error(
      "useFilterContext cannot be used without respective provider!",
    );
  }

  return context;
};

export default useFilterContext;
