import { lighten, darken } from "polished";
import { CSSProperties } from "styled-components";

import IFrame from "api/interfaces/IFrame";

// Intentional. Theme is to be inferred from this function.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const themeMaker = (opts: IFrame["customStyles"]) => ({
  space: {
    0: "0em",
    1: "0.5em", // 8px
    2: "1em", // 16px
    3: "1.5em", // 24px
    4: "2em", // 32px
    5: "2.5em",
    6: "3em",
  },
  palette: {
    primary: {
      light: lighten(0.5, opts.primaryColor),
      main: opts.primaryColor,
      dark: darken(0.03, opts.primaryColor),
    },
    secondary: {
      main: opts.secondaryColor,
    },
    mono: {
      white: "#FFFFFF",
      lit: "#f8f8f8", // dropzone Background Color
      lightest: "#F4F4F4",
      lighter: "#E7E7E7", // dropzone Border Color
      light: "#BBBBBB", // footer text, dropzone Text Color
      base: "#A7A7A7", // underlined inactive tabs
      dark: "#7F7F7F", // unemphasized menu items
      darker: "#464646", // Footer background
      darkest: "#333333", // caption text
      dim: "#333333",
      black: "#000000", // regular text
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c00",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
    },
    special: {
      header: opts.headerColor || "#FFFFFF",
      link: "#0067C5",
    },
  },
  boxShadows: {
    light: "0 0 20px rgba(34,36,38,.15)",
  },
  typography: {
    fontFamily: "'Raleway', sans-serif",
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    h1: {
      // Izceltās ziņas virsraksts
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 600,
      fontSize: "2rem", // 23px no Alises
      lineHeight: 1.2,
      color: "#000000",
      margin: "0.625em",
    },
    h2: {
      // Parastas ziņas virsraksts
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 600,
      fontSize: "1.5rem", // 14px no Alises
      lineHeight: 1.2,
      color: "#000000",
      margin: "0.625em",
      // textTransform: "capitalize" as CSSProperties["textTransform"],
    },
    h3: {
      // Menu itemu virsraksti
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 500,
      fontSize: "1.1rem", // 12px no Alises
      lineHeight: 1.2,
      color: "#000000",
      letterSpacing: 1,
      margin: "0.625em",
    },
    h4: {
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: 1.2,
      color: "#000000",
      margin: "0.625em",
    },
    h5: {
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 700,
      fontSize: "1.1rem",
      lineHeight: 1.2,
      color: "#000000",
      margin: "0.625em",
    },
    title1: {
      // Sadaļu virsraksti
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 600,
      fontSize: "1.1rem", // 12 px no Alises
      lineHeight: 1,
      letterSpacing: "0.1em",
      textTransform: "uppercase" as CSSProperties["textTransform"], // TODO fix type widening
      color: "#000000",
    },
    title2: {
      // Menu Sadaļas virsraksts
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 700,
      fontSize: "1.1rem", // 12 px no Alises
      lineHeight: 1,
      letterSpacing: "0.1em",
      textTransform: "uppercase" as CSSProperties["textTransform"],
      color: "#7F7F7F",
    },
    title3: {
      // Video, galerijas sadaļas visraksts
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 700,
      fontSize: "1.5rem", // 12 px no Alises
      lineHeight: 1,
      letterSpacing: "0.1em",
      textTransform: "uppercase" as CSSProperties["textTransform"],
      color: "#FFFFFF",
    },
    title4: {
      // Reklāmas sadaļas virsraksts
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 400,
      fontSize: "0.9rem", // 9px no Alises
      lineHeight: 1,
      textTransform: "uppercase" as CSSProperties["textTransform"],
      color: "#333333",
      margin: "1em 0 1em",
    },
    caption: {
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 400,
      fontSize: "1rem", // 9px no Alises
      lineHeight: 1,
      color: "#333333",
    },
    smallCaption: {
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 400,
      fontSize: "0.8rem",
      lineHeight: 1,
      color: "#333333",
    },
    body1: {
      // Galvenais teksts
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 400,
      fontSize: "1.2rem",
      lineHeight: 1,
      color: "#000000",
    },
    body2: {
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1,
      color: "#000000",
    },
    button1: {
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 700,
      fontSize: "1.1rem",
      lineHeight: 1,
      textTransform: "uppercase" as CSSProperties["textTransform"],
      color: "#FFFFFF",
      letterSpacing: 0,
    },
    button2: {
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 700,
      fontSize: "1.1rem",
      lineHeight: 1,
      textTransform: "uppercase" as CSSProperties["textTransform"],
      color: opts.primaryColor,
      letterSpacing: 0,
    },
  },
});

export default themeMaker;
