import React, { useRef, useCallback } from "react";

import useFilterContext from "hooks/useFilterContext";
import ClusterMap from "components/molecules/Maps/ClusterMap";
import useIframeContext from "hooks/useIFrameContext";
import useResizeDetector from "hooks/useResizeDetector";
import { TildeTranslateArray } from "api/tildeApi";
import useGlobalContext from "hooks/useGlobalContext";
import useAPI from "hooks/useApi";

export interface IFrameMapGridProps {
  external?: boolean;
}

const IFrameMapGrid: React.FC<IFrameMapGridProps> = () => {
  const { displayData } = useFilterContext();
  const {
    iframe: {
      mapSettings: { center, zoom },
    },
  } = useIframeContext();
  const ref = useRef(null);

  const {
    element: { top },
  } = useResizeDetector(ref, [displayData]);

  const {
    locale: { currentLocale },
  } = useGlobalContext();
  const tilde = useCallback(async () => {
    const titles = displayData?.map((card) => card.name || "") || [];
    const res = await TildeTranslateArray(titles, currentLocale);
    return res;
  }, [displayData, currentLocale]);
  const { response = [] } = useAPI(tilde, []);

  return (
    <div style={{ height: `calc(100vh - ${top}px)` }} ref={ref}>
      <ClusterMap
        translatedTitles={response || []}
        events={displayData}
        initialCenter={center}
        initialZoom={zoom}
      />
    </div>
  );
};

export default IFrameMapGrid;
