import React, { useState, useCallback, MouseEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import produce from "immer";

import useFilterContext from "hooks/useFilterContext";
import ControlledDropdown from "components/templates/Modals/ControlledDropdown/ControlledDropdown";
import FilterCheckbox from "components/atoms/Checkbox/FilterCheckbox";
import Typography from "components/atoms/Typography/Typography";
import { TYPOGRAPHY } from "components/atoms/Typography/Typography.styles";
import SimpleButton, {
  BUTTON,
} from "components/atoms/Buttons/SimpleButton/SimpleButton";
import useAPI from "hooks/useApi";
import useGlobalContext from "hooks/useGlobalContext";
import { TildeTranslateArray } from "api/tildeApi";

import {
  StyledCancelTagsButton,
  StyledCheckboxGrid,
  StyledTagsDivider,
} from "./TagsFilter/TagsFilter.styles";

export interface PlaceFilterProps {
  highlightOnline: boolean;
  showCity: boolean;
}

const ONLINE = "Tiešsaistē";
const getTriggerText = (
  city: string[],
  highlightOnline: boolean,
  t: TFunction,
) => {
  if (city.length === 0) return t("Filters.City");
  if (city.length === 1 && city[0] === ONLINE && highlightOnline)
    return t("Filters.City");

  const filteredCity = highlightOnline
    ? city.filter((c) => c !== ONLINE)
    : city;
  let triggerText = filteredCity[0];
  if (filteredCity.length > 1) triggerText += `, ${filteredCity[1]}`;
  if (filteredCity.length > 2) triggerText += `, +${filteredCity.length - 2}`;

  return triggerText;
};

const CityFilter: React.FC<PlaceFilterProps> = ({
  highlightOnline,
  showCity,
}) => {
  const {
    filters: { city },
    filterOpts,
    setFilter,
  } = useFilterContext();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const options = useMemo(
    () =>
      filterOpts.city.filter((c) => c.text !== (highlightOnline ? ONLINE : "")),
    [filterOpts.city, highlightOnline],
  );

  const online = city.includes(ONLINE);

  const setCity = useCallback((cities: string[]) => setFilter(cities, "city"), [
    setFilter,
  ]);

  const toggleCityByTag = useCallback(
    (tag: string | undefined) => {
      if (!tag) return;
      setCity(
        produce(city, (draft) => {
          const index = draft.indexOf(tag);
          if (index < 0) draft.push(tag);
          if (index >= 0) draft.splice(index, 1);
        }),
      );
    },
    [setCity, city],
  );

  const toggleCity = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      const tag = event.currentTarget?.id as string;
      toggleCityByTag(tag);
    },
    [toggleCityByTag],
  );

  const {
    locale: { currentLocale },
  } = useGlobalContext();
  const tilde = useCallback(async () => {
    const titles = options?.map((opt) => opt.text || "") || [];
    const res = await TildeTranslateArray(titles, currentLocale);
    return res;
  }, [options, currentLocale]);
  const { response = [] } = useAPI(tilde, []);

  return (
    <>
      {showCity && (
        <ControlledDropdown
          open={open}
          setOpen={setOpen}
          trigger={getTriggerText(city, highlightOnline, t)}
          options={options}
          empty={
            city.length === 0 ||
            (city.length === 1 && online && highlightOnline)
          }
        >
          <StyledCheckboxGrid>
            {options.map((option, idx) => {
              if (option.text) {
                return (
                  <div
                    key={option.value as string}
                    id={option.value as string}
                    onClick={toggleCity}
                    role="button"
                    tabIndex={idx}
                  >
                    <FilterCheckbox
                      id={`${option.value}-checkbox`}
                      checked={city.includes(option.value as string)}
                    >
                      {response[idx] || option.text} ({option.count})
                    </FilterCheckbox>
                  </div>
                );
              }
              return null;
            })}
          </StyledCheckboxGrid>
          <StyledTagsDivider />
          <StyledCancelTagsButton
            id="cancel-cities"
            checked
            onClick={() => {
              setCity([]);
              setOpen(false);
            }}
            variant="cross"
          >
            <Typography type={TYPOGRAPHY.BUTTON2}>
              {t("Filters.Cancel")}
            </Typography>
          </StyledCancelTagsButton>
        </ControlledDropdown>
      )}
      {highlightOnline && (
        <SimpleButton
          variant={online ? BUTTON.FILTER : BUTTON.PRIMARY}
          onClick={() => toggleCityByTag(ONLINE)}
        >
          {t("Filters.Online")}
        </SimpleButton>
      )}
    </>
  );
};

export default CityFilter;
