import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "moment/locale/uk";
import "moment/locale/ru";
import "moment/locale/en-gb";
import "moment/locale/lv";

import { LOCALE } from "locales/locale";
import { GetUserFromSession, GetUserJWTtoken, SignOutUser } from "api/userApi";
import { MapUserDataToUserObject } from "helpers/userHelpers";

import GlobalStateContext, { TUser } from "./GlobalContext";

interface IGlobalContextState {
  loading: boolean | null;
  locale: LOCALE;
  userData: TUser | null;
  token: () => Promise<string>;
}

class GlobalContextProvider extends Component<
  WithTranslation,
  IGlobalContextState
> {
  state = {
    loading: true,
    locale: LOCALE.LATVIAN,
    userData: null,
    token: async () => {
      return "";
    },
  };

  async componentDidMount() {
    try {
      const resp = await GetUserFromSession();
      const setLocale = sessionStorage.getItem("locale");
      if (setLocale !== "" && setLocale !== null) {
        this.handleSetLocale(setLocale as LOCALE);
      }
      if (Array.isArray(resp)) {
        const userData = MapUserDataToUserObject(resp);
        this.setState({ userData: userData as TUser });
      }
    } catch (err) {
      this.setState({ userData: null });
    } finally {
      this.setState({ loading: false });
    }
  }

  handleSetLocale = async (language: LOCALE) => {
    await this.props.i18n?.changeLanguage(language);
    sessionStorage.setItem("locale", language);
    this.setState({ locale: language });
  };

  handleSetUser = (userData: TUser) => {
    this.setState({ userData, loading: false });
  };

  getUserToken = async (): Promise<string> => GetUserJWTtoken();

  handleLogout = () => {
    sessionStorage.removeItem("token");
    const { userData } = this.state;
    if (userData !== null) {
      SignOutUser(userData);
      this.setState((prevState) => ({ ...prevState, userData: null }));
    }
  };

  render() {
    const { locale, userData, loading } = this.state;
    const { children } = this.props;

    return (
      <GlobalStateContext.Provider
        value={{
          locale: {
            currentLocale: locale,
            setLocale: this.handleSetLocale,
          },
          user: {
            token: this.getUserToken,
            userData,
            loading,
            setUser: this.handleSetUser,
            logout: this.handleLogout,
          },
        }}
      >
        {children}
      </GlobalStateContext.Provider>
    );
  }
}

export default withTranslation()(GlobalContextProvider);
