import React from "react";
import { StrictImageProps } from "semantic-ui-react";

import { PLACEHOLDER_IMAGE_16_TO_9 } from "constants/images";
import styled from "styles/styled";

import PlaceholdableImage from "../PlaceholdableImage/PlaceholdableImage";

export enum ASPECT {
  RATIO_18_TO_3 = "16.67%",
  RATIO_18_TO_6 = "33.33%",
  RATIO_21_TO_9 = "42.85%",
  RATIO_17_TO_9 = "52.94%",
  RATIO_16_TO_9 = "56.25%",
  RATIO_4_TO_3 = "75%",
  RATIO_3_TO_22 = "66.66%",
  RATIO_8_TO_5 = "62.5%",
  RATIO_1_TO_1 = "100%",
  EVENT_VIEW_BACKGROUND = "45%",
}

const StyledFixedRatioImageWrapper = styled.div<{
  aspectRatio?: ASPECT;
}>(({ aspectRatio }) => ({
  position: "relative",
  width: "100%",
  height: "0",
  overflow: "hidden",
  paddingBottom: aspectRatio,
}));

const StyledPlaceholdableImage = styled(PlaceholdableImage)({
  "&&": {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    "> .image": {
      objectFit: "cover",
    },
  },
});

export interface FixedRatioImageProps extends StrictImageProps {
  src?: string;
  alt?: string;
  aspectRatio?: ASPECT;
  placeholderImage?: string;
  className?: string;
}

const FixedRatioImage: React.FC<FixedRatioImageProps> = ({
  src,
  alt,
  aspectRatio = ASPECT.RATIO_16_TO_9,
  placeholderImage = PLACEHOLDER_IMAGE_16_TO_9,
  className,
  ...props
}) => {
  return (
    <StyledFixedRatioImageWrapper aspectRatio={aspectRatio}>
      <StyledPlaceholdableImage
        alt={alt}
        src={src}
        placeholderImage={placeholderImage}
        className={className}
        {...props}
      />
    </StyledFixedRatioImageWrapper>
  );
};

export default FixedRatioImage;
