import React from "react";
import { useTranslation } from "react-i18next";

import useFilterContext from "hooks/useFilterContext";
import FormTextInput from "components/molecules/FormFields/FormTextInput";
import styled from "styles/styled";

export interface NameFilterProps {}

const StyledNameFilter = styled(FormTextInput)`
  height: 24px;
  padding: 15px;
  border: none;
  border-radius: 20px;
`;

const NameFilter: React.FC<NameFilterProps> = () => {
  const {
    filters: { name },
    setFilter,
  } = useFilterContext();
  const { t } = useTranslation();

  return (
    <StyledNameFilter
      placeholder={t("Filters.SearchByTitle")}
      id="name"
      value={name}
      onChange={(value: string) => setFilter(value, "name")}
    />
  );
};

export default NameFilter;
