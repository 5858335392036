import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";

import GlobalContextProvider from "context/GlobalContextProvider";
import i18n from "locales/i18n";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./index.css";
import "semantic-ui-css/semantic.min.css";
import "flatpickr/dist/themes/material_green.css";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <GlobalContextProvider>
        <App />
      </GlobalContextProvider>
    </BrowserRouter>
  </I18nextProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
