export const calendarFormat = (m: any, now: any) => {
  const diff = m.diff(now, "days", true);

  const nextWeek = now.startOf("week").add(1, "week");

  if (diff < 0) {
    return "sameElse";
  }
  if (diff < 1) {
    return "sameDay";
  }
  if (diff < 2) {
    return "nextDay";
  }
  if (m.isBefore(nextWeek)) {
    return "sameWeek";
  }
  if (m.year() === now.year()) {
    return "sameYear";
  }

  return "sameElse";
};

export const CALENDAR = {
  LV: {
    calendar: {
      sameDay: "[Šodien], LT",
      nextDay: "[Rīt], LT",
      sameWeek: "dddd, LT",
      sameYear: "D. MMM, LT",
      sameElse: "L",
    },
    weekdays: [
      "Svētdien",
      "Pirmdien",
      "Otrdien",
      "Trešdien",
      "Ceturtdien",
      "Piektdien",
      "Sestdien",
    ],
    weekdaysShort: ["Sv", "Pr", "Ot", "Tr", "Ce", "Pk", "Se"],
  },
  EN: {
    calendar: {
      sameDay: "[Today], LT",
      nextDay: "[Tomorrow], LT",
      sameWeek: "dddd, LT",
      sameYear: "D. MMM, LT",
      sameElse: "L",
    },
  },
  UK: {
    calendar: {
      sameDay: "[Сьогодні], LT",
      nextDay: "[Завтра], LT",
      sameWeek: "dddd, LT",
      sameYear: "D. MMM, LT",
      sameElse: "L",
    },
    weekdays: [
      "Неділя",
      "Понеділок",
      "Вівторок",
      "Середа",
      "Четвер",
      "П'ятниця",
      "Субота",
    ],
  },
  RU: {
    calendar: {
      sameDay: "[Сегодня], LT",
      nextDay: "[Завтра], LT",
      sameWeek: "dddd, LT",
      sameYear: "D. MMM, LT",
      sameElse: "L",
    },
    weekdays: [
      "Воскресенье",
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
    ],
  },
  DE: {
    calendar: {
      sameDay: "[Heute], LT",
      nextDay: "[Morgen], LT",
      sameWeek: "dddd, LT",
      sameYear: "D. MMM, LT",
      sameElse: "L",
    },
  },
};
