import React from "react";

import CardGrid from "components/templates/Grids/CardGrid";

import MainCardLoader from "./MainCardLoader";

export interface IFrameCardGridLoaderProps {}

const EMPTY_ARRAY = new Array(16).fill(undefined);
const IFrameCardGridLoader: React.FC<IFrameCardGridLoaderProps> = () => {
  return (
    <CardGrid>
      {EMPTY_ARRAY.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <MainCardLoader key={index} />
      ))}
    </CardGrid>
  );
};

export default IFrameCardGridLoader;
