/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useCallback, useState, useMemo, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import produce from "immer";

import useFilterContext from "hooks/useFilterContext";
import SimpleButton, {
  BUTTON,
} from "components/atoms/Buttons/SimpleButton/SimpleButton";
import FilterCheckbox from "components/atoms/Checkbox/FilterCheckbox";
import Typography from "components/atoms/Typography/Typography";
import { TYPOGRAPHY } from "components/atoms/Typography/Typography.styles";
import ControlledDropdown from "components/templates/Modals/ControlledDropdown/ControlledDropdown";
import { LOCALE } from "locales/locale";

import {
  StyledCancelTagsButton,
  StyledCheckboxGrid,
  StyledTagsDivider,
  StyledTagsFrame,
} from "./TagsFilter.styles";

export interface TagsFilterProps {
  buttonTagOpts: string[];
  showAllTagFilter: boolean;
}

const TagsFilter: React.FC<TagsFilterProps> = ({
  buttonTagOpts,
  showAllTagFilter,
}) => {
  const {
    allTags,
    filters: { tags },
    filterOpts: { tags: relevantTagOpts },
    setFilter,
  } = useFilterContext();
  const { t, i18n } = useTranslation();
  const locale = (i18n?.language || LOCALE.LATVIAN) as LOCALE;
  const [open, setOpen] = useState(false);

  const [selectTags, buttonTags] = useMemo(
    () => [
      tags.filter((tag) => !buttonTagOpts.includes(tag)) || [],
      tags.filter((tag) => buttonTagOpts.includes(tag)) || [],
    ],
    [tags, buttonTagOpts],
  );

  const restOptions = useMemo(
    () =>
      relevantTagOpts
        .filter((tag) => !buttonTagOpts.find((hTag) => hTag === tag.value))
        .map((tag) => ({ ...tag, text: tag.t[locale] })),
    [buttonTagOpts, relevantTagOpts, locale],
  );

  const addTag = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      const tag = event.currentTarget.id as string;
      if (!tag) return;
      setFilter(
        produce(tags, (draft) => {
          const index = draft.indexOf(tag);
          if (index < 0) draft.push(tag);
          if (index >= 0) draft.splice(index, 1);
        }),
        "tags",
      );
    },
    [setFilter, tags],
  );

  const changeOnlySelectTags = useCallback(
    (newVals: string[]) => {
      setFilter([...buttonTags, ...newVals], "tags");
    },
    [setFilter, buttonTags],
  );

  return (
    <StyledTagsFrame>
      {buttonTagOpts.map((tag, idx) => {
        const isSelected = buttonTags.find((curr) => curr === tag);
        const variant = isSelected ? BUTTON.FILTER : BUTTON.PRIMARY;
        return (
          <SimpleButton
            variant={variant}
            onClick={addTag}
            id={tag}
            size="small"
            key={`${tag}-${idx + 1}`}
          >
            {allTags.find((tg) => tg.value === tag)?.t[locale] || tag}
          </SimpleButton>
        );
      })}
      {showAllTagFilter && (
        <ControlledDropdown
          open={open}
          setOpen={setOpen}
          trigger={t("Filters.Keywords")}
          options={restOptions}
          value={selectTags}
          onChange={changeOnlySelectTags}
          empty={selectTags.length === 0}
        >
          <StyledCheckboxGrid>
            {restOptions.map((option) => (
              <div
                key={option.value as string}
                id={option.value as string}
                onClick={addTag}
                role="button"
              >
                <FilterCheckbox
                  id={`${option.value}-checkbox`}
                  checked={selectTags.includes(option.value as string)}
                >
                  {option.t[locale]} ({option.count})
                </FilterCheckbox>
              </div>
            ))}
          </StyledCheckboxGrid>
          <StyledTagsDivider />
          <StyledCancelTagsButton
            id="cancel-tags"
            checked
            onClick={() => changeOnlySelectTags([])}
            variant="cross"
          >
            <Typography type={TYPOGRAPHY.BUTTON2}>
              {t("Filters.CancelAll")}
            </Typography>
          </StyledCancelTagsButton>
        </ControlledDropdown>
      )}
    </StyledTagsFrame>
  );
};

export default TagsFilter;
