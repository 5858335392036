import { CSSObject } from "styled-components";

import styled from "styles/styled";

import DynamicRefComponent from "../Primitives/DynamicRefComponent";

export interface StyledTypographyProps {
  align: CSSObject["textAlign"];
  color?: string;
  margin?: string;
  id?: string;
  wrap?: boolean;
}

export enum TYPOGRAPHY {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  TITLE1 = "title1",
  TITLE2 = "title2",
  TITLE3 = "title3",
  TITLE4 = "title4",
  CAPTION = "caption",
  SMALL_CAPTION = "smallCaption",
  BUTTON1 = "button1",
  BUTTON2 = "button2",
  BODY1 = "body1",
  BODY2 = "body2",
}

// TODO better theme typing
function StylTypography(type: TYPOGRAPHY) {
  return styled(DynamicRefComponent)<StyledTypographyProps>(
    ({ theme, align, color, margin, wrap }) => {
      const currentTheme = theme.typography[type];
      const currentColor = currentTheme.color;

      return {
        "&&&": {
          ...currentTheme,
          textAlign: align,
          color: color || currentColor,
          ...(margin ? { margin } : {}),
          ...(wrap
            ? {
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }
            : {}),
        },
      };
    },
  );
}

export const StyledH1 = StylTypography(TYPOGRAPHY.H1);
export const StyledH2 = StylTypography(TYPOGRAPHY.H2);
export const StyledH3 = StylTypography(TYPOGRAPHY.H3);
export const StyledH4 = StylTypography(TYPOGRAPHY.H4);
export const StyledH5 = StylTypography(TYPOGRAPHY.H5);
export const StyledTitle1 = StylTypography(TYPOGRAPHY.TITLE1);
export const StyledTitle2 = StylTypography(TYPOGRAPHY.TITLE2);
export const StyledTitle3 = StylTypography(TYPOGRAPHY.TITLE3);
export const StyledTitle4 = StylTypography(TYPOGRAPHY.TITLE4);
export const StyledBody1 = StylTypography(TYPOGRAPHY.BODY1);
export const StyledBody2 = StylTypography(TYPOGRAPHY.BODY2);
export const StyledButton1Text = StylTypography(TYPOGRAPHY.BUTTON1);
export const StyledButton2Text = StylTypography(TYPOGRAPHY.BUTTON2);
export const StyledCaption = StylTypography(TYPOGRAPHY.CAPTION);
export const StyledSmallCaption = StylTypography(TYPOGRAPHY.SMALL_CAPTION);
