import { RadioProps, StrictDropdownItemProps } from "semantic-ui-react";
import { Moment } from "moment";
import slugify from "slugify";
import { FocusEvent } from "react";

export enum WIZARD_OPTION {
  ORGANISATION = "Organization",
  OTHER = "Institution or Other",
}

export interface IDropDownOption {
  key: string;
  value: string;
  text: string;
}

export type TFormFieldValues =
  | boolean
  | string
  | string[]
  | File
  | Moment
  | Date[]
  | number;

export declare type IInputType =
  | "email"
  | "number"
  | "text"
  | "password"
  | "tel"
  | "url";

export type TFormOnChange<T = TFormFieldValues> = (
  data: T,
  id?: string,
) => void;

export interface IBaseFormFieldProps<T = TFormFieldValues> {
  value: T;
  id?: string;
  label?: string;
  defaultValue?: T;
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  options?: Array<StrictDropdownItemProps | RadioProps>;
  onChange?: TFormOnChange<T>;
  onBlur?: (event: FocusEvent<HTMLInputElement>, id: string) => void;
  error?: string;
  className?: string;
  inputType?: IInputType;
}

const dropdownOptionMaker = (label: string) => {
  const newDropDownOption: IDropDownOption = {
    key: slugify(label, { lower: true }),
    value: label,
    text: label,
  };
  return newDropDownOption;
};

export const TestOptions: IDropDownOption[] = [
  { key: "value1", value: "value1", text: "value1" },
  { key: "value2", value: "value2", text: "value2" },
];

export const IFrameOptions: IDropDownOption[] = [
  dropdownOptionMaker(WIZARD_OPTION.ORGANISATION),
  dropdownOptionMaker(WIZARD_OPTION.OTHER),
];
