import React, { FormEvent, memo } from "react";
import { Checkbox, CheckboxProps, FormField } from "semantic-ui-react";

import { IBaseDynamicFormFieldProps } from "interfaces/forms";

export interface FormCheckboxProps extends IBaseDynamicFormFieldProps<boolean> {
  toggle?: boolean;
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  id,
  onChange,
  value,
  required,
  ...props
}) => (
  <FormField required={required}>
    <Checkbox
      {...props}
      checked={value}
      required={required}
      id={id}
      onChange={(_: FormEvent, data: CheckboxProps) => {
        const newVal = (data.checked as boolean) || false;
        if (onChange) onChange(newVal, id);
      }}
    />
  </FormField>
);

export default memo(FormCheckbox);
