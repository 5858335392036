export const GET_ORGS_QUERY = (ids: string[]): string => {
  return `query getOrganiserData {
    organisers(where:{id: {in:[${ids
      .map((id) => {
        return `"${id}"`;
      })
      .join(",")}]}}) {
        id
        name
        slug
    }
 }`;
};

export const GET_CATEGORIES_QUERY = `query getCategoryData {
  categories {
    slug
    id
    title
  }
}`;

export const GET_TAGS_QUERY = `query getTagData {
  tags {
    id
    tag
}          
}`;
