/* eslint-disable consistent-return */
import { LOCALE } from "locales/locale";

import { AWSurlDEV, handleApi, handleApiPost } from "./api";

const TRANSLATE_ARRAY_API =
  "https://www.letsmt.eu/ws/service.svc/json/TranslateArray";
const TRANSLATE_API = "https://www.letsmt.eu/ws/service.svc/json/Translate";

const LV_UKR_ID = "smt-356ba853-2cb5-458f-a8a9-d9403ad65e52";
const LV_RU_ID = "smt-6f6d30fe-8f0a-4b99-ab25-16c1f38a2290";
const LV_ENG_ID = "smt-d00bc57b-de3e-44ca-832a-8a5920ac24f8";
const LV_DE_ID = "smt-77bdbbd5-50b4-4c9d-ac2f-8f5dee70b3d3";

const PostTildeSettings = (opts: Record<string, any>, language?: LOCALE) => {
  let systemID = "";
  if (language === LOCALE.ENGLISH) systemID = LV_ENG_ID;
  if (language === LOCALE.RUSSIAN) systemID = LV_RU_ID;
  if (language === LOCALE.UKRAINIAN) systemID = LV_UKR_ID;
  if (language === LOCALE.GERMAN) systemID = LV_DE_ID;

  if (!systemID) return;

  const body = {
    appID: process.env.REACT_APP_TILDE_API_KEY,
    systemID,
    options: "",
    ...opts,
  };

  return {
    method: "POST",
    headers: {
      "client-id": process.env.REACT_APP_TILDE_API_KEY,
      "Content-Type": "",
    },
    body: JSON.stringify(body),
  };
};

export async function TildeTranslateText(
  text: string,
  language?: LOCALE,
): Promise<string> {
  const body = PostTildeSettings({ text }, language);

  if (!body) return "";
  if (!text) return "";

  try {
    return handleApi(TRANSLATE_API, body);
  } catch (err) {
    console.error("Tilde api failed", err);
    return "";
  }
}

export async function TildeTranslateArray(
  textArray: string[],
  language?: LOCALE,
): Promise<string[]> {
  const body = PostTildeSettings({ textArray }, language);

  if (!body) return [];
  if (!textArray.length) return [];

  try {
    return handleApi(TRANSLATE_ARRAY_API, body);
  } catch (err) {
    console.error("Tilde api failed", err);
    return [];
  }
}

export const TildeUpdateTranslation = async (body: {
  text: string;
  translation: string;
  language: string;
}): Promise<void> => {
  return handleApiPost(`${AWSurlDEV}/edit-tilde`, body);
};
