import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { LoadScript, LoadScriptProps } from "@react-google-maps/api";

import useFilterContext from "hooks/useFilterContext";
import useIframeContext from "hooks/useIFrameContext";
import SimpleButton, {
  BUTTON,
} from "components/atoms/Buttons/SimpleButton/SimpleButton";
import styled from "styles/styled";

import IFrameCardGrid from "../IFrameCardGrid/IFrameCardGrid";
import IFrameMapGrid from "../IFrameMapGrid/IFrameMapGrid";

export interface IFrameViewSelectorProps {}

const StyledCenterButton = styled.div({
  textAlign: "center",
  padding: "5px",
});
const StyledMapPlaceholder = styled.div({
  height: "10px",
});

const GOOGLE_LIBRARIES: LoadScriptProps["libraries"] = ["places"];

const IFrameViewSelector: React.FC<IFrameViewSelectorProps> = () => {
  const { t } = useTranslation();
  const { setPageSettings, loading } = useFilterContext();
  const {
    iframe: {
      displaySettings: { toggleViewButton, defaultView, openEventInIframe },
    },
  } = useIframeContext();
  const [isMapOpen, setMapOpen] = useState(defaultView === "map");

  const toggleMapView = useCallback(() => {
    if (isMapOpen) {
      setPageSettings(0, "limit");
    } else {
      setPageSettings(1000, "limit");
    }
    setMapOpen(!isMapOpen);
  }, [isMapOpen, setPageSettings]);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""}
      libraries={GOOGLE_LIBRARIES}
    >
      {toggleViewButton ? (
        <StyledCenterButton>
          <SimpleButton
            onClick={toggleMapView}
            disabled={loading}
            variant={BUTTON.SECONDARY}
          >
            {t(`Map.${isMapOpen ? "ListView" : "MapView"}`)}
          </SimpleButton>
        </StyledCenterButton>
      ) : (
        <StyledMapPlaceholder />
      )}
      {isMapOpen ? (
        <IFrameMapGrid />
      ) : (
        <IFrameCardGrid external={!openEventInIframe} />
      )}
    </LoadScript>
  );
};

export default IFrameViewSelector;
