import { Modal, Popup } from "semantic-ui-react";
import styled from "styled-components";

import GeolocationMap from "components/molecules/Maps/GeolocationMap";
import MediaButton from "components/atoms/Buttons/MediaButton";

import EventDetailList from "../../../molecules/Lists/EventDetailList";
import FixedRatioImage from "../../../molecules/Images/FixedRatioImage/FixedRatioImage";

const modalBorderRadius = "15px";

export const StyledModal = styled(Modal)({
  "&&&": {
    border: "1px transparent",
    borderRadius: modalBorderRadius,
  },
});

export const StyledModalHeader = styled(Modal.Header)({
  "&&&&": {
    borderTopLeftRadius: modalBorderRadius,
    borderTopRightRadius: modalBorderRadius,
    borderBottom: "none",
    padding: "0 !important",
    display: "flex",
    overflow: "hidden",
    justifyContent: "space-between",
  },
});

export const StyledModalContent = styled(Modal.Content)({
  "&&&": {
    borderBottomLeftRadius: modalBorderRadius,
    borderBottomRightRadius: modalBorderRadius,
    padding: "0 !important",
    overflow: "hidden",
  },
});

export const StyledContentWrapper = styled.div({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px 20px 25px",
});

export const StyledBackgroundWrapper = styled.div({
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
});

export const StyledFixedRatioBackgroundImage = styled(FixedRatioImage)({
  filter: "blur(8px)",
  transform: "scale(1.05)",
});

export const StyledImageWrapper = styled.div({
  width: "100%",
  maxWidth: "730px",
  // padding: "0 16px",
});

export const StyledFixedRatioImage = styled(FixedRatioImage)({
  backgroundColor: "none",
  overflow: "hidden",
  borderRadius: "15px",
  "> .image": {
    borderRadius: "15px",
  },
});

export const StyledEventDetailList = styled(EventDetailList)({
  "&&&": {
    marginTop: "-5px",
    marginBottom: "5px",
  },
});

export const StyledTagList = styled.div({
  "&&&": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 0,
  },
});

export const StyledTagWrapper = styled.div({
  margin: "14px 2px 8px",
});

export const StyledTextWrapper = styled.div({
  margin: "13px 0 25px",
  maxWidth: "730px",
  width: "100%",
});

export const StyledGeolocationMap = styled(GeolocationMap)({
  "&&&": {
    borderBottomLeftRadius: modalBorderRadius,
    borderBottomRightRadius: modalBorderRadius,
  },
});

export const StyledMediaPopup = styled(Popup)({
  "&&&": {
    padding: "10px",
  },
});

export const StyledMediaButton = styled(MediaButton)({
  "&&&": {
    margin: "0px 5px",
  },
});
