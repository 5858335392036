import { RadioProps, StrictDropdownItemProps } from "semantic-ui-react";
import { Moment } from "moment";
import { PropsWithChildren, FocusEvent } from "react";

export interface IWizardField {
  id: string;
  label: string;
  type: FIELD;
  dbField?: boolean;
  disabled?: boolean;
  options?: Array<StrictDropdownItemProps | RadioProps>;
  simple?: boolean;
  value?: any;
  search?: boolean;
}

export enum FIELD {
  INPUT = "input",
  CHECKBOX = "checkBox",
  TEXTAREA = "textArea",
  SELECT = "select",
  MULTISELECT = "multiSelect",
  DATES = "dates",
  COLOR = "color",
  IMAGE = "image",
  IMAGE_DESCRIPTION = "imageDescription",
  JSON = "json",
  LOCATION = "geolocation",
  INTEGER = "integer",
  DATE_TIME_RANGE = "dateTimeRange",
}

export interface IFormGroup {
  id: string;
  label: string;
  fields: IWizardField[];
}

export interface IndexSignature {
  [key: string]: any;
}

export type TFormFieldValues = boolean | string | string[] | File | Moment;

export type TFormOnChange<T = TFormFieldValues> = (data: T, id: string) => void;

export interface IBaseDynamicFormFieldProps<T = TFormFieldValues>
  extends PropsWithChildren<any> {
  onChange?: TFormOnChange<T>;
  onBlur?: (event: FocusEvent<HTMLInputElement>, id: string) => void;
  error?: string;
  value: T;
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  options?: Array<StrictDropdownItemProps & RadioProps>;
  className?: string;
  inputType?: IInputType;
}
export declare type IInputType =
  | "email"
  | "number"
  | "text"
  | "password"
  | "tel"
  | "url";
