import React from "react";
import { Button } from "semantic-ui-react";

import styled from "styles/styled";

const TILDE_TRANSLATE_ICON =
  "https://www.tilde.lv/sites/all/themes/tilde/logo.png";
interface Props {
  onClick: () => void;
  disabled?: boolean;
  translated?: boolean;
}
const Styled = styled(Button)(({ theme }) => ({
  "&&&": {
    display: "flex",
    alignItems: "center",
    // ...(theme?.typography?.textButton || {}),
    textTransform: "initial",
    margin: "0",
    padding: "0",
    borderRadius: "0",
    border: "none",
    color: theme.palette.primary.dark,
    backgroundColor: "transparent",
    minHeight: "36px",
    "&:hover": {
      color: "#7F7F7F",
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
    ".googleIcon": {
      marginRight: "0.3em",
      display: "block",
      height: "20px",
      width: "20px",
      backgroundImage: `url(${TILDE_TRANSLATE_ICON})`,
      backgroundSize: "contain",
    },
  },
}));
const TranslateButton: React.FC<Props> = ({ children, onClick, disabled }) => {
  return (
    <Styled onClick={onClick} disabled={disabled}>
      <div className="googleIcon" />
      {children}
    </Styled>
  );
};

export default TranslateButton;
