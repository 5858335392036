export enum LOCALE {
  LATVIAN = "LV",
  ENGLISH = "EN",
  RUSSIAN = "RU",
  UKRAINIAN = "UK",
  GERMAN = "DE",
}

export const isDefinedLocale = (x: string | LOCALE): x is LOCALE => {
  return Object.values(LOCALE).some((value) => x === value);
};

export const LOCALE_OPTS = [
  {
    label: "latviešu",
    flag: "lv",
    value: LOCALE.LATVIAN,
    key: "lv",
  },
  {
    label: "русский",
    flag: "ru",
    value: LOCALE.RUSSIAN,
    key: "ru",
  },
  {
    label: "українська",
    flag: "ua",
    value: LOCALE.UKRAINIAN,
    key: "ua",
  },
  {
    label: "english",
    flag: "gb",
    value: LOCALE.ENGLISH,
    key: "gb",
  },
  {
    label: "deutsch",
    flag: "de",
    value: LOCALE.GERMAN,
    key: "de",
  },
];
