import React, { memo, useMemo } from "react";
import Flatpickr from "react-flatpickr";
import { BaseOptions } from "flatpickr/dist/types/options";
import { useTranslation } from "react-i18next";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import { key } from "flatpickr/dist/types/locale";

interface InputlessFlatpickrProps {
  minDate?: string;
  maxDate?: string;
  onClose?: (dates: Date[]) => void;
  onChange: (value: Date[]) => void;
  value: Date[];
}

const InputlessFlatpickr: React.FC<InputlessFlatpickrProps> = ({
  value,
  minDate,
  maxDate,
  onChange,
  onClose,
}) => {
  const { i18n } = useTranslation();
  const language = i18n?.language.toLowerCase() as key;
  const options: Partial<BaseOptions> = useMemo(
    () => ({
      minDate,
      maxDate,
      inline: true,
      mode: "range",
      closeOnSelect: true,
      locale: FlatpickrLanguages[language],
      onClose(dates, _, picker) {
        picker.setDate(picker.input.value || "", true);
        if (onClose) onClose(dates);

        onChange(dates);
      },
    }),
    [minDate, maxDate, onClose, onChange, language],
  );

  return (
    <Flatpickr
      value={value}
      onChange={onChange}
      options={options}
      render={(_, ref) => {
        return <input style={{ display: "none" }} ref={ref} />;
      }}
    />
  );
};

export default memo(InputlessFlatpickr);
