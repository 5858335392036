import { FileRejection, useDropzone } from "react-dropzone";
import { Dimmer, Icon, Image } from "semantic-ui-react";
import React, { FC } from "react";

import DropzoneContainer from "./DropzoneContainer";

import Typography from "../Typography/Typography";
import SimpleButton, { BUTTON } from "../Buttons/SimpleButton/SimpleButton";

export interface ImageDropzoneProps {
  isLoading: boolean;
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  exampleImgUrl?: string;
  disabled?: boolean;
  max?: number;
  accept?: string[];
  id?: string;
}

const ImageDropzone: FC<ImageDropzoneProps> = ({
  isLoading,
  onDrop,
  exampleImgUrl,
  disabled,
  max,
  accept,
  id,
}) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    disabled,
    maxSize: max,
    accept: { "image/png": [".png"], "image/jpeg": [".jpg"] },
  });

  return (
    <DropzoneContainer isLoading={isLoading}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Image src={exampleImgUrl} centered />
        {!exampleImgUrl && (
          <>
            <Icon name="cloud upload" size="massive" />
            <Typography as="p" align="center">
              Ievelc bildi šeit
            </Typography>
            <Typography as="p" align="center">
              vai
            </Typography>
          </>
        )}
        <p />
        <SimpleButton
          variant={BUTTON.PRIMARY}
          onClick={open}
          id={`${id}-filesBtn`}
        >
          Augšupielādē
        </SimpleButton>
        <Dimmer active={isDragActive}>
          <Icon name="arrow down" size="huge" />
          <Typography>Atlaid bildi te</Typography>
        </Dimmer>
      </div>
    </DropzoneContainer>
  );
};
ImageDropzone.defaultProps = {
  disabled: false,
};
export default ImageDropzone;
