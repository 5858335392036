import { useDebouncedCallback } from "use-debounce/lib";
import { useCallback, useState, SetStateAction, Dispatch } from "react";

import useIframeContext from "hooks/useIFrameContext";
import useDidUpdateEffect from "hooks/useDidUpdateEffect";

import { IFilters } from "../api/interfaces/IFilters";

interface IUIFilters extends IFilters {
  debounce: boolean;
}
// make into something generic
const useDebouncedFilters = (
  setFilters: Dispatch<SetStateAction<IFilters>>,
  setLoading: (value: boolean) => void,
) => {
  const {
    iframe: {
      initialDataQuery: { limit },
      presetValues: { city, tags, date },
    },
  } = useIframeContext();

  const [uiFilters, setUIFilters] = useState<IUIFilters>({
    city: city || [],
    tags: tags || [],
    date: date?.map((d) => new Date(d)) || [], // Today, indefinite :/
    name: "",
    limit: limit || 12,
    offset: 0,
    debounce: false,
  });

  const [debounceSetFilter] = useDebouncedCallback((value: IFilters) => {
    setFilters(value);
  }, 500);

  useDidUpdateEffect(() => {
    if (uiFilters.debounce) {
      debounceSetFilter(uiFilters);
    } else {
      setFilters(uiFilters);
    }
  }, [setFilters, debounceSetFilter, uiFilters]);

  const setDebouncedFilterById = useCallback(
    <T extends keyof IFilters>(value: IFilters[T], id: T) => {
      if (id === "offset" || id === "limit") {
        throw new Error("use setFilterById function instead!");
      } else {
        setLoading(true);
        setUIFilters((prev) => ({
          ...prev,
          debounce: true,
          offset: 0,
          [id]: value,
        }));
      }
    },
    [setLoading],
  );

  const setFilterById = useCallback(
    (value: number, id: "offset" | "limit") => {
      setLoading(true);
      if (id === "offset") {
        setUIFilters((prev) => ({ ...prev, debounce: false, offset: value }));
      } else {
        const newLimit = !value ? Number(limit) : value;
        setUIFilters((prev) => ({
          ...prev,
          debounce: false,
          offset: 0,
          limit: newLimit,
        }));
      }
    },
    [setLoading, limit],
  );

  return { uiFilters, setDebouncedFilterById, setFilterById };
};

export default useDebouncedFilters;
