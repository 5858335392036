import React from "react";

import IframeContextProvider from "context/IframeContext";
import IframeFilters from "components/organisms/IFrameFilters/IframeFilters";
import FilterContextProvider from "context/FilterContext";
import IFrameViewSelector from "components/organisms/IFrameViewSelector/IFrameViewSelector";
import IFrameHeader from "components/organisms/IFrameHeader/IFrameHeader";
import SetLanguage from "components/organisms/SetLanguage";

const IframePage: React.FC = () => {
  return (
    <IframeContextProvider>
      <FilterContextProvider>
        <SetLanguage />
        <IFrameHeader />
        <IframeFilters />
        <IFrameViewSelector />
      </FilterContextProvider>
    </IframeContextProvider>
  );
};

export default IframePage;
