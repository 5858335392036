import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ENG from "./en.json";
import LV from "./lv.json";
import RU from "./ru.json";
import UK from "./uk.json";
import { LOCALE } from "./locale";

const resources = {
  LV: {
    common: LV,
  },
  EN: {
    common: ENG,
  },
  RU: {
    common: RU,
  },
  UK: {
    common: UK,
  },
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    defaultNS: "common",
    lng: LOCALE.LATVIAN,
    fallbackLng: LOCALE.ENGLISH,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
