import React, { ChangeEvent, FocusEvent, memo } from "react";
import { FormField } from "semantic-ui-react";

import { IBaseDynamicFormFieldProps } from "interfaces/forms";

export interface FormIntegerInputProps
  extends IBaseDynamicFormFieldProps<string> {
  min?: number;
  max?: number;
}

const FormIntegerInput: React.FC<FormIntegerInputProps> = ({
  onChange,
  onBlur,
  id,
  label,
  required,
  ...props
}) => (
  <FormField required={required}>
    {label && <label htmlFor={id}>{label}</label>}
    <input
      {...props}
      id={id}
      type="number"
      step={1}
      required={required}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(event.target.value, id);
        }
      }}
      onBlur={(event: FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur(event, id);
        }
      }}
    />
  </FormField>
);

export default memo(FormIntegerInput);
