/* eslint-disable no-param-reassign */
export interface IDropzoneState {
  error: string;
  isLoading: boolean;
  imgUrl: string | undefined;
}
export enum actionTypes {
  submit = "submit",
  success = "success",
  error = "error",
  reset = "reset",
}
export function dropzoneReducer(
  draft: IDropzoneState,
  action: { type: actionTypes; imgUrl?: string },
) {
  switch (action.type) {
    case "reset": {
      draft.error = "";
      draft.isLoading = false;
      draft.imgUrl = undefined;
      return;
    }
    case "submit": {
      draft.error = "";
      draft.isLoading = true;
      return;
    }
    case "success": {
      draft.isLoading = false;
      draft.imgUrl = action.imgUrl;
      return;
    }
    case "error": {
      draft.error = "Form submission failed!!";
      draft.isLoading = false;
      return;
    }
    default: {
      draft.error = "Unrecognised action type used!!";
      draft.isLoading = false;
    }
  }
}
