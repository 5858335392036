import React, { memo, SyntheticEvent } from "react";
import { DropdownProps, Dropdown, FormField } from "semantic-ui-react";

import { IBaseDynamicFormFieldProps } from "interfaces/forms";

export interface MultiFormSelectProps
  extends IBaseDynamicFormFieldProps<string[]> {
  search?: boolean;
}

const FormMultiSelect: React.FC<MultiFormSelectProps> = ({
  id,
  label,
  required,
  onChange,
  options,
  onBlur,
  error,
  ...props
}) => (
  <FormField required={required} error={Boolean(error)}>
    {label && <label htmlFor={id}>{label}</label>}
    <Dropdown
      {...props}
      id={id}
      name={id}
      options={options || []}
      selection
      required={required}
      onChange={(_: SyntheticEvent, data: DropdownProps) => {
        const newVal = (data.value as string[]) || "";
        if (onChange) onChange(newVal, id);
      }}
      onBlur={(event: any, data: any) => {
        if (onBlur) {
          onBlur(event as any, id);
        }
      }}
      clearable
      multiple
    />
  </FormField>
);

export default memo(FormMultiSelect);
