import { StrictDropdownItemProps } from "semantic-ui-react";

import { WIZARD } from "components/organisms/Forms/Wizard/WizardFields";
import { IDropDownOption, WIZARD_OPTION } from "interfaces/fields";
import { IWizardField } from "interfaces/forms";
import { GetFilterValues, FILTER_OPT } from "api/query";
import ILocalizedDropdownOpts from "interfaces/ILocalizedDropdownOpts";

import { IClause } from "../api/interfaces/IQuery";

export interface IWizardStep {
  id: number;
  label: string;
  fields: IWizardField[];
  findValues?: boolean;
}

export const wizardStepMaker = (
  id: number,
  label: string,
  fields: IWizardField[],
  findValues?: boolean,
) => {
  const newStep: IWizardStep = { id, label, fields, findValues };
  return newStep;
};

export const GetWizardType = (step: IWizardStep): WIZARD_OPTION => {
  return step.fields.find((f) => f.id === WIZARD.TYPE)?.value;
};

export const PopulateNextFields = async (
  allSteps: IWizardStep[],
  nextStepFields: IWizardField[],
) => {
  const allStepFields = [...nextStepFields];
  const populatedFields = [...nextStepFields];
  allSteps.forEach((step) => {
    allStepFields.push(...step.fields);
  });
  let population: Record<string, StrictDropdownItemProps[]> = {};
  try {
    if (allSteps[0].fields[0].value === WIZARD_OPTION.ORGANISATION) {
      population = await GetFilterValues([
        FILTER_OPT.PLACE,
        FILTER_OPT.ORGANISERS,
      ]);
    } else {
      population = await GetFilterValues([
        FILTER_OPT.TAGS,
        FILTER_OPT.CATEGORY,
        FILTER_OPT.CITY,
      ]);
    }
  } catch (err) {
    console.error("Please handle me");
  }
  populatedFields.forEach((f) => {
    f.options = population[f.id] || [];
  });
  return populatedFields;
};
export const PopulateThirdStep = async (
  allSteps: IWizardStep[],
  nextStepFields: IWizardField[],
) => {
  const allStepFields = [...nextStepFields];
  const populatedFields = [...nextStepFields];
  allSteps.forEach((step) => {
    allStepFields.push(...step.fields);
  });
  const population: Record<string, IDropDownOption[]> = {};
  try {
    const opts = await GetFilterValues([FILTER_OPT.TAGS, FILTER_OPT.CITY]);
    const presetTags = allStepFields
      .find((field) => field.id === WIZARD.TAGS)
      ?.value?.map((tag: string) =>
        opts.tags.find((t: any) => t.value === tag),
      );
    const presetCities = allStepFields
      .find((field) => field.id === WIZARD.CITY)
      ?.value?.map((c: string) => opts.city.find((t: any) => t.value === c));
    population.highlightedTags = presetTags || opts.tags;
    population.presetFilterTags = presetTags || opts.tags;
    population.presetFilterCity = presetCities || opts.city;
  } catch (err) {
    console.error("Please handle me", err);
  }

  populatedFields.forEach((f) => {
    f.options = population[f.id] || [];
  });
  return populatedFields;
};

export const GetStepValue = (
  steps: IWizardStep[],
  currentStep: number,
  id: string,
  value: any,
) => {
  const newStep = { ...steps[currentStep] };
  const newField = newStep.fields.find((f) => f.id === id);
  if (newField) {
    const val = value;
    newField.value = val;
    const newSteps = [...steps];
    newSteps[currentStep] = newStep;
    return newSteps;
  }
  return null!;
};

/**
 * Gets value from overly complicated clause.
 * Usually only single value resides in clause (i.e. only "or" for tags)
 * This helper helps navigate it.
 */
export const getClauseVal = (
  clause?: IClause,
  key?: keyof IClause,
): string[] | undefined => {
  if (!clause) return undefined;
  const value = clause[key || "or"];
  if (value === undefined) return undefined;
  const values = value[0];
  if (!values) return undefined;
  if (typeof values === "string") return value as string[];

  throw new Error("Clause nesting is not supported");
};

/**
 * Maps value string to select options (i.e. tag array)
 * if value has no corresponding option given, its excluded
 * if array is empty, returns all options
 */
export const mapValuesToOpts = (
  values: string[] | undefined,
  opts: ILocalizedDropdownOpts[],
): ILocalizedDropdownOpts[] => {
  const mappedValues = values
    ?.map((value) => opts.find((opt) => opt.value === value))
    .filter((item) => item) as ILocalizedDropdownOpts[];

  return mappedValues || opts || [];
};
