import { Dropdown, Icon } from "semantic-ui-react";

import styled from "styles/styled";

export const StyledTitle = styled.div(({ theme }) => ({
  ...theme.typography.button1,
  color: theme.palette.primary.main,
  margin: "auto",
  paddingTop: "3px",
  paddingBottom: "3px",
  paddingRight: "10px",
  paddingLeft: "10px",
  marginTop: "5.5px",
  marginBottom: "5.5px",
}));
export const StyledChevron = styled(Icon)(({ open }) => ({
  "@keyframes spin-forward": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(180deg)",
    },
  },
  "@keyframes spin-backword": {
    from: {
      transform: "rotate(180deg)",
    },
    to: {
      transform: "rotate(0deg)",
    },
  },
  "&&&": {
    margin: 0,
    marginLeft: "4px",
    animation: open
      ? "spin-forward 0.5s ease forwards"
      : "spin-backword 0.5s ease forwards",
  },
}));
export const StyledTrigger = styled.div<{ empty?: boolean }>`
  background-color: ${({ theme, empty }) =>
    empty ? theme.palette.primary.main : theme.palette.mono.white};
  max-width: fit-content;
  margin: auto;
  border: solid 2px;
  border-radius: 20px;
  min-height: 34px;
  border-color: ${({ theme, empty }) =>
    empty ? theme.palette.mono.white : theme.palette.mono.white};
  &:hover {
    background-color: ${({ theme, empty }) =>
      empty ? theme.palette.primary.dark : theme.palette.primary.light};
  }
  ${StyledTitle} {
    color: ${({ theme, empty }) =>
      empty ? theme.palette.mono.white : theme.palette.primary.main};
  }
`;

export const StyledDropdownContent = styled(Dropdown.Menu)`
  &:hover {
    ${StyledTrigger} {
      background-color: green !important;
    }
  }
`;
// display: box trick does not work too well in JSS, don't migrate this bit
export const StyledDropdownWrapper = styled.div`
  .dropdown {
    padding: 0.06em 0.9em !important;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;

    & > a {
      order: 2;
    }
    & > div {
      order: 1;
      white-space: nowrap;
    }
    & > i {
      order: 3;
    }
  }
`;
export const StyledDropdown = styled(Dropdown)(
  ({ topPosition, theme, screenWidth }) => ({
    "&&&": {
      "&> .menu.transition.visible": {
        marginTop: "-15px!important",
        paddingTop: "15px",
        zIndex: -1,
        width: `${screenWidth + 4}px`,
        left: `-${topPosition + 4}px`,
        position: "absolute",

        "@media only screen and (min-width: 800px)": {
          // transform: `translate3d(-${topPosition}px, 0, 0) !important`,
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          left: "50%",
          transform: "translate(-50%, 0)",
          // minWidth: "400px",
        },
      },
      "& > a": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.mono.white,
        border: `2px solid ${theme.palette.mono.white}`,
        textTransform: "uppercase",
        borderRadius: "20px",
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
      "&>.dropdown.icon": {
        display: "none",
      },
      "&>.dropdown.icon.clear": {
        marginTop: "8px",
        display: "block",
        marginLeft: 0,
      },
      "&>.item": {
        padding: "5px 1.14285714rem!important",
      },
    },
  }),
);
