import slugify from "slugify";

import { IBaseFormFieldProps, IDropDownOption } from "interfaces/fields";
import { FIELD, IWizardField } from "interfaces/forms";
import { isFile, uploadImageFromFile } from "api/s3";

import {
  defaultOrgId,
  IEventForm,
} from "../components/organisms/Forms/EventForm";

export const a = "a";

export const DbFieldMaker = (
  type: FIELD,
  label: string,
  column: string,
  options?: IDropDownOption[],
) => {
  const field: IWizardField = {
    id: column,
    dbField: true,
    label,
    type,
    options,
    disabled: false,
    search: true,
  };
  return field;
};

export const SimpleFieldMaker = (
  type: FIELD,
  label: string,
  props: Partial<IBaseFormFieldProps>,
) => {
  const { id, ...rest } = props;
  const field: IWizardField = {
    id: id || slugify(label, { lower: true }),
    dbField: false,
    label,
    type,
    ...rest,
  };
  return field;
};
const SELECT_FIELDS = ["organiser", "category"];
const MULTISELECT_FIELDS = ["tags"];

export const formToGraphql = async (form: IEventForm) => {
  const returnable: any = {};

  try {
    // eslint-disable-next-line no-restricted-syntax
    for (const k of Object.keys(form)) {
      const key = k as keyof IEventForm;
      if (form[key] === "" || form[key] === null) {
        // ignore empty
        if (key === "organiser") {
          returnable.organiser = { id: defaultOrgId };
        }
      } else if (key === "imageDescription") {
        // eslint-disable-next-line no-await-in-loop
        const imageLink = await uploadImageFromFile(form[key] as File);
        if (returnable.description && returnable.description.length > 0) {
          returnable.description = returnable.description.concat(
            `<img src="${imageLink}" />`,
          );
        } else {
          returnable.description = `<img src="${imageLink}" />`;
        }
      } else if (key === "description") {
        if (returnable.description && returnable.description !== "") {
          returnable.description = returnable.description.concat(form[key]);
        } else {
          returnable[key] = form[key];
        }
      } else if (key === "online") {
        if (form[key]) {
          returnable.placeName = "Tiešsaistē";
        }
        returnable[key] = form[key];
      } else if (key === "image") {
        returnable[key] = isFile(form[key])
          ? // eslint-disable-next-line no-await-in-loop
            await uploadImageFromFile(form[key] as File)
          : form[key];
        returnable.imageThumbnail = isFile(form[key])
          ? // eslint-disable-next-line no-await-in-loop
            await uploadImageFromFile(form[key] as File)
          : form[key];
      } else if (key === "location") {
        // LOCATION PARSER
        returnable.latitude = form[key].lat;
        returnable.longitude = form[key].lng;
        returnable.city = form[key].city || undefined;
        returnable.country = form[key].country || undefined;
      } else if (SELECT_FIELDS.includes(key)) {
        returnable[key] = { id: form[key] };
      } else if (key === "startTime" || key === "endTime") {
        const formattedTime = new Date(form[key]);
        returnable[key] = formattedTime.toISOString();
      } else if (MULTISELECT_FIELDS.includes(key)) {
        returnable[key] = (form[key] as IEventForm["tags"]).map((val) => ({
          id: val.id,
          tag: val.tag,
        }));
      } else {
        returnable[key] = form[key];
      }
    }
  } catch (err) {
    console.error("form error", err);
  }
  return returnable;
};
