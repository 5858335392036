import React from "react";
import { Button, ButtonGroup, Icon } from "semantic-ui-react";
import { darken } from "polished";

import useFilterContext from "hooks/useFilterContext";
import styled from "styles/styled";

export interface IframeCardGridProps {}

const StyledButton = styled(Button)(({ theme }) => ({
  "&&&": {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    padding: ".57em .7em .57em",
    fontSize: "1.2rem",
    border: ".5px solid",
    borderColor: theme.palette.mono.white,
    ":first-child": {
      borderTopLeftRadius: "20px",
      borderBottomLeftRadius: "20px",
    },
    ":last-child": {
      borderTopRightRadius: "20px",
      borderBottomRightRadius: "20px",
    },
    ":hover": {
      backgroundColor: darken(0.05, theme.palette.mono.base),
    },
  },
}));

const IFramePaginator: React.FC<IframeCardGridProps> = () => {
  const {
    filters,
    setPageSettings,
    error,
    loading,
    count,
  } = useFilterContext();
  const offset = Number(filters?.offset);
  const limit = Number(filters?.limit);

  const disabledBack = Boolean(error || loading || offset <= 0);
  const disabledForth = Boolean(error || loading || count <= offset + limit);

  const goToStart = () => {
    setPageSettings(0, "offset");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const goToEnd = () => {
    if (limit === undefined) return;
    let rem = count % limit;
    if (rem === 0) rem = limit;
    setPageSettings(Math.max(count - rem, 0), "offset");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const goBack = () => {
    if (offset === undefined || limit === undefined) return;
    setPageSettings(offset - limit, "offset");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const goForth = () => {
    if (offset === undefined || limit === undefined) return;
    setPageSettings(offset + limit, "offset");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <ButtonGroup>
      <StyledButton icon disabled={disabledBack} onClick={goToStart}>
        <Icon name="angle double left" />
      </StyledButton>
      <StyledButton icon disabled={disabledBack} onClick={goBack}>
        <Icon name="angle left" />
      </StyledButton>
      <StyledButton icon disabled={disabledForth} onClick={goForth}>
        <Icon name="angle right" />
      </StyledButton>
      <StyledButton icon disabled={disabledForth} onClick={goToEnd}>
        <Icon name="angle double right" />
      </StyledButton>
      )
    </ButtonGroup>
  );
};

export default IFramePaginator;
