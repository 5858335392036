import React from "react";
import { Button, Icon, IconProps, ButtonProps } from "semantic-ui-react";

// TODO: Groom @Agnese
export interface MediaButtonProps extends ButtonProps {
  media: MEDIA;
  url: string;
  icon?: boolean;
}

export enum MEDIA {
  FACEBOOK = "facebook",
  TWITTER = "twitter",
  GOOGLE = "google",
  INSTAGRAM = "instagram",
  YOUTUBE = "youtube",
  LINKEDIN = "linkedin",
  WEBSITE = "website",
}

function formatMediaName(media: MEDIA) {
  switch (media) {
    case MEDIA.FACEBOOK:
      return "Facebook";
    case MEDIA.GOOGLE:
      return "Google Plus";
    case MEDIA.INSTAGRAM:
      return "Instagram";
    case MEDIA.LINKEDIN:
      return "LinkedIn";
    case MEDIA.TWITTER:
      return "Twitter";
    case MEDIA.YOUTUBE:
      return "YouTube";
    default:
      return "Website";
  }
}

const MediaButton: React.FC<MediaButtonProps> = ({
  media,
  icon,
  url,
  ...props
}) => {
  let iconName: IconProps["name"] = media as IconProps["name"];
  let buttonColor: ButtonProps["name"] = media;
  if (media === MEDIA.GOOGLE) {
    iconName = "google plus";
    buttonColor = "google plus";
  }
  if (media === MEDIA.WEBSITE) {
    iconName = "world";
    buttonColor = undefined;
  }

  if (icon) {
    return (
      <Button
        color={buttonColor}
        id={media}
        icon={iconName}
        href={url}
        circular
        {...props}
      />
    );
  }

  return (
    <Button color={buttonColor} id={media} href={url} {...props}>
      <Icon name={iconName} /> {formatMediaName(media)}
    </Button>
  );
};

export default MediaButton;
