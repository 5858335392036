import React, { ChangeEvent, memo, FocusEvent } from "react";
import { FormField, Label } from "semantic-ui-react";

import { IBaseDynamicFormFieldProps } from "interfaces/forms";

export interface FormTextInputProps extends IBaseDynamicFormFieldProps<string> {
  inputType?: "email" | "number" | "text" | "password" | "tel" | "url";
  // onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

const FormTextInput: React.FC<FormTextInputProps> = ({
  onChange,
  onBlur,
  id,
  label,
  inputType,
  required,
  error,
  ...props
}) => {
  const changeFn = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event.target.value, id);
  };

  return (
    <FormField required={required} error={Boolean(error)}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        {...props}
        id={id}
        type={inputType}
        required={required}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          changeFn(event);
        }}
        onBlur={(event: FocusEvent<HTMLInputElement>) => {
          if (onBlur) {
            onBlur(event, id);
          }
        }}
      />
      {error && <Label prompt>{error}</Label>}
    </FormField>
  );
};

export default memo(FormTextInput);
