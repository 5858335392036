import React from "react";
import { Menu } from "semantic-ui-react";

import { KURPES_LOGO, WHITE_KURPES_LOGO } from "constants/images";
import useIframeContext from "hooks/useIFrameContext";
import { KURPES_URL } from "constants/site";

import {
  StyledCustomLogo,
  StyledIFrameMenu,
  StyledKurpesLogo,
} from "./IFrameHeader.styles";

import LanguagePicker from "../LanguagePicker/LanguagePicker";

interface Props {}

const IFrameHeader: React.FC<Props> = () => {
  const {
    iframe: {
      logoUrl,
      languageChoice,
      customStyles: { headerHeight, whiteLogo },
    },
  } = useIframeContext();

  return (
    <StyledIFrameMenu attached="top" secondary>
      {logoUrl && (
        <Menu.Item position="left">
          <StyledCustomLogo src={logoUrl} height={`${headerHeight || 25}px`} />
        </Menu.Item>
      )}
      <Menu.Item position="right">
        {languageChoice?.length > 1 && <LanguagePicker />}
      </Menu.Item>
      <Menu.Item href={KURPES_URL} target="_blank">
        <StyledKurpesLogo
          src={whiteLogo ? WHITE_KURPES_LOGO : KURPES_LOGO}
          width="75.52px"
        />
      </Menu.Item>
    </StyledIFrameMenu>
  );
};

export default IFrameHeader;
