/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApiSettingsMaker, API } from "./apiSettings";

export interface IApiSettings {
  method: string;
  headers?: any;
  body?: any;
}

export interface IApi {
  url: string;
  settings: IApiSettings;
}

export const AWSurlDEV =
  "https://qams8k98ul.execute-api.eu-central-1.amazonaws.com/dev/api";

export enum API_ROUTE {
  FILTER = "filter",
  COUNT = "count",
  ACTIVE_FILTER_VALUES = "valuechecker",
  FILTER_VALUES = "filtervalues",
  EVENT = "event",
}

// core API logic
export async function handleApi(url: string, settings: IApiSettings) {
  try {
    const fetchRes = await fetch(url, settings);
    // Checking fetch status
    if (fetchRes.status !== 200)
      throw new Error(
        `Fetch did not return OK status : ${JSON.stringify(fetchRes)}`,
      );
    const lambdaRes = await fetchRes.json();

    // Checking lambda status
    if (lambdaRes.status && lambdaRes.status === 200) {
      return JSON.parse(lambdaRes.body);
    }
    if (lambdaRes.status) {
      throw new Error(
        `Lambda did not return OK status : ${JSON.stringify(lambdaRes)}`,
      );
    }
    if (lambdaRes.errorType || lambdaRes.errorMessage) {
      throw new Error(`Lambda error. Reason: ${lambdaRes.errorMessage}`);
    }
    return lambdaRes;
  } catch (err: any) {
    throw new Error(err);
  }
}

export function handleApiGet(url: string, token?: string) {
  return handleApi(url, ApiSettingsMaker(API.GET, undefined, token));
}

export function handleApiPost(
  url: string,
  body: Record<string, any>,
  token?: string,
) {
  return handleApi(url, ApiSettingsMaker(API.POST, body, token));
}

export function handleApiDelete(
  url: string,
  body: Record<string, any>,
  token?: string,
) {
  return handleApi(url, ApiSettingsMaker(API.DELETE, body, token));
}

export function handleApiPut(
  url: string,
  body: Record<string, any>,
  token?: string,
) {
  return handleApi(url, ApiSettingsMaker(API.PUT, body, token));
}

export async function graphqlFetch(
  query: string,
  variables?: any,
): Promise<any> {
  const response = await fetch(
    `${process.env.REACT_APP_KURPES_BACKEND_URL}/graphql`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        query,
        ...(variables && {
          variables,
        }),
      }),
    },
  );
  const json = await response.json();
  return json;
}
