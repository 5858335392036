import React, { useCallback, useState } from "react";
import { Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import Typography from "components/atoms/Typography/Typography";
import { TYPOGRAPHY } from "components/atoms/Typography/Typography.styles";
import SimpleButton, {
  BUTTON,
} from "components/atoms/Buttons/SimpleButton/SimpleButton";
import IEventCard from "api/interfaces/IEventCard";
import useAPI from "hooks/useApi";
import IEvent from "api/interfaces/IEvent";
import {
  formatDisplayDate,
  kurpesAddress,
  tagByLanguage,
} from "helpers/helpers";
import { MEDIA } from "components/atoms/Buttons/MediaButton";
import EventViewLoader from "components/atoms/Loaders/EventViewLoader";
import useFilterContext from "hooks/useFilterContext";
import useGlobalContext from "hooks/useGlobalContext";
import { TildeTranslateText } from "api/tildeApi";
import BubbleLoader from "components/atoms/Loaders/BubbleLoader";
import TranslatableDescription from "components/molecules/TranslatableDescription/TranslatableDescription";
import { desktopUserAgent } from "helpers/userAgent";
import CopyToClipboard from "components/molecules/CopyToClipboard/CopyToClipboard";
import { GetEventData } from "api/query";

import {
  StyledTagList,
  StyledTagWrapper,
  StyledTextWrapper,
  StyledModal,
  StyledModalHeader,
  StyledModalContent,
  StyledContentWrapper,
  StyledBackgroundWrapper,
  StyledFixedRatioBackgroundImage,
  StyledImageWrapper,
  StyledFixedRatioImage,
  StyledEventDetailList,
  StyledGeolocationMap,
  StyledMediaButton,
  StyledMediaPopup,
} from "./EventViewModal.styles";

import { ASPECT } from "../../../molecules/Images/FixedRatioImage/FixedRatioImage";

export interface EventViewModalProps {
  titleTranslated?: string;
  cardData: IEventCard;
  open: boolean;
  onClose: () => void;
}

interface ISocial {
  media: MEDIA;
  url: string;
}

const EventViewModal: React.FC<EventViewModalProps> = ({
  titleTranslated,
  cardData,
  open,
  onClose,
}) => {
  let {
    imageThumbnail,
    name,
    slug,
    startTime,
    endTime,
    placeName,
    street,
    city,
  } = cardData;

  const { t } = useTranslation();
  const { setFilter } = useFilterContext();
  const {
    locale: { currentLocale },
  } = useGlobalContext();

  const stableGetEventData = useCallback(() => GetEventData(slug), [slug]);
  const { response: eventData, loading, error } = useAPI(
    stableGetEventData,
    [],
  );
  const tilde = useCallback(async () => {
    const title = eventData?.description;

    const res = await TildeTranslateText(title, currentLocale);

    return res;
  }, [eventData, currentLocale]);

  const { response = "", loading: tildeLoading } = useAPI(tilde, []);

  const [shareMenuOpen, setShareMenuOpen] = useState(false);

  const eventUrl = window.location.href;
  const uriEncodedEventUrl = encodeURIComponent(eventUrl);
  const social: ISocial[] = [
    {
      media: MEDIA.FACEBOOK,
      url: `https://www.facebook.com/sharer/sharer.php?u=${uriEncodedEventUrl}`,
    },
    {
      media: MEDIA.TWITTER,
      url: `https://twitter.com/intent/tweet?text=${uriEncodedEventUrl}`,
    },
    {
      media: MEDIA.LINKEDIN,
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${uriEncodedEventUrl}`,
    },
  ];

  const handleShareMenuOpen = () => {
    if (navigator.share && !desktopUserAgent()) {
      navigator.share({
        title: name,
        url: eventUrl,
      });
    } else {
      setShareMenuOpen(true);
    }
  };

  let extraContent;
  let geolocation: { lat: number; lng: number } | undefined;
  if (loading) {
    extraContent = (
      <StyledTextWrapper>
        <EventViewLoader />
      </StyledTextWrapper>
    );
  } else if (error) {
    extraContent = (
      <Typography type={TYPOGRAPHY.BODY1} margin="10px 0">
        {t("EventView.Errors.LoadingFailed")}
      </Typography>
    );
  } else {
    const {
      tags,
      description,
      website,
      latitude,
      longitude,
    } = eventData as IEvent;
    if (latitude && longitude) {
      geolocation = { lat: latitude, lng: longitude };
    }

    extraContent = (
      <>
        {tags && (
          <StyledTagList>
            {tags.map((tag) => (
              <StyledTagWrapper key={tag.slug}>
                <SimpleButton
                  variant={BUTTON.PRIMARY}
                  onClick={() => {
                    setFilter([tag.slug], "tags");
                    onClose();
                  }}
                >
                  {tagByLanguage(tag, currentLocale)}
                </SimpleButton>
              </StyledTagWrapper>
            ))}
          </StyledTagList>
        )}
        <StyledTextWrapper>
          {tildeLoading ? (
            <>
              <BubbleLoader />
              <Typography align="center" type={TYPOGRAPHY.BODY1}>
                {t("Translate.Loading")}
              </Typography>
            </>
          ) : (
            <TranslatableDescription
              original={description}
              translated={response}
            />
          )}
        </StyledTextWrapper>
        {website && (
          <SimpleButton
            variant={BUTTON.SECONDARY}
            href={website}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="external" />
            {t("EventView.MoreInfo")}
          </SimpleButton>
        )}
      </>
    );
  }

  const startTimeFormatted = formatDisplayDate(currentLocale, [startTime]);
  const endTimeFormatted = formatDisplayDate(currentLocale, [endTime]);

  if (eventData) {
    imageThumbnail = eventData.imageThumbnail;
    name = eventData.name;
    slug = eventData.slug;
    startTime = eventData.startTime;
    endTime = eventData.endTime;
    placeName = eventData.placeName;
    street = eventData.street;
    city = eventData.city;
  }

  return (
    <StyledModal open={open} onClose={onClose}>
      <StyledModalHeader>
        <SimpleButton variant={BUTTON.TEXT} onClick={onClose}>
          <Icon name="angle left" />
          {t("EventView.Back")}
        </SimpleButton>
        <StyledMediaPopup
          trigger={
            <SimpleButton variant={BUTTON.TEXT}>
              {t("EventView.Share")}
            </SimpleButton>
          }
          open={shareMenuOpen}
          onOpen={handleShareMenuOpen}
          onClose={() => setShareMenuOpen(false)}
          position="bottom center"
        >
          {social.map(({ media, url }) => (
            <StyledMediaButton
              key={media}
              media={media}
              url={url}
              icon
              target="_blank"
              rel="noopener noreferrer"
            />
          ))}
          <CopyToClipboard url={eventUrl} />
        </StyledMediaPopup>
      </StyledModalHeader>
      <StyledModalContent>
        <StyledContentWrapper>
          <StyledBackgroundWrapper>
            <StyledFixedRatioBackgroundImage
              src={imageThumbnail}
              aspectRatio={ASPECT.EVENT_VIEW_BACKGROUND}
            />
          </StyledBackgroundWrapper>
          <StyledImageWrapper>
            <StyledFixedRatioImage src={imageThumbnail} />
          </StyledImageWrapper>
          <Typography align="center" type={TYPOGRAPHY.H1}>
            {titleTranslated || name}
          </Typography>
          <StyledEventDetailList
            startTime={startTimeFormatted}
            endTime={endTimeFormatted}
            location={kurpesAddress(placeName, street, city)}
            align="center"
            variant="large"
          />
          {extraContent}
        </StyledContentWrapper>
        {geolocation && <StyledGeolocationMap geolocation={geolocation} />}
      </StyledModalContent>
    </StyledModal>
  );
};

export default EventViewModal;
