import React from "react";
import { CSSProperties } from "styled-components";
import { Segment } from "semantic-ui-react";

import styled from "styles/styled";

export interface FormSegmentProps {
  style?: CSSProperties;
}

const StyledSegment = styled(Segment)({
  "&&&": {
    boxShadow: "none",
    marginTop: 0,
  },
});

const FormSegment: React.FC<FormSegmentProps> = ({ children, style }) => {
  return <StyledSegment style={style}>{children}</StyledSegment>;
};

export default FormSegment;
