import React from "react";
import { List, ListProps } from "semantic-ui-react";
import { CSSObject } from "styled-components";

import styled from "styles/styled";
import Typography from "components/atoms/Typography/Typography";
import { TYPOGRAPHY } from "components/atoms/Typography/Typography.styles";

export interface EventDetailListProps extends ListProps {
  startTime?: string;
  endTime?: string;
  location?: string;
  align?: CSSObject["alignItems"];
  variant?: "small" | "large";
}

const StyledList = styled(List)<{ align: CSSObject["alignItems"] }>(
  ({ align }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: align,
  }),
);

const StyledListItem = styled(List.Item)({
  "&&&": {
    position: "relative",
    margin: 0,
    marginLeft: "0em",
    verticalAlign: "middle",
    display: "flex",
    alignItems: "center",
  },
});

const StyledListIcon = styled(List.Icon)(({ theme }) => ({
  "&&&": {
    textAlign: "center",
    width: "1.5em",
    color: theme.palette.primary.main,
    height: "auto",
  },
}));

const EventDetailList: React.FC<EventDetailListProps> = ({
  startTime,
  endTime,
  location,
  align = "flex-start",
  variant = "small",
  ...props
}) => {
  const typographyType =
    variant === "small" ? TYPOGRAPHY.CAPTION : TYPOGRAPHY.BODY1;
  return (
    <StyledList
      align={align}
      {...props}
      size={variant === "large" ? "big" : undefined}
    >
      {startTime && (
        <StyledListItem>
          <StyledListIcon name="clock outline" />
          <List.Content verticalAlign="middle">
            <Typography align="left" type={typographyType} margin="0">
              {startTime && endTime ? `${startTime} – ${endTime}` : startTime}
            </Typography>
          </List.Content>
        </StyledListItem>
      )}
      {location && (
        <StyledListItem>
          <StyledListIcon name="map marker alternate" margin="0" />
          <List.Content verticalAlign="middle">
            <Typography align="left" type={typographyType}>
              {location}
            </Typography>
          </List.Content>
        </StyledListItem>
      )}
    </StyledList>
  );
};

export default EventDetailList;
