import { keyframes } from "styled-components";

import styled from "styles/styled";

const loading = keyframes`
0%,
80%,
100% {
  box-shadow: 0 2.5em 0 -1.3em;
}
40% {
  box-shadow: 0 2.5em 0 0;
}
`;

const BubbleLoader = styled.div`
  animation: ${loading} 1.8s infinite ease-in-out;
  animation-delay: ${1.8 * -0.16}s;
  animation-fill-mode: both;
  border-radius: 50%;
  color: #7f7f7f;
  font-size: 10px;
  height: 2.5em;
  margin: 60px auto;
  margin-top: 10px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  width: 2.5em;
  &:before {
    animation: ${loading} 1.8s infinite ease-in-out;
    animation-delay: ${1.8 * -0.32}s;
    animation-fill-mode: both;
    border-radius: 50%;
    content: "";
    height: 2.5em;
    left: -3.5em;
    position: absolute;
    top: 0;
    width: 2.5em;
  }
  &:after {
    animation: ${loading} 1.8s infinite ease-in-out;
    animation-fill-mode: both;
    border-radius: 50%;
    content: "";
    height: 2.5em;
    left: 3.5em;
    position: absolute;
    top: 0;
    width: 2.5em;
  }
`;

export default BubbleLoader;
