import React, { useState, FC } from "react";
import { useTranslation } from "react-i18next";
import sanitizeHtml from "sanitize-html";

import Typography from "components/atoms/Typography/Typography";
import { TYPOGRAPHY } from "components/atoms/Typography/Typography.styles";
import TranslateButton from "components/atoms/Buttons/TranslateButton";

interface Props {
  original?: string;
  translated?: string;
}

const TranslatableDescription: FC<Props> = ({ original, translated }) => {
  const [translate, setTranslate] = useState(true);
  const { t } = useTranslation();
  const cleanHtml = (dirty?: string) => {
    if (!dirty) return "";
    return sanitizeHtml(dirty, {
      allowedTags: ["b", "i", "em", "strong", "a", "br", "div", "p", "img"],
      allowedAttributes: {
        a: ["href", "target", "rel"],
        img: ["src"],
      },
    });
  };
  if (!translated) {
    return (
      <Typography align="left" type={TYPOGRAPHY.BODY1}>
        <div
          style={{ whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{ __html: cleanHtml(original) || "" }}
        />
      </Typography>
    );
  }

  return (
    <>
      <Typography align="left" type={TYPOGRAPHY.BODY1}>
        <div
          style={{ whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{
            __html: translate
              ? cleanHtml(translated)
              : cleanHtml(original) || "",
          }}
        />
      </Typography>
      <TranslateButton
        onClick={() => {
          setTranslate((prev) => !prev);
        }}
      >
        {t(translate ? "Translate.ShowOriginal" : "Translate.ShowTranslation")}
      </TranslateButton>
    </>
  );
};

export default TranslatableDescription;
