import { RouteProps } from "react-router-dom";

export interface IRoute extends RouteProps {
  name: string;
  path: string;
}
export enum ROUTE {
  HOME = "/",
  ADMIN = "/configureIframe",
  TRANSLATIONS = "/mes-labojam-tulkojumus",
  DEMO = `/ed96a09d-549a-4fad-8590-03d744eb856f`,
  IFRAME = "/:id",
  EVENT_FORM = "/:eventKey/:id",
  IFRAME_WITH_EVENT = "/:id/event/:slug",
}
