import React, { ChangeEvent, memo } from "react";
import { Form } from "semantic-ui-react";

import { IBaseDynamicFormFieldProps } from "interfaces/forms";

export interface FormTextAreaProps extends IBaseDynamicFormFieldProps<string> {
  error?: string;
}

const FormTextArea: React.FC<FormTextAreaProps> = ({
  onChange,
  id,
  label,
  required,
  error,
  ...props
}) => {
  const changeFn = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value, id);
    }
  };
  return (
    <Form.Field
      {...props}
      id={id}
      required={required}
      label={label}
      error={
        error
          ? {
              content: error,
              pointing: "below",
            }
          : false
      }
      onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
        changeFn(event);
      }}
      control="textarea"
    />
  );
};

export default memo(FormTextArea);
