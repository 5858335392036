import * as React from "react";

interface Props {
  leftColor: string;
  rightColor: string;
}
// no hooks! this is meant to be used outside render tree
const MapMarker: React.FC<Props> = ({ leftColor, rightColor }) => {
  return (
    <svg
      width={21.785}
      height={28.816}
      viewBox="0 0 21.785 28.816"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="prefix__a">
          <stop offset={0} stopColor={leftColor} />
          <stop offset={1} stopColor={rightColor} />
        </linearGradient>
        <linearGradient
          href="#prefix__a"
          id="prefix__b"
          x1={16.493}
          y1={16.726}
          x2={14.551}
          y2={16.782}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1.18 -.329)"
        />
        <linearGradient
          href="#prefix__a"
          id="prefix__c"
          x1={16.493}
          y1={16.726}
          x2={14.551}
          y2={16.782}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1.18 -.329)"
        />
      </defs>
      <g
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="bevel"
        strokeMiterlimit={1}
        paintOrder="stroke markers fill"
        color="#000"
        fontWeight={400}
        fontFamily="sans-serif"
      >
        <path
          style={{
            lineHeight: "normal",
            fontVariantLigatures: "normal",
            fontVariantPosition: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantAlternates: "normal",
            fontVariantEastAsian: "normal",
            fontFeatureSettings: "normal",
            fontVariationSettings: "normal",
            textIndent: 0,
            textAlign: "start",
            textDecorationLine: "none",
            textDecorationStyle: "solid",
            textDecorationColor: "#000",
            textTransform: "none",
            textOrientation: "mixed",
            whiteSpace: "normal",
            // shapePadding: 0,
            shapeMargin: 0,
            inlineSize: 0,
            isolation: "auto",
            mixBlendMode: "normal",
            // solidColor: "#000",
            // solidOpacity: 1,
          }}
          d="M16.368 27.88c-.95-1.125-2.06-2.646-3.41-4.67-1.746-2.618-2.962-4.672-4.043-6.831-1.644-3.286-2.438-5.84-2.517-8.1-.104-2.973 1.14-5.122 3.708-6.409C12.79.525 17.524.176 21.13 1.058c1.878.46 3.177 1.123 4.21 2.15a5.957 5.957 0 011.5 2.425c.239.734.287 1.153.284 2.48-.003 1.174-.012 1.284-.177 2.069-.74 3.526-2.734 7.6-6.46 13.193-1.647 2.474-3.497 4.96-3.69 4.96-.025 0-.218-.205-.429-.455zm.323-11.352c.048-.068.361-.532.696-1.032 1.79-2.678 2.875-4.906 3.155-6.477.105-.59.052-1.418-.113-1.76-.455-.946-1.823-1.46-3.88-1.46-1.525 0-2.811.342-3.445.915-1.093.988-.61 3.355 1.382 6.771.513.88 2.013 3.17 2.075 3.167.023-.001.081-.057.13-.124z"
          overflow="visible"
          fill="url(#prefix__b)"
          transform="translate(-5.865 -.045)"
        />
        <path
          style={{
            lineHeight: "normal",
            fontVariantLigatures: "normal",
            fontVariantPosition: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantAlternates: "normal",
            fontVariantEastAsian: "normal",
            fontFeatureSettings: "normal",
            fontVariationSettings: "normal",
            textIndent: 0,
            textAlign: "start",
            textDecorationLine: "none",
            textDecorationStyle: "solid",
            textDecorationColor: "#000",
            textTransform: "none",
            textOrientation: "mixed",
            whiteSpace: "normal",
            // shapePadding: 0,
            shapeMargin: 0,
            inlineSize: 0,
            isolation: "auto",
            mixBlendMode: "normal",
            // solidColor: "#000",
            // solidOpacity: 1,
          }}
          d="M9.404.557C7.432.702 5.575 1.126 4.228 1.8 1.652 3.092.402 5.254.506 8.234c.079 2.266.873 4.824 2.52 8.112 1.08 2.16 2.298 4.215 4.044 6.834 1.35 2.024 2.461 3.546 3.412 4.672a8.332 8.332 0 00.383.421l.033.03.014.008.008.003.004.002h.007c.027 0 .046-.013.073-.033a.993.993 0 00.093-.086c.074-.074.168-.18.282-.316.227-.272.528-.662.875-1.127a83.93 83.93 0 002.39-3.408c3.727-5.595 5.724-9.672 6.465-13.203.165-.786.173-.9.176-2.075.003-1.327-.044-1.753-.283-2.488a5.99 5.99 0 00-1.508-2.437c-1.038-1.03-2.341-1.696-4.223-2.157-1.806-.442-3.895-.575-5.867-.43zm.004.054c1.967-.145 4.05-.01 5.85.43 1.875.459 3.17 1.118 4.199 2.14a5.93 5.93 0 011.492 2.417c.238.73.284 1.144.281 2.47-.002 1.174-.011 1.278-.176 2.063-.738 3.52-2.73 7.591-6.455 13.183a83.829 83.829 0 01-2.39 3.407 27.78 27.78 0 01-.871 1.123 5.166 5.166 0 01-.28.314.929.929 0 01-.087.08c-.022.017-.038.021-.038.022l-.002-.002a2.112 2.112 0 01-.125-.121 8.324 8.324 0 01-.283-.32c-.948-1.124-2.058-2.643-3.408-4.667-1.745-2.617-2.961-4.67-4.041-6.828C1.43 13.038.639 10.487.56 8.232.457 5.27 1.691 3.133 4.252 1.85 5.589 1.18 7.44.756 9.408.61zm1.275 5.116c-1.529 0-2.818.34-3.462.921-.557.503-.71 1.355-.475 2.502.234 1.148.853 2.594 1.85 4.303.257.441.761 1.234 1.208 1.916.224.341.433.655.59.883.08.114.145.207.194.271.024.033.043.059.058.077l.022.021.01.008.005.004.014.002c.017 0 .026-.008.037-.016a.695.695 0 00.113-.121c.051-.07.364-.531.698-1.031 1.79-2.68 2.877-4.91 3.158-6.488.053-.299.066-.653.045-.979-.02-.326-.075-.62-.16-.799-.232-.48-.693-.849-1.354-1.097-.66-.249-1.52-.378-2.55-.377zm0 .054c1.027 0 1.88.128 2.532.373.651.245 1.1.608 1.324 1.073.079.164.134.455.154.777.02.321.007.673-.045.965-.278 1.563-1.361 3.79-3.15 6.467-.334.5-.65.967-.695 1.03a.616.616 0 01-.1.108l-.002.002-.012-.013a8.768 8.768 0 01-.25-.342 46.78 46.78 0 01-.59-.881 52.375 52.375 0 01-1.205-1.914c-.995-1.707-1.613-3.15-1.845-4.287-.232-1.138-.078-1.965.459-2.45.624-.564 1.904-.907 3.425-.908z"
          overflow="visible"
        />
      </g>
      <path
        d="M16.368 27.88c-.95-1.125-2.06-2.646-3.41-4.67-1.746-2.618-2.962-4.672-4.043-6.831-1.644-3.286-2.438-5.84-2.517-8.1-.104-2.973 1.14-5.122 3.708-6.409C12.79.525 17.524.176 21.13 1.058c1.878.46 3.177 1.123 4.21 2.15a5.957 5.957 0 011.5 2.425c.239.734.287 1.153.284 2.48-.003 1.174-.012 1.284-.177 2.069-.74 3.526-2.734 7.6-6.46 13.193-1.647 2.474-3.497 4.96-3.69 4.96-.025 0-.218-.205-.429-.455zm.323-11.352c.048-.068.361-.532.696-1.032 1.79-2.678 2.875-4.906 3.155-6.477.105-.59.052-1.418-.113-1.76-.455-.946-1.823-1.46-3.88-1.46-1.525 0-2.811.342-3.445.915-1.093.988-.61 3.355 1.382 6.771.513.88 2.013 3.17 2.075 3.167.023-.001.081-.057.13-.124z"
        fill="url(#prefix__c)"
        transform="translate(-5.865 -.045)"
      />
      <path
        d="M10.542 16.323c-.485-.67-1.674-2.542-2.11-3.322-1.123-2.01-1.696-3.607-1.707-4.753-.008-.954.32-1.516 1.107-1.9 1.478-.722 4.289-.732 5.701-.02.89.448 1.196 1.032 1.136 2.173-.064 1.244-.903 3.253-2.317 5.552-.502.818-1.597 2.455-1.64 2.455-.02 0-.096-.084-.17-.185z"
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.039}
        strokeLinecap="round"
        strokeLinejoin="bevel"
        strokeMiterlimit={1}
        paintOrder="stroke markers fill"
      />
    </svg>
  );
};

export default MapMarker;
