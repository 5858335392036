import * as React from "react";
import { CSSObject } from "styled-components";

import {
  StyledBody1,
  StyledBody2,
  StyledH1,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledH5,
  StyledTitle1,
  StyledTitle2,
  StyledTitle3,
  StyledTitle4,
  StyledButton1Text,
  StyledButton2Text,
  StyledCaption,
  StyledSmallCaption,
  TYPOGRAPHY,
} from "./Typography.styles";

import { TComponentRef } from "../Primitives/DynamicRefComponent";

interface TypographyProps {
  align?: CSSObject["textAlign"];
  color?: string;
  as?: TComponentRef;
  type?: TYPOGRAPHY;
  inline?: boolean;
  id?: string;
  margin?: string;
  wrap?: boolean;
  className?: string;
}

const defaultTypeMapping: Record<TYPOGRAPHY, TComponentRef> = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  title1: "h4",
  title2: "h4",
  title3: "h4",
  title4: "h5",
  body1: "p",
  body2: "p",
  caption: "span",
  smallCaption: "span",
  button1: "span",
  button2: "span",
};

const Typography: React.FC<TypographyProps> = ({
  children,
  align,
  color,
  as: asComponent,
  type,
  id,
  wrap,
  margin,
  ...props
}) => {
  let Component = StyledBody1;
  if (type === TYPOGRAPHY.BUTTON1) {
    Component = StyledButton1Text;
  } else if (type === TYPOGRAPHY.BUTTON2) {
    Component = StyledButton2Text;
  } else if (type === TYPOGRAPHY.CAPTION) {
    Component = StyledCaption;
  } else if (type === TYPOGRAPHY.SMALL_CAPTION) {
    Component = StyledSmallCaption;
  } else if (type === TYPOGRAPHY.H1) {
    Component = StyledH1;
  } else if (type === TYPOGRAPHY.H2) {
    Component = StyledH2;
  } else if (type === TYPOGRAPHY.H3) {
    Component = StyledH3;
  } else if (type === TYPOGRAPHY.H4) {
    Component = StyledH4;
  } else if (type === TYPOGRAPHY.H5) {
    Component = StyledH5;
  } else if (type === TYPOGRAPHY.TITLE1) {
    Component = StyledTitle1;
  } else if (type === TYPOGRAPHY.TITLE2) {
    Component = StyledTitle2;
  } else if (type === TYPOGRAPHY.TITLE3) {
    Component = StyledTitle3;
  } else if (type === TYPOGRAPHY.TITLE4) {
    Component = StyledTitle4;
  } else if (type === TYPOGRAPHY.BODY2) {
    Component = StyledBody2;
  }

  const innerComponentRef =
    asComponent || defaultTypeMapping[type || TYPOGRAPHY.BODY1];

  return (
    <Component
      align={align}
      color={color}
      componentRef={innerComponentRef}
      id={id}
      wrap={wrap}
      margin={margin}
      {...props}
    >
      {children}
    </Component>
  );
};

Typography.defaultProps = {
  align: "left",
  type: TYPOGRAPHY.BODY1,
};

export default Typography;
