import { Button } from "semantic-ui-react";
import { darken, lighten } from "polished";

import styled from "styles/styled";

export const BaseButton = styled(Button)<{
  initialPadding?: 0 | 1;
}>(({ theme, size }) => ({
  "&&&": {
    ...theme.typography.button1,
    borderRadius: "20px",
    padding: size === "small" ? "0.35em 1em 0.35em" : ".62em 1.2em .62em",
    backgroundColor: theme.palette.mono.base,
    border: "solid 2px",
    borderColor: theme.palette.mono.white,
    "&:hover": {
      backgroundColor: darken(0.03, theme.palette.primary.main),
      color: theme.palette.mono.white,
    },
  },
}));

export const PrimaryButton = styled(BaseButton)(({ theme }) => ({
  "&&&": {
    backgroundColor: theme.palette.primary.main,
  },
}));
export const SecondaryButton = styled(BaseButton)(({ theme }) => ({
  "&&&": {
    backgroundColor: theme.palette.secondary.main,
  },
}));
export const FilterButton = styled(BaseButton)(({ theme }) => ({
  "&&&": {
    backgroundColor: theme.palette.mono.white,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: lighten(0.5, theme.palette.primary.main),
      borderColor: theme.palette.mono.white,
      color: theme.palette.primary.main,
    },
  },
}));
export const TextButton = styled(BaseButton)(({ theme }) => ({
  "&&&": {
    margin: "0",
    borderRadius: "0",
    border: "none",
    color: theme.palette.mono.dark,
    backgroundColor: "transparent",
    fontWeight: 600,
    fontSize: "0.67em",
    padding: "15px 25px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      color: theme.palette.mono.dark,
    },
  },
}));
