import React, { FC } from "react";

import { FIELD, IBaseDynamicFormFieldProps } from "interfaces/forms";

import FormTextInput from "./FormTextInput";
import FormCheckbox from "./FormCheckbox";
import FormSelect from "./FormSelect";
import FormMultiSelect from "./FormMultiSelect";
import FormDateTimeInput from "./FormDateTimeInput";
import FormTextArea from "./FormTextArea";
import FormGeolocationInput from "./FormGeolocationInput";
import FormIntegerInput from "./FormIntegerInput";
import FormColorPicker from "./FormColorPicker";
import FormJsonArea from "./FormJsonArea";
import FormImageZone from "./FormImageZone";
import FormImageDescription from "./FormImageDescription";

export interface IFormField<T> extends IBaseDynamicFormFieldProps<T> {
  errorObj?: Record<string, string>;
  type: FIELD;
}
const FormField: FC<IFormField<unknown>> = ({
  type,
  id,
  errorObj,
  ...props
}) => {
  let FormComponent: FC<IBaseDynamicFormFieldProps<any>> = FormTextInput;

  if (type === FIELD.CHECKBOX) {
    FormComponent = FormCheckbox;
  } else if (type === FIELD.SELECT) {
    FormComponent = FormSelect;
  } else if (type === FIELD.MULTISELECT) {
    FormComponent = FormMultiSelect;
  } else if (type === FIELD.IMAGE_DESCRIPTION) {
    FormComponent = FormImageDescription;
  } else if (type === FIELD.IMAGE) {
    FormComponent = FormImageZone;
  } else if (type === FIELD.DATES) {
    FormComponent = FormDateTimeInput;
  } else if (type === FIELD.TEXTAREA) {
    FormComponent = FormTextArea;
  } else if (type === FIELD.LOCATION) {
    FormComponent = FormGeolocationInput;
  } else if (type === FIELD.INTEGER) {
    FormComponent = FormIntegerInput;
  } else if (type === FIELD.COLOR) {
    FormComponent = FormColorPicker;
  } else if (type === FIELD.JSON) {
    FormComponent = FormJsonArea;
  }

  return (
    <FormComponent {...props} id={id} error={errorObj?.[`${id}` as any]} />
  );
};

export default FormField;
