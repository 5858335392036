import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Container, Form, FormGroup } from "semantic-ui-react";
import styled from "styled-components";

import useForm from "hooks/useForm";
import FormField from "components/molecules/FormFields/FormField";
import { FIELD } from "interfaces/forms";
import SimpleButton from "components/atoms/Buttons/SimpleButton/SimpleButton";
import Typography from "components/atoms/Typography/Typography";
import { TYPOGRAPHY } from "components/atoms/Typography/Typography.styles";
import {
  GetCategoryOpts,
  GetOrganizationOpts,
  GetTagOpts,
} from "api/Event/getEventOpts";
import RIGA_GEO from "constants/locations";

type Props = {
  optionData: any;
  handleSubmitForm: (form: IEventForm) => void;
};
export interface IEventForm {
  organiser: string;
  name: string;
  partnerOrganiser: string;
  category: string;
  image?: File | null;
  imageDescription?: File | null;
  website: string;
  startTime: string;
  endTime: string;
  description: string;
  tags: { id: string; tag: string }[];
  online: boolean;
  location: { lat: number; lng: number; city?: string; country?: string };
  placeName: string;
}
const StyledForm = styled(Form)({
  "&&&&": {
    margin: "2em",
  },
});

const StyledContainer = styled(Container)({
  "&&&&": {
    margin: "2em",
    paddingBottom: "2em",
    ".heading": {
      margin: "1em 0",
    },
    ".textBlock": {
      margin: "2em",
      ">*": {
        lineHeight: "1.5em",
      },
    },
    ".orDescription": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    textArea: {
      height: "20em !important",
    },
  },
});

const defaultInitValues: IEventForm = {
  organiser: "",
  name: "",
  partnerOrganiser: "",
  category: "",
  website: "",
  startTime: "",
  endTime: "",
  description: "",
  imageDescription: null,
  tags: [],
  online: false,
  image: null,
  location: RIGA_GEO,
  placeName: "",
};
export const defaultOrgId = "b1cf4082-a88a-42b0-8c82-5f6fd6277777";

const EventForm: ForwardRefRenderFunction<any, Props> = (
  { optionData, handleSubmitForm },
  ref,
) => {
  const {
    values,
    submitForm,
    changeFormValue,
    fieldErrors,
    resetForm,
    isLoading,
    validateFormValue,
    isDirty,
  } = useForm(defaultInitValues, handleSubmitForm);
  const {
    name,
    organiser,
    partnerOrganiser,
    category,
    image,
    website,
    startTime,
    endTime,
    description,
    imageDescription,
    tags,
    location,
    online,
  } = values;
  const [formOpts, setFormOpts] = useState<Record<string, any[]>>({});

  const getFormOptions = async () => {
    const orgOpts = await GetOrganizationOpts(optionData?.organizers?.or || []);
    const categoryOpts = await GetCategoryOpts();
    const tagOpts = await GetTagOpts();
    setFormOpts({
      orgOpts:
        orgOpts.length > 0
          ? orgOpts
          : [{ key: defaultOrgId, value: defaultOrgId, text: "default" }],
      categoryOpts,
      tagOpts,
    });
  };

  // add formReset function to form ref to call from parent component
  useImperativeHandle(
    ref,
    () => {
      return {
        formReset() {
          resetForm();
        },
      };
    },
    [resetForm],
  );

  useEffect(() => {
    getFormOptions();
    // eslint-disable-next-line
  }, []);
  return (
    <StyledContainer>
      <div className="textBlock">
        <Typography type={TYPOGRAPHY.H2} className="heading">
          Sveicināts! <br />
          Šī ir ievades forma Jūsu pasākumu kalendāram!
        </Typography>
        <Typography type={TYPOGRAPHY.BODY1}>
          Ja rodās kādi tehniski izaicinājumi, tad droši darba laikā zvaniet vai
          rakstiet mūsu kolēģim Matīsam Andersonam:
          <a href="mailto:pasakumi@kurp.es"> pasakumi@kurp.es</a> vai +371
          28227605
        </Typography>
        <Typography type={TYPOGRAPHY.BODY1}>
          Sīkākas instrukcijas un informācija par šo pakalpojumu pieejama{" "}
          <a href="https://kurpes.lv">šeit</a>
        </Typography>
      </div>
      <StyledForm>
        <FormField
          width={16}
          type={FIELD.SELECT}
          label="Organizators"
          onChange={changeFormValue}
          value={organiser}
          options={formOpts.orgOpts}
          id="organiser"
          onBlur={validateFormValue}
        />
        <FormField
          width={16}
          type={FIELD.INPUT}
          label="Partnerorganizācija"
          onChange={changeFormValue}
          value={partnerOrganiser}
          id="partnerOrganiser"
          onBlur={validateFormValue}
        />
        <FormField
          width={16}
          type={FIELD.INPUT}
          label="Pasākuma nosaukums"
          onChange={changeFormValue}
          value={name}
          id="name"
          required
          errorObj={fieldErrors}
          onBlur={validateFormValue}
        />
        <Form.Group widths="equal">
          <FormField
            type={FIELD.SELECT}
            label="Kategorija"
            onChange={changeFormValue}
            options={formOpts.categoryOpts}
            value={category}
            id="category"
          />
          <FormField
            type={FIELD.IMAGE}
            label="Pasākuma attēls"
            onChange={changeFormValue}
            value={image}
            id="image"
            required
          />
        </Form.Group>
        <FormField
          type={FIELD.INPUT}
          label="Saite papildus informācijai"
          onChange={changeFormValue}
          value={website}
          id="website"
        />
        <FormGroup widths={2}>
          <FormField
            type={FIELD.DATES}
            label="Pasākuma sākuma laiks"
            onChange={changeFormValue}
            value={startTime}
            id="startTime"
            required
          />
          <FormField
            type={FIELD.DATES}
            label="Pasākuma beigu laiks"
            onChange={changeFormValue}
            value={endTime}
            id="endTime"
            required
          />
        </FormGroup>
        <FormGroup widths="equal">
          <FormField
            type={FIELD.IMAGE_DESCRIPTION}
            label="Pasākuma afiša"
            onChange={changeFormValue}
            value={imageDescription}
            id="imageDescription"
          />
          <p className="orDescription">Vai</p>
          <FormField
            type={FIELD.TEXTAREA}
            label="Pasākuma apraksts"
            onChange={changeFormValue}
            value={description}
            disabled={imageDescription !== null}
            id="description"
          />
        </FormGroup>

        <FormField
          type={FIELD.MULTISELECT}
          label="Pasākuma atslēgvārdi"
          options={formOpts.tagOpts}
          search
          onChange={(data, id) => {
            const tagArr = (data as string[]).map((d) => ({
              id: d,
              tag: formOpts.tagOpts.find((t) => t.key === d).text,
            }));
            return changeFormValue(tagArr, id);
          }}
          value={Object.values(tags).map((obj) => obj.id)}
          id="tags"
        />

        <FormField
          type={FIELD.CHECKBOX}
          label="Pasākums notiek tiešsaistē"
          onChange={changeFormValue}
          value={online}
          id="online"
        />
        {!online && (
          <>
            <FormField
              type={FIELD.LOCATION}
              label="Norises vietas adrese"
              onChange={changeFormValue}
              value={location}
              id="location"
            />
            {/* <FormField
              type={FIELD.INPUT}
              label="placeName"
              onChange={changeFormValue}
              value={placeName}
              id="placeName"
            /> */}
          </>
        )}
        <SimpleButton
          onClick={submitForm}
          loading={isLoading}
          disabled={!isDirty || Object.keys(fieldErrors).length > 0}
        >
          Izveidot pasākumu
        </SimpleButton>
      </StyledForm>
    </StyledContainer>
  );
};

export default forwardRef(EventForm);
