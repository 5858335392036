import React, { SyntheticEvent } from "react";
import { Card } from "semantic-ui-react";

import Typography from "components/atoms/Typography/Typography";
import { TYPOGRAPHY } from "components/atoms/Typography/Typography.styles";
import IEventCard from "api/interfaces/IEventCard";
import styled from "styles/styled";
import EventDetailList from "components/molecules/Lists/EventDetailList";
import { formatDisplayDate, kurpesAddress } from "helpers/helpers";
import useGlobalContext from "hooks/useGlobalContext";

import FixedRatioImage, {
  ASPECT,
} from "../../Images/FixedRatioImage/FixedRatioImage";

export interface MainCardProps {
  titleTranslated?: string;
  data: IEventCard;
  onClick?: (event: SyntheticEvent<MouseEvent>) => void;
  mini?: boolean;
  className?: string;
}

const StyledCard = styled(Card)({
  "&&&": {
    boxShadow: "none",
    border: "none",
    width: "100%",
    ":hover": {
      transform: "translateY(-3px)",
      boxShadow: "rgba(34, 36, 38, 0.15) 0px 0px 20px",
    },
  },
});
const StyledCardHeader = styled(Card.Header)({
  "&&&": {
    marginBottom: "13px",
  },
});

const MainCard: React.FC<MainCardProps> = ({
  titleTranslated,
  data: { imageThumbnail, name, startTime, endTime, street, city, placeName },
  onClick,
  mini,
  className,
}) => {
  const {
    locale: { currentLocale },
  } = useGlobalContext();
  return (
    <StyledCard onClick={onClick} className={className}>
      <FixedRatioImage
        src={imageThumbnail}
        aspectRatio={ASPECT.RATIO_16_TO_9}
      />
      <Card.Content>
        <StyledCardHeader>
          <Typography
            align="center"
            wrap
            type={mini ? TYPOGRAPHY.H5 : TYPOGRAPHY.H2}
            margin="0"
          >
            {titleTranslated || name}
          </Typography>
        </StyledCardHeader>
        <Card.Description>
          <EventDetailList
            startTime={formatDisplayDate(
              currentLocale,
              [startTime, endTime],
              mini,
              true,
            )}
            location={mini ? "" : kurpesAddress(placeName, street, city)}
          />
        </Card.Description>
      </Card.Content>
    </StyledCard>
  );
};

export default MainCard;
