export enum API {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  ANY = "ANY",
}

interface IApiMaker {
  method: API;
  headers: Record<string, string>;
  body: string;
  token: string;
}

export const ApiSettingsMaker = (
  method: API,
  body?: Record<string, string>,
  token?: string,
) => ({
  method,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(token && { Authorization: token }),
  },
  body: JSON.stringify(body),
});

export const GetSettings = {
  method: "GET",
};
