import React, { useEffect, useState, useCallback } from "react";

import useIframeContext from "hooks/useIFrameContext";
import useAPI from "hooks/useApi";
import {
  FILTER_OPT,
  GetEventCount,
  GetEvents,
  GetFilterValues as getFilterVals,
} from "api/query";
import IEventCard from "api/interfaces/IEventCard";
import IQuery from "api/interfaces/IQuery";
import {
  getClauseVal,
  mapValuesToOpts as mapValsToOpts,
} from "helpers/wizardHelpers";
import { parseDate } from "helpers/helpers";
import ILocalizedDropdownOpts from "interfaces/ILocalizedDropdownOpts";
import { IFilters } from "api/interfaces/IFilters";
import useDebouncedFilters from "hooks/useDebouncedFilters";

export interface IFilterContext {
  loading?: boolean;
  error?: boolean;
  displayData: Array<IEventCard>;
  count: number;
  filters: IFilters;
  filterOpts: {
    tags: ILocalizedDropdownOpts[];
    city: ILocalizedDropdownOpts[];
  };
  allTags: ILocalizedDropdownOpts[];
  setFilter: <T extends keyof IFilters>(value: IFilters[T], id: T) => void;
  setPageSettings: (value: number, id: "limit" | "offset") => void;
}

export const FilterContext = React.createContext<IFilterContext | null>(null);

const getQueryFromFilters = (
  filters: IFilters,
  defaultQuery: IQuery,
): IQuery => {
  const {
    organizers: defaultOrganizers,
    place: defaultPlace,
    category: defaultCategory,
    tags: defaultTags,
    city: defaultCity,
    name: defaultName,
    startTime: defaultStartTime,
    endTime: defaultEndTime,
    limit: defaultLimit,
    offset: defaultOffset,
  } = defaultQuery;
  const { tags, city, date, limit, offset, name } = filters;

  return {
    organizers: defaultOrganizers,
    place: defaultPlace,
    category: defaultCategory,
    tags: tags?.length ? { or: tags, and: defaultTags?.and } : defaultTags,
    city: city?.length ? { or: city } : defaultCity,
    name: name || defaultName,
    startTime: date[0] ? { eq: [parseDate(date[0])] } : defaultStartTime,
    endTime: date[1] ? { eq: [parseDate(date[1], true)] } : defaultEndTime,
    limit: limit || defaultLimit,
    offset: offset || defaultOffset,
  };
};

const getAllTagValues = async () => {
  const vals = await getFilterVals([FILTER_OPT.TAGS]);

  return vals.tags;
};

const FilterContextProvider: React.FC = ({ children }) => {
  const {
    iframe: { initialDataQuery, iframeFilters, presetValues },
  } = useIframeContext();
  const [filters, setFilters] = useState<IFilters>(() => {
    // careful, initial filter data is being set on mount only
    const { city, tags, date } = presetValues;
    const { limit } = initialDataQuery;

    return {
      city: city || [],
      tags: tags || [],
      date: date?.map((d) => new Date(d)) || [], // Today, indefinite :/
      name: "",
      offset: 0,
      limit: limit || 12,
    };
  });

  const [filterOpts, setFilterOpts] = useState<IFilterContext["filterOpts"]>({
    tags: [],
    city: [],
  });

  const { response: allTags } = useAPI(getAllTagValues, []);

  // Set decorated tags
  useEffect(() => {
    /* eslint-disable */
    const { tags: hasTags, city: hasCity } = iframeFilters;
    const { tags: initTags, city: initCities } = initialDataQuery;
    const tagQuery  = { ...getQueryFromFilters(filters, initialDataQuery), tags: initTags};
    const cityQuery = { ...getQueryFromFilters(filters, initialDataQuery), city: initCities};
    const promise = async () => {
      const tagPromise  = hasTags ? getFilterVals([FILTER_OPT.TAGS], tagQuery)  : {};
      const cityPromise = hasCity ? getFilterVals([FILTER_OPT.CITY], cityQuery) : {};
      return Promise.all([ tagPromise, cityPromise]);
    };
    promise()
      .then((res) => {
        const [tagTags, cityTags] = res as any;

        const clauseVal = getClauseVal(initTags); 
        const tagHack = clauseVal && clauseVal.length > 1 ? getClauseVal(initTags): undefined
        setFilterOpts({
          // TODO fix this in a normal manner
          tags: mapValsToOpts(tagHack  , tagTags?.tags || []),
          city: mapValsToOpts(getClauseVal(initCities), cityTags?.city || []),
        });
      })
      .catch((err) => {
        console.error(err);
      });
    /* eslint-enable */
  }, [iframeFilters, filters, initialDataQuery]);

  // filter function
  const getEvents = useCallback(async (): Promise<[IEventCard[], number]> => {
    if (!filters) return [[], 0];
    const query = getQueryFromFilters(filters, initialDataQuery);
    return Promise.all([GetEvents(query), GetEventCount(query)]);
  }, [filters, initialDataQuery]);

  // call filter function whenever filters change
  // (getEvents change whenever filter do)
  const { response, loading, error, setLoading } = useAPI(getEvents, []);

  const {
    uiFilters,
    setDebouncedFilterById,
    setFilterById,
  } = useDebouncedFilters(setFilters, setLoading);

  const [displayData, count] = response || [[], 0];

  return (
    <FilterContext.Provider
      value={{
        loading,
        error,
        displayData,
        count,
        allTags: allTags || [],
        filters: uiFilters,
        setFilter: setDebouncedFilterById,
        setPageSettings: setFilterById,
        filterOpts,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
