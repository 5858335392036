import React, { Suspense, lazy } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Loader } from "semantic-ui-react";
import { ThemeProvider } from "styled-components";
import moment from "moment";

import { ROUTE } from "routes";
import defaultTheme from "styles/defaultTheme";
import ErrorBoundary from "components/organisms/ErrorBoundary/ErrorBoundary";
import IframePage from "components/pages/IframePage";
import useGlobalContext from "hooks/useGlobalContext";
import { calendarFormat, CALENDAR } from "momentFormats";

// Codesplit all admin pages
const AdminPage = lazy(() => import("components/pages/AdminPage"));
const TildePage = lazy(() => import("components/pages/TildePage"));
const PublicEventPage = lazy(() => import("components/pages/PublicEventPage"));

moment.calendarFormat = calendarFormat;

const App: React.FC = () => {
  const {
    locale: { currentLocale },
  } = useGlobalContext();
  moment.locale(currentLocale.toLowerCase(), CALENDAR[currentLocale]);

  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={defaultTheme}>
        <ToastContainer />
        <ErrorBoundary>
          <Router>
            <Switch>
              <Route path={ROUTE.ADMIN} component={AdminPage} />
              <Route exact path={ROUTE.TRANSLATIONS} component={TildePage} />
              <Route
                exact
                path={ROUTE.EVENT_FORM}
                component={PublicEventPage}
              />
              <Route
                exact
                path={[ROUTE.IFRAME, ROUTE.IFRAME_WITH_EVENT]}
                component={IframePage}
              />
              <Redirect from={ROUTE.HOME} to={ROUTE.DEMO} />
            </Switch>
          </Router>
        </ErrorBoundary>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
