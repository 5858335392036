/* eslint-disable no-param-reassign */ // Because we are using immer
import React, { FC, memo, useCallback, useEffect } from "react";
import { useImmerReducer } from "use-immer";
import { toast } from "react-toastify";
import { FormField, Label } from "semantic-ui-react";
import { FileRejection } from "react-dropzone";

import { actionTypes, dropzoneReducer } from "helpers/dropzoneHelpers";
import { uploadImageFromFile } from "api/s3";
import { IBaseDynamicFormFieldProps } from "interfaces/forms";

import SingleImageDropzone from "../../atoms/ImageDropzone/SingleImageDropzone";

export interface FormImageDescriptionProps
  extends IBaseDynamicFormFieldProps<File | string | undefined> {
  max?: number;
  accept?: string[];
  variant?: "upload" | "get-pointer";
}

const FormImageDescription: FC<FormImageDescriptionProps> = ({
  id,
  label,
  required,
  onChange,
  disabled,
  description,
  onValidate,
  onBlur,
  error,
  value,
  max,
  accept,
  width,
  variant = "get-pointer",
}) => {
  const [dropzoneState, dispatchDropzone] = useImmerReducer(dropzoneReducer, {
    error: "",
    isLoading: false,
    imgUrl:
      typeof value === "object" && value ? URL.createObjectURL(value) : value,
  });

  // for reset form to work properly
  useEffect(() => {
    if (dropzoneState.imgUrl && !value) {
      dispatchDropzone({ type: actionTypes.reset });
    }
  }, [value, dispatchDropzone, dropzoneState.imgUrl]);

  const changeFn = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      try {
        // 1. Get the file
        const file = acceptedFiles[0];
        if (!file) {
          dispatchDropzone({ type: actionTypes.reset });
          if (onChange) onChange(undefined, id);
          if (onValidate) onValidate(fileRejections[0].file, id);
          if (onBlur) onBlur(undefined as any, id);
          return;
        }

        // 2. Update form value
        dispatchDropzone({ type: actionTypes.submit });
        if (variant === "get-pointer") {
          if (onChange) onChange(file, id);
          if (onValidate) onValidate(file, id);
          if (onBlur) onBlur(file as any, id);

          // 3. Generate preview
          dispatchDropzone({
            type: actionTypes.success,
            imgUrl: URL.createObjectURL(file),
          });
        } else {
          const url = await uploadImageFromFile(file);
          if (onChange) onChange(url, id);

          // 3. Generate preview
          dispatchDropzone({
            type: actionTypes.success,
            imgUrl: url,
          });
        }
      } catch (err) {
        dispatchDropzone({ type: actionTypes.error });
        console.error("Failed to upload image!", err);
        toast.error("Forms.GenericError");
      }
    },
    [dispatchDropzone, onChange, onBlur, onValidate, variant, id],
  );

  const { isLoading, imgUrl } = dropzoneState;

  return (
    <FormField id={id} required={required} error={Boolean(error)} width={width}>
      {label && <label htmlFor={id}>{label}</label>}
      <SingleImageDropzone
        max={max}
        accept={accept}
        isLoading={isLoading}
        onDrop={changeFn}
        exampleImgUrl={imgUrl}
        disabled={disabled}
        id={id}
      />
      {error ? (
        <Label prompt>{error}</Label>
      ) : (
        <div className="description">{description}</div>
      )}
    </FormField>
  );
};

export default memo(FormImageDescription);
