import * as React from "react";
import { Segment, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import styled from "styles/styled";

import Typography from "../../atoms/Typography/Typography";
import { TYPOGRAPHY } from "../../atoms/Typography/Typography.styles";
import SimpleButton, {
  BUTTON,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";

export interface ErrorBannerProps {
  refresh?: () => void;
  main?: boolean;
}

const StyledSegment = styled(Segment)<{ margin: 0 | 1 }>(
  ({ margin, theme }) => ({
    "&&&": {
      backgroundColor: theme.palette.mono.lightest,
      textAlign: "center",
      padding: "50px 0",
      border: "none",
      boxShadow: "none",
      borderRadius: 0,
      ".button": {
        marginTop: "1em",
      },
      ...(margin
        ? {
            margin: "10vh",

            "@media only screen and (max-width: 700px)": {
              margin: "5vh",
            },
            "@media only screen and (max-width:600px)": {
              margin: "10px",
            },
          }
        : { margin: 0 }),
    },
  }),
);

const ErrorBanner: React.FC<ErrorBannerProps> = ({ refresh, main }) => {
  const { t } = useTranslation();
  const windowReload = () => window.location.reload();
  return (
    <StyledSegment margin={main ? 1 : 0}>
      <Icon name="umbrella" size="massive" alt="umbrella" />
      <Typography type={TYPOGRAPHY.H1} align="center">
        {t("InfoBanners.SomethingWentWrong")}
      </Typography>
      <Typography type={TYPOGRAPHY.BODY2} align="center">
        {t("InfoBanners.CheckYourInternet")}
      </Typography>
      <SimpleButton
        id="refreshOnErrorButton"
        onClick={refresh || windowReload}
        variant={BUTTON.DEFAULT}
      >
        <Icon name="refresh" />
        {t("InfoBanners.Refresh")}
      </SimpleButton>
    </StyledSegment>
  );
};

export default ErrorBanner;
