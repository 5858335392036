import React from "react";

import useIframeContext from "hooks/useIFrameContext";
import styled from "styles/styled";
import { getClauseVal } from "helpers/wizardHelpers";

import TagsFilter from "./TagsFilter/TagsFilter";
import CityFilter from "./CityFilter";
import DateFilter from "./DateFilter/DateFilter";
import NameFilter from "./NameFilter";

const StyledFilterFrame = styled.div(({ theme }) => ({
  margin: "auto",
  padding: "25px 5px",
  textAlign: "center",
  background: theme.palette.primary.main,
  "& > div": {
    marginBottom: "20px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
  ".firstRowFilters": {
    display: "inline-block",
    "& > *": {
      margin: "2.5px",
      display: "inline-block",
    },
  },
}));

export interface IframeFiltersProps {}

const IframeFilters: React.FC<IframeFiltersProps> = () => {
  const {
    iframe: {
      iframeFilters: {
        highlightedTags,
        highlightOnline,
        tags,
        city,
        date,
        name,
      },
      initialDataQuery: { startTime, endTime },
    },
  } = useIframeContext();

  const startTimeClause = getClauseVal(startTime, "eq");
  const minDate = startTimeClause ? startTimeClause[0] : undefined;
  const endTimeClause = getClauseVal(endTime, "eq");
  const maxDate = endTimeClause ? endTimeClause[0] : undefined;

  return (
    <StyledFilterFrame>
      <div className="filterGridElement">
        {(city || highlightOnline) && (
          <div className="firstRowFilters">
            <CityFilter highlightOnline={highlightOnline} showCity={city} />
          </div>
        )}
        {date && (
          <div className="firstRowFilters">
            <DateFilter minDate={minDate} maxDate={maxDate} />
          </div>
        )}
      </div>
      <div className="dateFilter">
        <TagsFilter buttonTagOpts={highlightedTags} showAllTagFilter={tags} />
      </div>
      {name && (
        <div className="filterGridElement">
          <NameFilter />
        </div>
      )}
    </StyledFilterFrame>
  );
};

export default IframeFilters;
