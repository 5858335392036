/* @typescript-eslint disable no-unused-expressions */
import React, { Component, createRef } from "react";
import { toast } from "react-toastify";
import { withTranslation, WithTranslation } from "react-i18next";

import styled from "styles/styled";
import RIGA_GEO from "constants/locations";

interface GeocoderMapProps {
  address: string;
  defaultGeolocation?: { lat: number; lng: number };
  changeLocation?: (value: { lat: number; lng: number }) => void;
}

const StyledMapContainer = styled.div({
  width: "inherit",
  height: "12.5em",
});

/**
 * works like geolocation input.
 * 1. Takes address
 * 2. gets its geolocation
 * 3. displays this geolocation
 * 4. calls changeLocation with particular geolocation, if given.
 *
 * defaultGeolocation - when address field is empty
 */
class GeocoderMap extends Component<GeocoderMapProps & WithTranslation> {
  googleMapRef = createRef<HTMLDivElement>();

  googleMap: google.maps.Map<HTMLDivElement> | undefined;

  marker: google.maps.Marker | undefined;

  geocoder: google.maps.Geocoder | undefined;

  googleMapScript: HTMLScriptElement | undefined;

  componentDidMount() {
    this.googleMapScript = document.createElement("script");
    this.googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`;
    window.document.body.appendChild(this.googleMapScript);

    this.googleMapScript.addEventListener("load", this.createGoogleMap);
  }

  componentDidUpdate(prevProps: GeocoderMapProps) {
    const { address, changeLocation, t } = this.props;
    if (prevProps.address === address) return;

    this.geocoder?.geocode({ address }, (results, status) => {
      if (status === "OK" && results[0]) {
        const result = results[0];
        const position = {
          city: result.address_components[0]?.long_name || "",
          country:
            result.address_components.filter((add) =>
              add.types.includes("country"),
            )[0].long_name || "",
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
        };
        if (changeLocation) changeLocation(position);
        this.googleMap?.setCenter(position);
        this.marker?.setPosition(position);
      } else {
        toast.error(t("Forms.MapError"));
      }
    });
  }

  componentWillUnmount() {
    this.googleMapScript?.removeEventListener("load", this.createGoogleMap);
  }

  createGoogleMap = () => {
    if (this.googleMapRef.current === null) return;

    const { defaultGeolocation } = this.props;

    this.googleMap = new window.google.maps.Map(this.googleMapRef.current, {
      zoom: 16,
      center: defaultGeolocation || RIGA_GEO,
      disableDefaultUI: true,
    });
    this.marker = new window.google.maps.Marker({
      position: defaultGeolocation || RIGA_GEO,
      map: this.googleMap,
    });
    this.geocoder = new window.google.maps.Geocoder();
  };

  render() {
    return <StyledMapContainer id="google-map" ref={this.googleMapRef} />;
  }
}
export default withTranslation()(GeocoderMap);
