import React, { FC } from "react";
import { Button } from "semantic-ui-react";
import copy from "copy-to-clipboard";
import { useTranslation } from "react-i18next";

interface Props {
  url: string;
}
const CopyToClipboard: FC<Props> = ({ url }) => {
  const { t } = useTranslation();

  const handleLinkCopied = () => {
    if (window !== undefined) {
      copy(url);
    }
  };
  return (
    <Button
      color="grey"
      id="clipboard-copy"
      icon="copy"
      onClick={handleLinkCopied}
      title={t("EventView.Copy")}
      style={{ marginLeft: "5px" }} // TODO why lost margin?
      circular
    />
  );
};

export default CopyToClipboard;
