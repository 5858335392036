import { useState, useEffect, DependencyList } from "react";
import { useImmer } from "use-immer";
// NB! API function must have stable reference
const useAPI = <T>(api: () => Promise<T>, deps: DependencyList) => {
  const [response, setResponse] = useImmer<T | undefined>(undefined);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const doFetch = async () => {
      setLoading(true);
      try {
        const res = await api();
        if (!signal.aborted) setResponse(() => res);
      } catch (e) {
        if (!signal.aborted) {
          console.error(e);
          setError(true);
        }
      } finally {
        if (!signal.aborted) setLoading(false);
      }
    };
    doFetch();
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [api, ...deps]);

  return { response, setResponse, setLoading, error, loading };
};

export default useAPI;
