import * as React from "react";
import styled from "styled-components";

export interface CardGridProps {}

const StyledGrid = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr",
  margin: "0 10px",
  gridRowGap: "10px",
  gridColumnGap: "10px",
  "& > *": {
    margin: "0 !important",
  },

  "@media only screen and (max-width: 400px)": {
    margin: "0",
  },
  "@media only screen and (min-width: 580px)": {
    gridTemplateColumns: "1fr 1fr",
  },
  "@media only screen and (min-width: 900px)": {
    gridTemplateColumns: "1fr 1fr 1fr",
    margin: "0 20px",
    gridRowGap: "20px",
    gridColumnGap: "20px",
  },
  "@media only screen and (min-width: 1200px)": {
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
  },
});

const CardGrid: React.FC<CardGridProps> = ({ children }) => {
  return <StyledGrid>{children}</StyledGrid>;
};

export default CardGrid;
