import { useEffect, RefObject, SyntheticEvent } from "react";

const EVENT = "mousedown";

const useOnClickAway = (
  ref: RefObject<HTMLElement>,
  callback: (event: SyntheticEvent) => void,
) => {
  useEffect(() => {
    const listener = (event: any): any => {
      if (!ref || !ref.current || ref.current.contains(event.target)) {
        return;
      }
      callback(event);
    };
    document.addEventListener(EVENT, listener);
    return () => {
      document.removeEventListener(EVENT, listener);
    };
  }, [ref, callback]);
};

export default useOnClickAway;
