import React, { FC } from "react";
import styled from "styled-components";
import { Dimmer, Loader } from "semantic-ui-react";

import FormSegment from "components/templates/Wrappers/FormSegment";

export interface DropzoneContainerProps {
  isLoading: boolean;
}

const StyledContainer = styled.div(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  padding: "1.250em",
  borderWidth: "2px",
  borderRadius: "2px",
  borderColor: theme.palette.mono.labelBackground,
  borderStyle: "dashed",
  backgroundColor: theme.palette.mono.mainBackground,
  color: theme.palette.mono.filterOptionCount,
  outline: "none",
  transition: "border .24s ease-in-out",
}));

const StyledDropArea = styled.section({
  width: "100%",
  height: "100%",
  alignItems: "center",
  textAlign: "center",
  "* > img": {
    maxHeight: "300px!important",
  },
  ".pdf": {
    color: "black",
    i: {
      marginBottom: "0.1em",
    },
    a: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      justifyItems: "center",
      alignItems: "center",
    },
  },
});

const DropzoneContainer: FC<DropzoneContainerProps> = ({
  children,
  isLoading,
}) => {
  return (
    <FormSegment>
      <Dimmer active={isLoading}>
        <Loader>Ielādē</Loader>
      </Dimmer>
      <StyledContainer>
        <StyledDropArea>{children}</StyledDropArea>
      </StyledContainer>
    </FormSegment>
  );
};

export default DropzoneContainer;
