import { CognitoUserAttribute } from "amazon-cognito-identity-js";

import { IndexSignature } from "interfaces/forms";

import { TUser } from "../context/GlobalContext";

export function MapUserDataToUserObject(userData: CognitoUserAttribute[]) {
  const userObject: IndexSignature = {};
  userData.forEach((ud) => {
    const Name = ud.getName();
    const Value = ud.getValue();
    let newName = Name;
    if (Name === "admin") {
      userObject[Name] = Value === "true";
    } else {
      if (Name === "family_name") newName = "familyName";
      if (Name === "email_verified") newName = "emailVerified";
      if (Name === "sub") newName = "id";
      userObject[newName] = Value;
    }
  });
  return userObject as TUser;
}

export function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
}
