import {
  useEffect,
  useState,
  useLayoutEffect,
  RefObject,
  DependencyList,
} from "react";

interface IResizeHook {
  window: {
    innerWidth: number;
    innerHeight: number;
    clientWidth: number;
    clientHeight: number;
  };
  element: Partial<DOMRect>;
}
const getRelativeToDocument = (currElement?: HTMLElement | null) => {
  if (!currElement) return {};

  const elementPosition = currElement.getBoundingClientRect();

  return {
    left: elementPosition.left,
    bottom: elementPosition.bottom + +document.documentElement.scrollTop,
    top: elementPosition.top + +document.documentElement.scrollTop,
  };
};
const useResizeDetector = (
  element?: RefObject<HTMLElement>,
  elementDeps?: DependencyList,
): IResizeHook => {
  const currElement = element?.current;
  const [size, setSize] = useState<IResizeHook>({
    window: {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      clientWidth: document.body.clientWidth,
      clientHeight: document.body.clientHeight,
    },
    element: {},
  });

  useLayoutEffect(() => {
    setSize((prev) => ({
      ...prev,
      element: getRelativeToDocument(currElement),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currElement, ...(elementDeps || [])]);

  useEffect(() => {
    const handleResize = () => {
      setSize({
        window: {
          innerWidth: window.innerWidth,
          innerHeight: window.innerHeight,
          clientWidth: document.body.clientWidth,
          clientHeight: document.body.clientHeight,
        },
        element: getRelativeToDocument(currElement),
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [currElement, size]);

  return size;
};

export default useResizeDetector;
