import React, { useState, useCallback, memo } from "react";
import { FormField } from "semantic-ui-react";

import { IBaseDynamicFormFieldProps } from "interfaces/forms";

import FormSegment from "../../templates/Wrappers/FormSegment";
import GeocoderMap from "../Maps/GeocoderMap";

export interface IGeoLocation {
  lat: number;
  lng: number;
}
export type FormGeolocationFieldsProps =
  IBaseDynamicFormFieldProps<IGeoLocation>;

const FormGeolocationFields: React.FC<FormGeolocationFieldsProps> = ({
  id,
  label,
  value,
  onChange,
}) => {
  const [address, setAddress] = useState("");

  const onAddressBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setAddress(event.currentTarget.value);
    },
    [setAddress],
  );

  const onGeolocationChange = useCallback(
    (val: IGeoLocation) => {
      if (onChange) onChange(val, id);
    },
    [onChange, id],
  );

  return (
    <>
      <FormField>
        <label htmlFor={id}>{label}</label>
        <input id={id} defaultValue={address} onBlur={onAddressBlur} />
      </FormField>
      <FormSegment>
        <GeocoderMap
          address={address}
          defaultGeolocation={value}
          changeLocation={onGeolocationChange}
        />
      </FormSegment>
    </>
  );
};

export default memo(FormGeolocationFields);
