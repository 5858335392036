import moment from "moment";

import ITag from "api/interfaces/ITag";

import { LOCALE } from "../locales/locale";

export const kurpesAddress = (
  rawPlace?: string | null,
  rawStreet?: string | null,
  rawCity?: string | null,
): string => {
  let place = rawPlace && rawPlace !== " " ? rawPlace : undefined;
  const address = [rawStreet, rawCity].filter((item) => item && item !== " ");
  const [street, city] = address;

  if (place === city) place = undefined;
  if (place === street) place = undefined;
  if (place && (street || city)) {
    return `${place} - ${address.join(", ")}`;
  }
  if (street || city) {
    return address.join(", ");
  }
  if (place) {
    return place;
  }
  return "";
};
const CALENDAR: Record<LOCALE, any> = {
  LV: {
    sameDay: "[Līdz šodienas] LT",
    nextDay: "[Līdz rītdienas] LT",
    sameWeek: "[Līdz] D. MMM, LT",
    sameYear: "[Līdz] D. MMM, LT",
    sameElse: "[Līdz] L",
  },
  EN: {
    sameDay: "[Until today] LT",
    nextDay: "[Until tomorrow] LT",
    sameWeek: "[Until] D. MMM, LT",
    sameYear: "[Until] D. MMM, LT",
    sameElse: "[Until] L",
  },
  UK: {
    sameDay: "[До сьогодні] LT",
    nextDay: "[До завтра] LT",
    sameWeek: "[До] D. MMM, LT",
    sameYear: "[До] D. MMM, LT",
    sameElse: "[До] L",
  },
  RU: {
    sameDay: "[До сегодняшних ] LT",
    nextDay: "[До завтра] LT",
    sameWeek: "[До] D. MMM, LT",
    sameYear: "[До] D. MMM, LT",
    sameElse: "[До] L",
  },
  DE: {
    sameDay: "[Bis heute] LT",
    nextDay: "[Bis morgen] LT",
    sameWeek: "[Bis] D. MMM, LT",
    sameYear: "[Bis] D. MMM, LT",
    sameElse: "[Bis] L",
  },
};

export const formatDisplayDate = (
  language: LOCALE,
  date?: (string | undefined)[],
  short?: boolean,
  relative?: boolean,
): string => {
  if (!date || !date[0]) return "";
  moment.locale(language.toLowerCase());
  const now = moment();
  const startDate = moment(date[0]);
  const endDate = date[1] ? moment(date[1]) : undefined;
  // If event has already started
  if (endDate && now.isAfter(startDate) && now.isBefore(endDate)) {
    return endDate.calendar(CALENDAR[language]);
  }
  // If its longterm event
  if (endDate && moment(startDate).diff(endDate, "hours") >= 24) {
    return `${startDate.format("D. MMMM")} - ${endDate.format("D. MMMM")}`;
  }
  // true for cards on a map. TODO get rid of this.
  if (short) {
    const L_DATE_MOMENT = moment.localeData().longDateFormat("L");
    const L_TIME_MOMENT = moment.localeData().longDateFormat("LT");
    return `${startDate.format(L_DATE_MOMENT)} ${startDate.format(
      L_TIME_MOMENT,
    )}`;
  }
  // true for event cards. TODO get rid of this boolean.
  if (relative) {
    return startDate.calendar();
  }
  const L_DATETIME_MOMENT = moment.localeData().longDateFormat("LLL");
  return startDate.format(L_DATETIME_MOMENT);
};

function addDaysToDate(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const parseDate = (date: string | Date, endOfDay?: boolean): string => {
  let formattedDate = typeof date === "string" ? new Date(date) : date;

  if (endOfDay) {
    formattedDate = addDaysToDate(formattedDate, 1);
  }

  return formattedDate.toISOString();
};

export const isEmptyData = (
  variable: Record<string, any> | Array<string> | undefined,
): boolean => {
  if (variable && variable.constructor === Object) {
    return (
      Object.keys(variable).length === 0 && variable.constructor === Object
    );
  }
  if (Array.isArray(variable)) {
    if (variable.length > 0) {
      return false;
    }
    return true;
  }
  return true;
};

export const tagByLanguage = (tag: ITag, language: LOCALE) => {
  let displayString;
  if (language === LOCALE.LATVIAN) displayString = tag.tag;
  if (language === LOCALE.ENGLISH) displayString = tag.tag_en;
  if (language === LOCALE.RUSSIAN) displayString = tag.tag_ru;
  if (language === LOCALE.UKRAINIAN) displayString = tag.tag_uk;

  return displayString || tag.tag_en || tag.tag;
};
