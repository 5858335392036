import React, { MouseEvent } from "react";

import styled from "styles/styled";

const StyledWrapper = styled.div`
  margin-left: 5px;

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #eee;

    border-radius: 15px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
  .container:hover input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .tick:after {
    content: "";
    position: absolute;
    display: none;
  }
  .cross:after {
    content: "X";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .tick:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .container .cross:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    color: white;
    font-weight: 700;
  }
  .text {
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 2px;
  }
`;
interface Props {
  id: string;
  checked?: boolean;
  variant?: "tick" | "cross";
  onClick?: (event: MouseEvent) => void;
  className?: string;
}

const FilterCheckbox: React.FC<Props> = ({
  checked,
  id,
  children,
  variant,
  onClick,
  className,
}) => {
  return (
    <StyledWrapper className={className}>
      <label className="container" htmlFor={id}>
        <div className="text">{children}</div>
        <input id={id} type="checkbox" checked={checked} onClick={onClick} />
        <span className={`checkmark ${variant || "tick"}`} />
      </label>
    </StyledWrapper>
  );
};

export default FilterCheckbox;
