import React, { ReactNode, CSSProperties, useRef } from "react";
import { Popup, Ref } from "semantic-ui-react";

import SimpleButton, {
  BUTTON,
} from "components/atoms/Buttons/SimpleButton/SimpleButton";
import styled from "styles/styled";
import useResizeDetector from "hooks/useResizeDetector";

interface ControlledPopupProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  buttonText: ReactNode;
  empty?: boolean;
}
const OPEN_STYLE: CSSProperties = {
  position: "relative",
  zIndex: 2000,
};

const StyledPopup = styled(Popup)<{ topPosition: number; screenWidth: number }>(
  ({ topPosition, screenWidth }) => ({
    "&&&": {
      margin: "0",
      top: "-1rem!important",
      "@media only screen and (max-width: 800px)": {
        width: `${screenWidth + 2}px`,
        transform: `translate3d(0, ${topPosition}px , 0) !important`,
        paddingLeft: 0,
        paddingRight: 0,
        ".flatpickr-calendar": {
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
    },
  }),
);

const ControlledPopup: React.FC<ControlledPopupProps> = ({
  open,
  setOpen,
  buttonText,
  empty,
  children,
}) => {
  const ref = useRef<HTMLElement>(null);
  const {
    window: { clientWidth },
    element: { bottom },
  } = useResizeDetector(ref, [buttonText]);
  return (
    <StyledPopup
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      topPosition={bottom}
      trigger={
        <Ref innerRef={ref}>
          <SimpleButton
            variant={empty ? BUTTON.PRIMARY : BUTTON.FILTER}
            type="button"
            style={open ? OPEN_STYLE : {}}
          >
            {buttonText}
          </SimpleButton>
        </Ref>
      }
      flowing
      basic
      screenWidth={clientWidth}
      on={["click"]}
      position="bottom center"
    >
      {children}
    </StyledPopup>
  );
};

export default ControlledPopup;
