import {
  handleApiDelete,
  handleApiGet,
  handleApiPost,
  handleApiPut,
} from "./api";
import IFrame from "./interfaces/IFrame";

const IFRAME = "iframe";

export const postIframe = (body: IFrame, token?: string): Promise<IFrame> => {
  return handleApiPost(
    `${process.env.REACT_APP_IFRAME_KURPES_BACKEND_URL}/${IFRAME}`,
    body,
    token,
  );
};

export const getIframe = (id: string, eventKey?: string): Promise<IFrame> => {
  return handleApiGet(
    `${process.env.REACT_APP_IFRAME_KURPES_BACKEND_URL}/${IFRAME}?id=${id}${
      eventKey ? `&eventKey=${eventKey}` : ""
    }`,
  );
};

export const updateIFrame = (body: IFrame, token?: string): Promise<IFrame> => {
  return handleApiPut(
    `${process.env.REACT_APP_IFRAME_KURPES_BACKEND_URL}/${IFRAME}`,
    body,
    token,
  );
};

export const deleteIframe = (id: string, token?: string): Promise<void> => {
  return handleApiDelete(
    `${process.env.REACT_APP_IFRAME_KURPES_BACKEND_URL}/${IFRAME}`,
    {
      id,
    },
    token,
  );
};

export const getIframes = (token?: string): Promise<IFrame[]> => {
  return handleApiGet(
    `${process.env.REACT_APP_IFRAME_KURPES_BACKEND_URL}/${IFRAME}/all`,
    token,
  );
};
