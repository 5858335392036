/* eslint-disable no-unused-expressions */
import React from "react";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import {
  L_DATE_MOMENT,
  TODAY,
  TOMORROW,
  TOMORROW2,
  TOMORROW6,
} from "constants/dates";

interface DateDisplayProps {
  dateFrom?: string;
  dateTo?: string;
}

const parseFilterDisplayDate = (
  t: TFunction,
  sFrom?: string,
  sTo?: string,
): string => {
  const isEq = (d1: Moment, d2: Moment) => d1.isSame(d2, "d");
  const dateFrom = sFrom ? moment(sFrom) : TODAY;
  const dateTo = sTo ? moment(sTo) : dateFrom;
  /* eslint-disable */
  if (!sFrom                    && !sTo                   ) return t("Filters.NoDate");
  if (!dateFrom.isValid()       || !dateTo.isValid()      ) return t("Filters.NoDate");
  if (isEq(TODAY,     dateFrom) && isEq(TODAY,     dateTo)) return t("Filters.Today");
  if (isEq(TOMORROW,  dateFrom) && isEq(TOMORROW,  dateTo)) return t("Filters.Tomorrow");
  if (isEq(TOMORROW2, dateFrom) && isEq(TOMORROW2, dateTo)) return t("Filters.DayAfterTomorrow");
  if (isEq(TODAY,     dateFrom) && isEq(TOMORROW6, dateTo)) return t("Filters.ThisWeek");
  if (dateTo.diff(dateFrom, "days") >= 45)                  return t("Filters.FromX", { x: dateFrom.format(L_DATE_MOMENT) });
  /* eslint-enable */

  const a = [];
  if (sFrom) a.push(dateFrom.format(L_DATE_MOMENT));
  if (sTo && !isEq(dateFrom, dateTo)) a.push(dateTo.format(L_DATE_MOMENT));

  if (a.length === 1) return t("Filters.FromX", { x: a[0] });
  if (a.length === 2) return t("Filters.FromXtoY", { x: a[0], y: a[1] });

  return t("Filters.NoDate");
};

const DateDisplay: React.FC<DateDisplayProps> = ({ dateFrom, dateTo }) => {
  const { t } = useTranslation();
  return <>{parseFilterDisplayDate(t, dateFrom, dateTo)}</>;
};

export default DateDisplay;
