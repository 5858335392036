import React from "react";

import MainCardWithModal, {
  MainCardWithModalProps,
} from "components/organisms/Cards/MainCardWithModal/MainCardWithModal";
import styled from "styles/styled";
import useIframeContext from "hooks/useIFrameContext";

export type MapCardProps = Omit<MainCardWithModalProps, "mini" | "external">;

const StyledCard = styled.div({
  position: "relative",
  zIndex: 1,
  width: "200px",
  padding: "5px",
  backgroundColor: "white",
});

const MapCard: React.FC<MapCardProps> = (props) => {
  const {
    iframe: {
      displaySettings: { openEventInIframe },
    },
  } = useIframeContext();

  return (
    <StyledCard
      // hack to enable click events on cards ...
      ref={(ref) => ref && google.maps.OverlayView.preventMapHitsFrom(ref)}
    >
      <MainCardWithModal {...props} mini external={!openEventInIframe} />
    </StyledCard>
  );
};

export default MapCard;
