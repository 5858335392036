import React, { memo, SyntheticEvent } from "react";
import { Dropdown, DropdownProps, FormField } from "semantic-ui-react";

import { IBaseDynamicFormFieldProps } from "interfaces/forms";

export interface FormSelectFieldProps
  extends IBaseDynamicFormFieldProps<string> {
  search?: boolean;
}
const FormSelect: React.FC<FormSelectFieldProps> = ({
  id,
  label,
  required,
  options,
  onChange,
  error,
  ...props
}) => (
  <FormField required={required} error={Boolean(error)}>
    {label && <label htmlFor={id}>{label}</label>}
    <Dropdown
      {...props}
      id={id}
      name={id}
      options={options || []}
      selection
      search
      required={required}
      clearable={!required}
      onChange={(_: SyntheticEvent, data: DropdownProps) => {
        const newVal = (data.value as string) || "";
        if (onChange) onChange(newVal, id);
      }}
      onBlur={() => {}}
    />
  </FormField>
);

export default memo(FormSelect);
