import * as React from "react";
import { Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import styled from "styles/styled";

export interface NoEventsProps {}

const StyledSegment = styled.div({
  "&&&": {
    textAlign: "center",
    margin: "10px 20px",
  },
});

const NoEventsBanner: React.FC<NoEventsProps> = () => {
  const { t } = useTranslation();
  return (
    <StyledSegment>
      <Message>
        <Message.Content>
          <Message.Header>{t("InfoBanners.NoEvents")}</Message.Header>
          {t("InfoBanners.TryThis")}
        </Message.Content>
      </Message>
    </StyledSegment>
  );
};

export default NoEventsBanner;
