/* eslint-disable no-unused-expressions */
import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { CSSProperties } from "styled-components";

import RIGA_GEO from "constants/locations";
import useGoogleMarker from "hooks/useGoogleMarker";

interface GeolocationMapProps {
  geolocation?: { lat: number; lng: number };
}

const mapStyles: CSSProperties = {
  width: "inherit",
  height: "350px",
};

const GeolocationMap: React.FC<GeolocationMapProps> = ({ geolocation }) => {
  const customMarker = useGoogleMarker();

  return (
    <GoogleMap
      mapContainerStyle={mapStyles}
      center={geolocation || RIGA_GEO}
      zoom={16}
    >
      <Marker position={geolocation || RIGA_GEO} icon={customMarker} />
    </GoogleMap>
  );
};
export default GeolocationMap;
