import React, { useMemo } from "react";
import { Dropdown, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import styled from "styles/styled";
import { LOCALE, LOCALE_OPTS } from "locales/locale";
import useGlobalContext from "hooks/useGlobalContext";
import useIframeContext from "hooks/useIFrameContext";

interface Props {}

const StyledLang = styled(Dropdown)(({ theme }) => ({
  "&&&": {
    ":hover": {
      background: "transparent!important",
    },
    ":active": {
      background: "transparent!important",
    },
    ":focus": {
      background: "transparent!important",
    },
    ".label": {
      color: theme.palette.mono.darkest,
      background: "transparent",
      fontWeight: 400,
    },
  },
}));

const OPTIONS = (t: TFunction, include: LOCALE[]) => [
  ...LOCALE_OPTS.filter((opt) =>
    include.find((locale) => locale === opt.value),
  ),

  {
    label: (
      <>
        {t("Translate.LanguageSelect")}{" "}
        <Image
          src="https://www.tilde.lv/sites/all/themes/tilde/logo.png"
          style={{ width: "15px", marginTop: "1px", marginLeft: "1px" }}
        />
      </>
    ),
    key: "tilde",
    disabled: true,
  },
];

const LanguagePicker: React.FC<Props> = () => {
  const {
    locale: { currentLocale, setLocale },
  } = useGlobalContext();
  const { t } = useTranslation();
  const {
    iframe: { languageChoice },
  } = useIframeContext();

  const opts = useMemo(() => OPTIONS(t, languageChoice || []), [
    t,
    languageChoice,
  ]);

  return (
    <StyledLang
      basic
      button
      className="icon"
      direction="left"
      icon="world"
      inverted
      options={opts}
      value={currentLocale}
      onChange={
        ((_: any, { value }: any) => {
          setLocale(value as any);
        }) as any
      }
    />
  );
};

export default LanguagePicker;
