import * as React from "react";
import { Menu, Placeholder } from "semantic-ui-react";

import {
  StyledIFrameMenu,
  StyledKurpesLogo,
} from "components/organisms/IFrameHeader/IFrameHeader.styles";
import { KURPES_LOGO } from "constants/images";
import styled from "styles/styled";

import IFrameCardGridLoader from "./IFrameCardGridLoader";

const StyledFilterPlaceholder = styled(Placeholder.Image)({
  "&&&": {
    width: "100%",
    height: "200px !important",
    ":before": {
      height: "5px !important",
    },
  },
});

const StyledCardGridLoaderWrapper = styled.div({
  margin: "10px 0",
});

interface Props {}

const IFrameLoader: React.FC<Props> = () => (
  <>
    <Placeholder fluid>
      <Placeholder.Header>
        <StyledIFrameMenu attached="top" secondary>
          <Menu.Item href="https://kurpes.lv/" target="_blank" position="right">
            <StyledKurpesLogo src={KURPES_LOGO} />
          </Menu.Item>
        </StyledIFrameMenu>
      </Placeholder.Header>
      <StyledFilterPlaceholder />
    </Placeholder>
    <StyledCardGridLoaderWrapper>
      <IFrameCardGridLoader />
    </StyledCardGridLoaderWrapper>
  </>
);

export default IFrameLoader;
