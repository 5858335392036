import ILocalizedDropdownOpts from "interfaces/ILocalizedDropdownOpts";

import IQuery from "./interfaces/IQuery";
import ITag from "./interfaces/ITag";
import ICategory from "./interfaces/ICategory";
import IOrganiser from "./interfaces/IOrganiser";
import IEventCard from "./interfaces/IEventCard";
import { AWSurlDEV, API_ROUTE, handleApiGet, handleApiPost } from "./api";

import { isEmptyData } from "../helpers/helpers";

/// iframe/user/00033311/flag

// tags=[and[kultura, talsi],or[sports],neq[balets]]&organizers=[eq[visasiespejas]]&id=[neq[1113,1114,1115]]

// startTime=[and[today], or[today+3, endTime]]

export const GetEvents = async (query: IQuery): Promise<IEventCard[]> => {
  const resp = await handleApiPost(
    `${process.env.REACT_APP_IFRAME_KURPES_BACKEND_URL}/${API_ROUTE.FILTER}`,
    query,
  );
  return resp.data;
};
export const GetEventCount = async (query: IQuery): Promise<number> => {
  const resp = await handleApiPost(
    `${process.env.REACT_APP_IFRAME_KURPES_BACKEND_URL}/${API_ROUTE.COUNT}`,
    query,
  );
  return Number(resp.count);
};

export const GetEventData = (slug: string): Promise<any> => {
  return handleApiGet(
    `${process.env.REACT_APP_IFRAME_KURPES_BACKEND_URL}/${API_ROUTE.EVENT}?slug=${slug}`,
  );
};

export const GetMissingData = (query: string) => {
  return handleApiGet(
    `${AWSurlDEV}/${API_ROUTE.ACTIVE_FILTER_VALUES}?${query}`,
  );
};
export enum FILTER_OPT {
  TAGS = "tags",
  CITY = "city",
  CATEGORY = "category",
  PLACE = "placeName",
  ORGANISERS = "organisers",
}
export const GetFilterValues = async (
  tags: FILTER_OPT[],
  query?: IQuery,
): Promise<Record<FILTER_OPT, ILocalizedDropdownOpts[]>> => {
  let res;

  if (!isEmptyData(query) && query !== undefined) {
    res = await handleApiPost(
      `${process.env.REACT_APP_IFRAME_KURPES_BACKEND_URL}/${API_ROUTE.FILTER_VALUES}`,
      query,
    );
  } else {
    res = await handleApiGet(
      `${process.env.REACT_APP_IFRAME_KURPES_BACKEND_URL}/${
        API_ROUTE.FILTER_VALUES
      }?name=${tags.join(",")}`,
    );
  }

  const opts = {
    organisers:
      res.organisers
        ?.map((org: IOrganiser) => ({
          value: org.id,
          key: org.id,
          text: org.name,
          t: {
            LV: org.name,
            EN: org.name,
            RU: org.name,
          },
        }))
        .sort((a: ILocalizedDropdownOpts, b: ILocalizedDropdownOpts) =>
          a.text?.localeCompare(b.text),
        ) || [],
    placeName:
      res.placeName?.map((place: { placeName: string }) => ({
        value: place.placeName,
        key: place.placeName,
        text: place.placeName,
        t: {
          LV: place.placeName,
          EN: place.placeName,
          RU: place.placeName,
        },
      })) || [],
    tags:
      res.tags?.map((tag: ITag) => ({
        value: tag.slug,
        key: tag.slug,
        text: tag.tag,
        t: {
          LV: tag.tag,
          EN: tag.tag_en || tag.tag,
          RU: tag.tag_ru || tag.tag_en || tag.tag,
          UK: tag.tag_uk || tag.tag_en || tag.tag,
        },
        count: Number(tag?.count) || 0,
      })) || [],
    category:
      res.category
        ?.map((cat: ICategory) => ({
          value: cat.slug,
          key: cat.slug,
          text: cat.title,
          t: {
            LV: cat.title,
            EN: cat.title_en || cat.title,
            RU: cat.title_ru || cat.title_en || cat.title,
            UK: cat.title_uk || cat.title_en || cat.title,
          },
        }))
        .sort((a: ILocalizedDropdownOpts, b: ILocalizedDropdownOpts) =>
          a.text?.localeCompare(b.text),
        ) || [],
    city:
      res.city?.map((city: { city: string; count: number }) => ({
        value: city.city,
        key: city.city,
        text: city.city,
        t: {
          LV: city.city,
          EN: city.city,
          RU: city.city,
        },
        count: Number(city?.count) || 0,
      })) || [],
  };
  return opts;
};
