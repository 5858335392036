import styled from "styles/styled";

export const StyledDateInput = styled.div(({ theme }) => ({
  "&&&": {
    ".flatpickr-calendar": {
      WebkitBoxShadow: `none`,
      boxShadow: `none`,
      userSelect: `none`,
      margin: "auto",
    },
    ".flatpickr-day.week.selected": {
      WebkitBoxShadow: `-5px 0 0 ${theme.palette.primary.main}, 5px 0 0 ${theme.palette.primary.main}`,
      boxShadow: `-5px 0 0 ${theme.palette.primary.main}, 5px 0 0 ${theme.palette.primary.main}`,
    },

    ".flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1))": {
      WebkitBoxShadow: `-10px 0 0 ${theme.palette.primary.main}`,
      boxShadow: `-10px 0 0 ${theme.palette.primary.main}`,
    },
    ".flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay": {
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    "span.flatpickr-weekday": {
      background: theme.palette.primary.main,
    },
    ".flatpickr-weekdays": {
      background: theme.palette.primary.main,
    },
    ".flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month": {
      backgroundColor: theme.palette.primary.main,
    },

    ".flatpickr-current-month .flatpickr-monthDropdown-months": {
      background: theme.palette.primary.main,
    },
    ".flatpickr-months .flatpickr-month": {
      background: theme.palette.primary.main,
    },
    ".flatpickr-calendar.arrowBottom:after": {
      borderTopColor: theme.palette.primary.main,
    },
    ".flatpickr-calendar.arrowTop:after": {
      borderBottomColor: theme.palette.primary.main,
    },

    "@media only screen and (max-width: 360px)": {
      ".flatpickr-calendar": {
        width: "100%",
      },
      ".flatpickr-days": {
        width: "100%",
      },
      ".dayContainer": {
        width: "100%",
        minWidth: "100%",
        maxWidth: "100%",
      },
      ".flatpickr-day": {
        maxWidth: "14.285714286%",
        height: "30px",
        lineHeight: "30px",
      },
      ".flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg": {
        width: "16px",
        height: "16px",
      },
    },
  },
}));

export const StyledShortcutGroup = styled.div({
  textAlign: "center",
  marginTop: "10px",
});
