import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import useFilterContext from "hooks/useFilterContext";
import styled from "styles/styled";
import IFrameCardGridLoader from "components/atoms/Loaders/IFrameCardGridLoader";
import CardGrid from "components/templates/Grids/CardGrid";
import NoEventsBanner from "components/molecules/InfoBanners/NoEventsBanner";
import useAPI from "hooks/useApi";
import { TildeTranslateArray } from "api/tildeApi";
import useGlobalContext from "hooks/useGlobalContext";

import IFramePaginator from "../IFramePaginator/IFramePaginator";
import MainCardWithModal from "../Cards/MainCardWithModal/MainCardWithModal";
import EventViewModal from "../Modal/EventViewModal/EventViewModal";

export interface IFrameCardGridProps {
  external?: boolean;
}

const StyledPaginatorWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  margin: "7px 0",
});

const StyledMainCardWithModal = styled(MainCardWithModal)({
  height: "100%",
});
interface Params {
  slug: string;
  id: string;
}

const IFrameCardGrid: React.FC<IFrameCardGridProps> = ({ external }) => {
  const { displayData, loading, error } = useFilterContext();
  const {
    locale: { currentLocale },
  } = useGlobalContext();

  // HACK
  const { slug, id } = useParams<Params>();
  const history = useHistory();
  const modalOpen = slug
    ? !displayData.find((item) => item?.slug === slug)
    : false;

  const tilde = useCallback(async () => {
    const titles = displayData?.map((card) => card.name || "") || [];

    const res = await TildeTranslateArray(titles, currentLocale);

    return res;
  }, [displayData, currentLocale]);

  const { response = [] } = useAPI(tilde, []);
  if (loading) return <IFrameCardGridLoader />;
  if (error) return <div>error :(</div>;

  return (
    <>
      {displayData.length === 0 && <NoEventsBanner />}
      <CardGrid>
        {displayData.map((event, index) => (
          <StyledMainCardWithModal
            titleTranslated={response[index]}
            key={event.slug}
            data={event}
            external={external}
          />
        ))}
      </CardGrid>
      <StyledPaginatorWrapper>
        <IFramePaginator />
      </StyledPaginatorWrapper>
      {/* TODO hack */}

      {modalOpen && (
        <EventViewModal
          titleTranslated=""
          cardData={{ slug }}
          open={modalOpen}
          onClose={() => {
            if (external) return;
            history.push(`/${id}`);
          }}
        />
      )}
    </>
  );
};

export default IFrameCardGrid;
