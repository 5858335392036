import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import useAPI from "hooks/useApi";
import { getIframe } from "api/iframe";
import IFrame from "api/interfaces/IFrame";
import IFrameLoader from "components/atoms/Loaders/IFrameLoader";
import ErrorBanner from "components/molecules/InfoBanners/ErrorBanner";
import themeMaker from "styles/themeMaker";
import defaultTheme from "styles/defaultTheme";

export interface IIframeContext {
  id: string;
  iframe: IFrame;
}
interface IParams {
  id: string;
}
export const IframeContext = React.createContext<IIframeContext | null>(null);

const IframeContextProvider: React.FC = ({ children }) => {
  const { id } = useParams<IParams>();
  const getIframeFromParam = useCallback(async () => {
    return getIframe(id);
  }, [id]);
  const { response, loading, error } = useAPI(getIframeFromParam, []);

  const theme = useMemo(() => {
    if (!response) return defaultTheme;
    return themeMaker(response.customStyles);
  }, [response]);

  if (loading) return <IFrameLoader />;
  if (error || !response) return <ErrorBanner />;
  if (!response.valid) return <ErrorBanner />;

  return (
    <IframeContext.Provider
      value={{
        id,
        iframe: response,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </IframeContext.Provider>
  );
};

export default IframeContextProvider;
