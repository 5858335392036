import { IBaseDynamicFormFieldProps } from "interfaces/forms";
import React, { memo, useLayoutEffect, useState } from "react";
import FormTextArea from "./FormTextArea";

export type FormTextAreaProps = IBaseDynamicFormFieldProps<Record<string, string>>;

const BAD_INITIAL_FORMAT = "Neizdevās ielasīt lauku, nepareizs JSON formāts.";
const BAD_FORMAT = "Nepareizs JSON formāts.";
const FormJsonArea: React.FC<FormTextAreaProps> = ({
  id,
  onChange,
  value,
  label,
  ...props
}) => {
  const [stringifiedValue, setStringifiedValue] = useState<string>("");
  const [error, setError] = useState("");
  useLayoutEffect(() => {
    try {
      const stringified = JSON.stringify(value, undefined, 4);
      setStringifiedValue(stringified);
    } catch (err) {
      setError(BAD_INITIAL_FORMAT);
    }
    // intentional componentWillMount replica
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeFn = (val: any) => {
    try {
      setError("");
      setStringifiedValue(val);
      const parsed = JSON.parse(val);
      if (onChange) onChange(parsed, id);
    } catch (err) {
      setError(BAD_FORMAT);
    }
  };
  return (
    <FormTextArea
      {...props}
      id={id}
      label={label}
      error={error}
      value={stringifiedValue}
      onChange={(val) => {
        changeFn(val);
      }}
    />
  );
};
export default memo(FormJsonArea);
