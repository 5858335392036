import React from "react";
import { useHistory, useParams } from "react-router-dom";

import EventViewModal from "components/organisms/Modal/EventViewModal/EventViewModal";
import MainCard, {
  MainCardProps,
} from "components/molecules/Cards/MainCard/MainCard";
import { EVENT_URL } from "constants/site";

export interface MainCardWithModalProps extends MainCardProps {
  external?: boolean;
}

interface Params {
  slug: string;
  id: string;
}

const MainCardWithModal: React.FC<MainCardWithModalProps> = ({
  external,
  titleTranslated,
  ...props
}) => {
  const { data } = props;
  const { slug, id } = useParams<Params>();
  const history = useHistory();
  const modalOpen = slug === data.slug;

  const openEvent = () => {
    if (external) {
      const url = `${EVENT_URL}${data.slug}`;
      const newWindow = window.open(url, "_blank", "noopener");
      if (newWindow) newWindow.opener = null;
    } else {
      history.push(`/${id}/event/${data.slug}`);
    }
  };

  const closeEvent = () => {
    if (external) return;
    history.push(`/${id}`);
  };

  return (
    <>
      <MainCard
        {...props}
        onClick={openEvent}
        titleTranslated={titleTranslated}
      />
      {modalOpen && (
        <EventViewModal
          titleTranslated={titleTranslated}
          cardData={data}
          open={modalOpen}
          onClose={closeEvent}
        />
      )}
    </>
  );
};

export default MainCardWithModal;
