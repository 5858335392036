import { useContext } from "react";

import { IframeContext, IIframeContext } from "context/IframeContext";

const useIframeContext = (): IIframeContext => {
  const context = useContext(IframeContext);
  if (context === null) {
    throw new Error(
      "useIframeContext cannot be used without respective provider!",
    );
  }

  return context;
};

export default useIframeContext;
