/* eslint-disable react/no-array-index-key */
import React from "react";
import { Placeholder } from "semantic-ui-react";
import styled from "styled-components";

interface EventViewLoaderProps {}

const StyledPlaceholder = styled(Placeholder)({
  width: "100%",
});

const EventViewLoader: React.FC<EventViewLoaderProps> = () => {
  return (
    <StyledPlaceholder fluid>
      {new Array(4).map((_, index) => (
        <React.Fragment key={index}>
          <Placeholder.Line length="full" />
          <Placeholder.Line length="very long" />
          <Placeholder.Line length="full" />
          <Placeholder.Line length="long" />
          <Placeholder.Line length="very long" />
        </React.Fragment>
      ))}
    </StyledPlaceholder>
  );
};

export default EventViewLoader;
