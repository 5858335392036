import React, { useState, useEffect } from "react";
import { StrictImageProps, Image } from "semantic-ui-react";

import { PLACEHOLDER_IMAGE_16_TO_9 } from "constants/images";
import styled from "styles/styled";

export interface PlaceholdableImageProps extends StrictImageProps {
  src?: string;
  placeholderImage?: string;
  alt?: string;
}

const StyledImageWrapper = styled.div<{
  placeholderImage?: string;
}>(({ placeholderImage = "" }) => ({
  background: `url(${placeholderImage})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
}));

const StyledImage = styled(Image)<{
  show?: boolean;
}>(({ show = true, theme }) => ({
  visibility: show ? "visible" : "hidden",
  backgroundColor: theme.palette.mono.white,
  width: "100%",
  height: "100%",
}));

const PlaceholdableImage: React.FC<PlaceholdableImageProps> = ({
  src,
  placeholderImage = PLACEHOLDER_IMAGE_16_TO_9,
  alt,
  className,
  ...props
}) => {
  const [img, setImg] = useState({
    src: undefined as string | undefined,
    show: false,
    triedLoadingPlaceholder: false,
  });

  useEffect(() => {
    if (src) {
      setImg({
        src,
        show: false,
        triedLoadingPlaceholder: false,
      });
    } else {
      setImg({
        src: placeholderImage,
        show: false,
        triedLoadingPlaceholder: true,
      });
    }
  }, [src, placeholderImage]);

  const onError = () => {
    if (img.triedLoadingPlaceholder) return;

    setImg({
      src: placeholderImage,
      show: true,
      triedLoadingPlaceholder: true,
    });
  };

  const onLoad = () => {
    setImg({
      ...img,
      show: true,
    });
  };

  return (
    <StyledImageWrapper className={className}>
      <StyledImage
        alt={alt}
        src={img.src}
        show={img.show}
        onError={onError}
        onLoad={onLoad}
        {...props}
      />
    </StyledImageWrapper>
  );
};

export default PlaceholdableImage;
