import React from "react";

import { LOCALE } from "locales/locale";

export type TLocale = {
  currentLocale: LOCALE;
  setLocale: (locale: LOCALE) => void;
};
export type TUser = { username: string; email: string };
export type TUserObject = {
  token: () => Promise<string | undefined>;
  userData: TUser | null;
  loading: boolean | null;
  setUser: (user: TUser) => void;
  logout: () => void;
};

function placeHolder() {}

export interface IGlobalStateContext {
  locale: TLocale;
  user: TUserObject;
}

const GlobalStateContext = React.createContext<IGlobalStateContext>({
  locale: {
    currentLocale: LOCALE.LATVIAN,
    setLocale: placeHolder,
  },
  user: {
    userData: null,
    loading: null,
    setUser: placeHolder,
    logout: placeHolder,
    token: async () => "",
  },
});

export default GlobalStateContext;
