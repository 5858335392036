export const KURPES_LOGO =
  "https://kurpes-img.s3.eu-central-1.amazonaws.com/site-icons/kurpes-logo.png";

export const WHITE_KURPES_LOGO =
  "https://vi-global-resources.s3.eu-central-1.amazonaws.com/misc/baltas_kurpes+(1).png";

export const MAP_CLUSTER =
  "https://iframe-images.s3.eu-central-1.amazonaws.com/cluster.png";

export const MAP_MARKER =
  "https://iframe-images.s3.eu-central-1.amazonaws.com/marker_red.png";

export const PLACEHOLDER_IMAGE_16_TO_9 =
  "https://iframe-images.s3.eu-central-1.amazonaws.com/kurpesPlaceholder.svg";
