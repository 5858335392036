import { Image, Menu } from "semantic-ui-react";
import { darken } from "polished";

import styled from "styles/styled";

export const StyledKurpesLogo = styled(Image)({
  height: "25px",
  opacity: 0.5,
  "&:hover": {
    opacity: 1,
  },
});

export const StyledCustomLogo = styled(Image)(({ height }) => ({
  height: height || "25px",
}));

export const StyledIFrameMenu = styled(Menu)(({ theme }) => ({
  "&&&&&": {
    margin: "0 !important",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    backgroundColor: theme.palette.special.header,
    borderColor: darken(0.03, theme.palette.special.header),
  },
}));
