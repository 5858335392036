import React, { useEffect } from "react";

import useGlobalContext from "hooks/useGlobalContext";
import useIframeContext from "hooks/useIFrameContext";

const SetLanguage: React.FC = ({ children }) => {
  const {
    iframe: { language },
  } = useIframeContext();
  const {
    locale: { currentLocale, setLocale },
  } = useGlobalContext();
  useEffect(() => {
    if (language === currentLocale) return;
    setLocale(language);
    // intentional. Should have done class, sorry
    // eslint-disable-next-line
  }, []);
  return <>{children}</>;
};

export default SetLanguage;
