import React, {
  ReactNode,
  CSSProperties,
  SyntheticEvent,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { DropdownProps, Ref, StrictDropdownItemProps } from "semantic-ui-react";

import useResizeDetector from "hooks/useResizeDetector";
import useOnClickAway from "hooks/useOnClickAway";

import {
  StyledChevron,
  StyledDropdown,
  StyledDropdownContent,
  StyledDropdownWrapper,
  StyledTitle,
  StyledTrigger,
} from "./ControlledDropdown.styles";

interface ControlledDropdownProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  trigger: ReactNode;
  options: StrictDropdownItemProps[];
  value?: string[];
  onChange?: (value: string[]) => void;
  empty?: boolean;
}
const OPEN_STYLE: CSSProperties = {
  position: "relative",
  zIndex: 2000,
};

const ControlledDropdown: React.FC<ControlledDropdownProps> = ({
  open,
  setOpen,
  options,
  onChange,
  value,
  trigger,
  children,
  empty,
}) => {
  const ooChangeFn = (event: SyntheticEvent, data: DropdownProps) => {
    event.preventDefault();
    if (onChange) {
      const val = data.value instanceof Array ? (data.value as string[]) : [];
      onChange(val);
    }
  };

  // TODO rewrite without using semantic, this is getting too silly, sorry
  const ref = useRef<HTMLElement>(null);
  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  useOnClickAway(ref, close);
  useEffect(() => close(), [trigger, close, value]);

  const {
    window: { clientWidth },
    element: { left },
  } = useResizeDetector(ref, [trigger, value, open]);

  return (
    <StyledDropdownWrapper style={open ? OPEN_STYLE : {}}>
      <Ref innerRef={ref}>
        <StyledTrigger
          empty={empty}
          onClick={() => setOpen(true)}
          role="button"
        >
          <StyledDropdown
            trigger={
              <StyledTitle onClick={() => setOpen(!open)}>
                {trigger}

                <StyledChevron open={open} name="angle double down" />
              </StyledTitle>
            }
            open={open}
            screenWidth={clientWidth}
            onOpen={() => setOpen(true)}
            multiple
            lazyLoad
            floating
            onChange={ooChangeFn}
            clearable
            options={options}
            value={value || []}
            topPosition={left}
          >
            <StyledDropdownContent>{open && children}</StyledDropdownContent>
          </StyledDropdown>
        </StyledTrigger>
      </Ref>
    </StyledDropdownWrapper>
  );
};

export default ControlledDropdown;
