import styled, { ThemedStyledInterface } from "styled-components";

import themeMaker from "./themeMaker";

const defaultTheme = themeMaker({
  primaryColor: "goldenrod",
  headerColor: "#FFFFFF",
  secondaryColor: "rgb(167, 167, 167)",
});

export type Theme = typeof defaultTheme;
// TODO reexport all usage of styled through this.
export const styledWithTheme = styled as ThemedStyledInterface<Theme>;

export default defaultTheme;
