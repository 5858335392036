import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import useFilterContext from "hooks/useFilterContext";
import ControlledPopup from "components/templates/Modals/ControlledPopup";
import DateDisplay from "components/atoms/Primitives/DateDisplay";
import InputlessFlatpickr from "components/atoms/Inputs/InputlessFlatpickr";
import SimpleButton, {
  BUTTON,
} from "components/atoms/Buttons/SimpleButton/SimpleButton";
import useDidUpdateEffect from "hooks/useDidUpdateEffect";
import { TODAY } from "constants/dates";

import { StyledDateInput, StyledShortcutGroup } from "./DateFilter.styles";

export interface DateFilterProps {
  minDate?: string;
  maxDate?: string;
}

function dateFromJs(dateObj?: Date) {
  if (!dateObj) return undefined;
  const month = dateObj.getMonth() + 1; // months from 1-12
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  return `${year}-${month}-${day}`;
}

const TODAY_DATE = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
);

const DateFilter: React.FC<DateFilterProps> = ({ minDate, maxDate }) => {
  const { t } = useTranslation();
  const { filters, setFilter } = useFilterContext();

  const [controlledDate, setControlledDate] = useState(filters.date);
  const [open, setOpen] = useState(false);

  const newFrom = dateFromJs(controlledDate[0]);
  const newTo = dateFromJs(controlledDate[1]);
  const from = dateFromJs(filters.date[0]);
  const to = dateFromJs(filters.date[1]);

  useDidUpdateEffect(() => {
    if (open) return;
    if (from === newFrom && to === newTo) return;
    setFilter(controlledDate, "date");
  }, [controlledDate, newTo, newFrom, from, to, open]);

  const onShortCutClick = useCallback(
    (d1, d2) => () => {
      const getDate = (days: number) =>
        new Date(TODAY_DATE.getTime() + 24 * 60 * days * 60 * 1000);
      const presetDates = [getDate(d1), getDate(d2)];
      setControlledDate(presetDates);
      setOpen(false);
    },
    [],
  );

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ControlledPopup
      buttonText={<DateDisplay dateFrom={newFrom} dateTo={newTo} />}
      open={open}
      setOpen={setOpen}
      empty={filters.date.length === 0}
    >
      <StyledDateInput>
        <InputlessFlatpickr
          minDate={minDate || TODAY.toISOString()}
          maxDate={maxDate}
          value={controlledDate}
          onChange={setControlledDate}
          onClose={onClose}
        />
        <StyledShortcutGroup>
          <SimpleButton
            variant={BUTTON.PRIMARY}
            onClick={onShortCutClick(0, 0)}
          >
            {t("Filters.Today")}
          </SimpleButton>
          <SimpleButton
            variant={BUTTON.PRIMARY}
            onClick={onShortCutClick(1, 1)}
          >
            {t("Filters.Tomorrow")}
          </SimpleButton>
          <SimpleButton
            variant={BUTTON.PRIMARY}
            onClick={onShortCutClick(0, 6)}
          >
            {t("Filters.ThisWeek")}
          </SimpleButton>
        </StyledShortcutGroup>
      </StyledDateInput>
    </ControlledPopup>
  );
};

export default DateFilter;
